import * as api from "../api";

export function filterCompanyPayCards(query) {
  return api
    .fetchData("get", "/company3/paycard/console", query)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function filterCompanyPayCardsFilter(query) {
  return api
    .fetchData("get", "/company3/paycard/filter/console", query)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanies3(id) {
  if (id) {
    return api
      .fetchData("get", "/company3", { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject("");
  }
}

export function updateCompany(id, json) {
  return api
    .fetchData("put", "/company3", { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanyUserCards(id) {
  if (id) {
    return api
      .fetchData("get", "/company/user/card", { userId: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject("");
  }
}

export function getCompanyPayCards(id, channel) {
  if (id) {
    return api
      .fetchData("get", "/company3/paycard", {
        companyId: id,
        channel: channel,
      })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject("");
  }
}

export function updateCompanyPayCard(companyId, channel, json) {
  return api
    .fetchData(
      "put",
      "/company3/paycard",
      { companyId: companyId, channel: channel },
      json
    )
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteCompanyPayCard(companyId, channel, id) {
  return api
    .fetchData("delete", "/company3/paycard", {
      companyId: companyId,
      channel: channel,
      id: id,
    })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteCompanyPayCardCurrent(companyId, channel) {
  return api
    .fetchData("delete", "/company3/paycard/current", {
      companyId: companyId,
      channel: channel,
    })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function createCompanyPayCardPay(companyId, channel, id, payInfo_card) {
  return api
    .fetchData("post", "/company3/paycard/pay", {
      companyId,
      channel,
      id,
      ...(payInfo_card !== undefined && { payInfo_card }),
    })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function updateCompanyUser(id, json) {
  return api
    .fetchData("put", "/company/user", { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export async function getOptions(query) {
  const url = "/company/option?" + query;
  return await api.fetchData("get", url);
}
