import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ContractCard from '../../../components/ContractCard';

const Preview = (props) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div
          style={{
            width: '100%',
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
            }}
          >
            Preview
          </p>
          <ContractCard contract={props.contract} />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Preview;
