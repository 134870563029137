import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

class ToggleButton extends React.PureComponent {
  render() {
    const { datas, selected, onSelect, classes } = this.props;
    const buttons = datas.map((data) => (
      <Button
        key={data.value}
        size="small"
        variant="contained"
        className={classes.button}
        color={selected === data.value ? 'default' : 'primary'}
        onClick={() => onSelect(data.value)}>
        {data.label}
      </Button>
    ));
    return <div className="row">{buttons}</div>;
  }
}

const styles = () => ({
  button: {
    borderRadius: 0,
    boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.14), 0px 3px 0px -2px rgba(0, 0, 0, 0.12)',
    marginBottom: 16,
  },
});

export default withStyles(styles)(ToggleButton);
