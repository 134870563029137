import * as api from '../api';

export function getSchema() {
    return api.fetchData('get', '/notice/schema')
    .then((datas) => {
        return Promise.resolve(datas)
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function filterNotices(query) {
    return api.fetchData('get', '/notice', query)
    .then((datas) => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function getNotice(id) {
    return api.fetchData('get','/notice', {id:id})
    .then(datas => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export function createNotice(json) {
    return api.fetchData('post', '/notice', {}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}

export function updateNotice(id, json) {
    return api.fetchData('put', '/notice', {id:id}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}
export function deleteNotice(id) {
    return api.fetchData('delete', '/notice', {id:id})
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}