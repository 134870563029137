import React from 'react';
import { Router, Route, IndexRoute } from 'react-router';

import Container from './app/container';
import DashBoard from './dashboard';
import SignIn from './auth/signin';
import Notices from './notice/list';
import Notice from './notice/editor';
import NoticeContents from './notice/content-editor';
import Contracts from './contract/list';
import Contract from './contract/editor';
import Companies from './companies/list';
import Company from './companies/editor';
import Company3 from './company3/editor';
import Company3Manual from './company3/manual-editor';
import Company3List from './company3/list';
import Bloggers from './users/list-blogger';
import Instagrammers from './users/list-instagrammer';
import Youtubers from './users/list-youtuber';
import Accounts from './accounts/list';
import Account from './accounts/editor';
import User from './users/editor';
import Calendar from './calendar/schedule';
import Drawns from './drawn/list';
import Drawn from './drawn/editor';
import Bugs from './bug/list';
import Bug from './bug/editor';
import Reports from './report/list';
import Report from './report/editor';
import Inquiries from './inquiries';
import Inquiry from './inquiry';
import ReportInfos from './reportinfo/list';
import ReportInfo from './reportinfo/editor';
import CompanyUsers from './company-user/list';
import CompanyUser from './company-user/editor';
import CompanyPaycards from './paycard/list';
import CompanyPaycardsFilter from './paycard/list2';
import CompanyPaycard from './paycard/editor';
import UserPays from './userpay/list';
import UserPay from './userpay/editor';
import CompanyEtc from './etc/editor';
import Campaigns from './campaigns/list';
import Campaign from './campaigns/editor';
import Propositions from './proposition/list';
import Proposition from './proposition/editor';
import UserPoints from './user-point/list';
import UserPoint from './user-point/editor';
import CompanyPoints from './company-point/list';
import CompanyPoint from './company-point/editor';
import CompanyPointDetail from './company-point/detail';
import KeywordRequests from './keyword-request/list';
import Packages from './package/list';
import Package from './package/editor';
import Premiums from './premium/list';
import Premium from './premium/editor';
import Sumempoints from './sumempoint/list';
import Sumempoint from './sumempoint/editor';
import Messages from './messages/list';
import purchaseLink from './purchase-link/list';
import CosduckCompanyUsers from './cosduck/pages/company-users/index';
import CosduckCompany from './cosduck/pages/company';
import CosduckUsers from './cosduck/pages/users/index';
import CosduckUser from './cosduck/pages/user/index';
import CosduckContracts from './cosduck/pages/contracts/index';
import CosduckContract from './cosduck/pages/contract/index';
import CosduckAd from './cosduck/pages/ad/index';
import CosduckAds from './cosduck/pages/ads/index';
import CosduckHandbook from './cosduck/pages/handbook/index';
import CosduckReportIssue from './cosduck/pages/ReportIssue/index';
import ProductReviewConfigs from './cosduck/pages/productReviewConfigs';
import ProductReviewConfig from './cosduck/pages/productReviewConfig';
class AppRoute extends React.Component {
  render() {
    return (
      <Router history={this.props.history}>
        <Route path='/' component={Container}>
          <IndexRoute component={DashBoard} />
          <Route path='signin' component={SignIn} />
          <Route path='bloggers' component={Bloggers} />
          <Route path='instagrammers' component={Instagrammers} />
          <Route path='youtubers' component={Youtubers} />
          <Route path='accounts' component={Accounts} />
          <Route path='account' component={Account} />
          <Route path='user' component={User} />
          <Route path='notices' component={Notices} />
          <Route path='notice' component={Notice} />
          <Route path='notice-contents' component={NoticeContents} />
          <Route path='companies' component={Companies} />
          <Route path='company' component={Company} />
          <Route path='companies3' component={Company3List} />
          <Route path='company3' component={Company3} />
          <Route path='company3-manual' component={Company3Manual} />
          <Route path='contracts' component={Contracts} />
          <Route path='contract' component={Contract} />
          <Route path='calendar' component={Calendar} />
          <Route path='bugs' component={Bugs} />
          {/*<Route path='bug' component={Bug} />*/}
          <Route path='drawns' component={Drawns} />
          <Route path='drawn' component={Drawn} />
          <Route path='reports' component={Reports} />
          <Route path='report' component={Report} />
          <Route path='inquiries' component={Inquiries} />
          <Route path='inquiry' component={Inquiry} />
          <Route path='reportinfos' component={ReportInfos} />
          <Route path='reportinfo' component={ReportInfo} />
          <Route path='company-users' component={CompanyUsers} />
          <Route path='company-user' component={CompanyUser} />
          <Route path='paycards' component={CompanyPaycards} />
          <Route path='paycards-filter' component={CompanyPaycardsFilter} />
          <Route path='paycard' component={CompanyPaycard} />
          <Route path='userpays' component={UserPays} />
          <Route path='userpay' component={UserPay} />
          <Route path='etc' component={CompanyEtc} />
          <Route path='campaigns' component={Campaigns} />
          <Route path='campaign' component={Campaign} />
          <Route path='propositions' component={Propositions} />
          <Route path='proposition' component={Proposition} />
          <Route path='user-points' component={UserPoints} />
          <Route path='user-point' component={UserPoint} />
          <Route path='company-points' component={CompanyPoints} />
          <Route path='company-point' component={CompanyPoint} />
          <Route path='company-point-detail' component={CompanyPointDetail} />
          <Route path='keyword-requests' component={KeywordRequests} />
          <Route path='packages' component={Packages} />
          <Route path='package' component={Package} />
          <Route path='premiums' component={Premiums} />
          <Route path='premium' component={Premium} />
          <Route path='sumempoints' component={Sumempoints} />
          <Route path='sumempoint' component={Sumempoint} />
          <Route path='messages' component={Messages} />
          <Route path='purchase-link' component={purchaseLink} />
          <Route path='cosduck/users' component={CosduckUsers} />
          <Route path='cosduck/user' component={CosduckUser} />
          <Route path='cosduck/contracts' component={CosduckContracts} />
          <Route path='cosduck/contract' component={CosduckContract} />
          <Route path='cosduck/ad' component={CosduckAd} />
          <Route path='cosduck/ads' component={CosduckAds} />
          <Route path='cosduck/handbook' component={CosduckHandbook} />
          <Route path='cosduck/report' component={CosduckReportIssue} />
          <Route path='cosduck/company-user' component={CosduckCompanyUsers} />
          <Route path='cosduck/company' component={CosduckCompany} />
          <Route
            path='cosduck/productReviewConfigs'
            component={ProductReviewConfigs}
          />
          <Route
            path='cosduck/productReviewConfig'
            component={ProductReviewConfig}
          />
        </Route>
      </Router>
    );
  }
}
export default AppRoute;
