import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import DeliveryItem from '../../../components/DeliveryItem';
import { useState } from 'react';
import { useEffect } from 'react';
import { getUserAddress } from '../../../api/user';

const AddressList = (props) => {
  const [addressList, setAddressList] = useState([]);
  const lastDeliveryAddressId = props.lastDeliveryAddressId;
  const uid = props.id;

  useEffect(() => {
    const fetch = async () => {
      const result = await getUserAddress(uid);
      setAddressList(result.data.data.userAddresses);
    };

    fetch();
  }, []);

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>배송지 목록</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={{ width: '100%', minWidth: 500 }}>
          {addressList.map((address) => (
            <DeliveryItem
              item={address}
              containerStyle={{
                ...(lastDeliveryAddressId === address.id && {
                  backgroundColor: 'rgba(0,128,255,0.1)',
                }),
              }}
            />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AddressList;
