import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const COLOR_MAP = {
  id: {
    bg: 'red',
    color: 'white',
  },
  longUrl: {
    bg: 'orange',
    color: 'white',
  },
  shortUrl: {
    bg: 'yellow',
    color: 'black',
  },
  naverUrl: {
    bg: 'green',
    color: 'white',
  },
  contractId: {
    bg: 'blue',
    color: 'white',
  },
  propositionId: {
    bg: 'purple',
    color: 'white',
  },
  cname: {
    bg: 'white',
    color: 'black',
  },
  companyName: {
    bg: 'black',
    color: 'white',
  },
};

const KeywordChip = ({
  category,
  subCategory,
  label,
  onClickHandler,
  onDeleteHandler,
}) => {
  return (
    <span
      onClick={(event) => {
        event.stopPropagation();
        onClickHandler();
      }}
      style={{
        boxSizing: 'border-box',
        padding: '8px 16px',
        boxShadow: '0 0 0 2px #d0dffc',
        backgroundColor: '#fff',
        borderRadius: '32px',
        fontSize: 16,
        fontWeight: 500,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '24px',
          backgroundColor: COLOR_MAP[subCategory].bg,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ color: COLOR_MAP[subCategory].color }}>
          {category === 'chart' ? 'C' : 'M'}
        </span>
      </span>
      <p
        style={{
          color: '#283b70',
          margin: '0px 4px',
          maxWidth: '180px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {label}
      </p>
      <CloseIcon
        color={'#283b70'}
        style={{ width: 24, height: 24 }}
        onClick={(event) => {
          event.stopPropagation();
          onDeleteHandler();
        }}
      />
    </span>
  );
};

export default KeywordChip;
