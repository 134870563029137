// TODO : 추후 실제 API로 교체 필요

import axios from 'axios';

/**
 * Contracts 요청
 * @param {string} query.uid ?User uid
 * @param {number} query.adId ?Ad Id
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @param {string} query.adsType ?adsType (STANDARD | EXPRESS)
 * @param {string} query.platform ?(INSTAGRAM | TIKTOK)
 * @param {string} query.productName ?제품 이름
 * @returns {Contract[]} Contract 목로
 */

export const getContracts = (query = {}) => {
  const payloadString = Object.entries(query)
    .map((e) => e.join('='))
    .join('&');

  let baseURL = `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/contracts`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const getContract = (id) => {
  return axios.get(
    `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/contract/${id}`
  );
};

export const updateContract = (id, body) => {
  delete body.id;
  delete body.updatedAt;
  delete body.createdAt;

  return axios.patch(
    `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/contract/${id}`,
    {
      ...body,
    }
  );
};

export const cancelContracts = async (contractIds) => {
  if (!contractIds.length) return;

  return await axios.patch(
    `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/contracts/cancel`,
    {
      contractIds,
    }
  );
};
