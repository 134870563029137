import React from "react";
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ImageEditor from "../../../components/image-editor";

import _ from 'lodash';

import * as api from '../api';

class CampaignInfo extends React.Component {
  state = {
    locals:[],
  }

  render() {
    const { campaign } = this.props;

    if (campaign && Object.keys(campaign).length > 0) {
      if (campaign.category.firstName === '매장') {
        const { locals } = this.state;
        let regionItems = campaign.local ? <MenuItem value={campaign.local.region}>{campaign.local.region}</MenuItem> : '';
        let localItems = campaign.local ? <MenuItem value={campaign.local.code}>{campaign.local.code}</MenuItem> : '';

        if ( Object.keys(locals).length > 0 ) {
          regionItems = Object.keys(locals).map((item) => {
            return <MenuItem key={item} value={item}>{item}</MenuItem>;
          })
          localItems = _.isEmpty((campaign.local || {}).region) ? '' : Object.keys(locals[campaign.local.region]).map((key) => {
            return <MenuItem key={key} value={key}>{locals[campaign.local.region][key]}</MenuItem>;
          });
        }
        return (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>가맹점 기본정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className='row'>
                  <Select
                    fullWidth
                    value={campaign.local ? campaign.local.region : ''}
                    onChange={(evt) => {
                      const localCode = Object.keys(locals[evt.target.value])[0];
                      this.props.handlePropChange('local', 'region', evt.target.value);
                      this.props.handlePropChange('local', 'code', localCode);
                      this.props.handlePropChange('local', 'local', locals[evt.target.value][localCode]);
                    }}
                  >
                    {regionItems}
                  </Select>
                  <Select
                    fullWidth
                    value={campaign.local ? campaign.local.code : ''}
                    onChange={(evt) => {
                      this.props.handlePropChange('local', 'code', evt.target.value);
                      this.props.handlePropChange('local', 'local', locals[campaign.local.region][evt.target.value]);
                    }}
                  >
                    {localItems}
                  </Select>
                </div>
                <label>매장 이름</label>
                <TextField
                  fullWidth
                  value={campaign.serviceName}
                  onChange={(evt) => this.props.handleChange('serviceName', evt.target.value)}
                />
                <label>매장 연락처</label>
                <TextField
                  fullWidth
                  value={campaign.contacts}
                  onChange={(evt) => this.props.handleChange('contacts', evt.target.value)}
                />
                <label>매장 영업시간</label>
                <TextField
                  fullWidth
                  value={campaign.businessHours}
                  onChange={(evt) => this.props.handleChange('businessHours', evt.target.value)}
                />
                <label>매장 주소</label>
                <TextField
                  fullWidth
                  value={campaign.address}
                  onChange={(evt) => this.props.handleChange('address', evt.target.value)}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      }

      // 제품일 경우
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>가맹점 기본정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <label>제품명</label>
              <TextField
                fullWidth
                value={campaign.serviceName}
                onChange={(evt) => this.props.handleChange('serviceName', evt.target.value)}
              />
              <div>
                <ImageEditor
                  label='제품 상세 이미지'
                  images={campaign.images ? campaign.images : []}
                  main={null}
                  handleChange={(arr) => this.props.handleChange('images', arr)} />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const locals = await api.getOptions('type=local');
    this.setState({ locals })
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CampaignInfo);