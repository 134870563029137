import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import * as api from './api';
import ActionCreators from '../action';
import { SearchInput, SortableTableHead } from '../../components';
import momentLib from 'moment-timezone';
import _ from 'lodash';

const header = [
  { sortable: true, id: 'signupAt', label: '가입일' },
  { sortable: true, id: 'displayName', label: '이름' },
  { sortable: true, id: 'email', label: '이메일' },
  { sortable: true, id: 'phone', label: '연락처' },
  { sortable: true, id: 'level', label: '등급' },
  { sortable: true, id: 'visitorCount', label: '일평균방문자' },
  { sortable: true, id: 'local', label: '지역' },
  { sortable: true, id: 'extensionCount', label: '기한연장횟수' },
  { sortable: true, id: 'mark', label: '평점'},
  { sortable: true, id: 'edit', label: '정보' },
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'signupAt',
  direction: 'desc',
  users: [],
  total: 0,
};

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, 'signupAt', 'desc', { [selectItem]: findText });
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleReview = () => {
    let expr = {
      $or: [
        {
          $and: [ { level: 'black' }, { visitorCount: { $lte: 600 } }, { type: 'naver'} ],
        },
        {
          $and: [ { level: 'red' }, { visitorCount: { $gte: 600 } }, { type: 'naver'}  ],
        },
        {
          $and: [ { level: 'yellow' }, { visitorCount: { $gte: 200 } }, { type: 'naver'}  ],
        },
      ],
    };

    this.resetState();
    this.fetchDatas(0, 'level', 'asc', { expr: JSON.stringify(expr) });
  };

  handleUpdateVistor = () => {
    api.updateVistorCount().catch(() => {
      alert('업데이트 실패');
    });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { users, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      type: 'naver',
    };

    api.filterUsers(filter).then((results) => {
      if ((options || {}).expr && results) {
        results.users = results.users.filter((user) => {
          let idx = _.findLastIndex(user.naverHistory, function (history) { return history.days !== undefined });
          if (idx > -1) {
            if(momentLib(new Date()).tz('Asia/Seoul').isBefore(momentLib(user.naverHistory[idx].schedule))) {
              console.log(user.naverHistory[idx].schedule);
              results.total--;
              return false;
            }
          }
          return true;
        })
      }
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, users, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = users.filter((user) => user).map((item, index) => {
      let naverId = item.email && item.email.indexOf('@') > 0 ? item.email.split('@')[0] : item.email;

      return (
        <TableRow key={index}>
          <TableCell>{momentLib(item.signupAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>{item.displayName}</TableCell>
          <TableCell>
            <a href={`http://blog.naver.com/${naverId}`} target='_blank' rel='noopener noreferrer'>
              {item.email}
            </a>
          </TableCell>
          <TableCell>{item.phone}</TableCell>
          <TableCell className={item.level}>{item.level}</TableCell>
          <TableCell>{item.visitorCount}</TableCell>
          <TableCell>{item.local}</TableCell>
          <TableCell>{item.extensionCount ? item.extensionCount : 0}</TableCell>
          <TableCell>{item.mark}</TableCell>
          <TableCell>
            <Link to={'/user?uid=' + item.uid} target='_blank'>
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className='table-container'>
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
          handleReview={this.handleReview}
          handleUpdateVistor={this.handleUpdateVistor}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className='table-footer'>
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
