import React from 'react';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';

const header = [
  { sortable: false, id: 'id', label: 'ID' },
  { sortable: true, id: 'title', label: '이름' },
  { sortable: true, id: 'registedAt', label: '등록 시간' },
  { sortable: true, id: 'updatedAt', label: '업데이트 시간' },
  { sortable: true, id: 'available', label: '이용가능여부' },
  { sortable: true, id: 'isMain', label: '메인' },
  { sortable: false, id: 'edit', label: '편집' },
];
const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'registedAt',
  direction: 'desc',
  notices: [],
  total: 0,
};

class Notices extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
    };

    api.filterNotices(filter).then((results) => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, notices, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = notices.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.id}</TableCell>
          <TableCell>{item.title}</TableCell>
          <TableCell>{momentLib(item.registedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>{momentLib(item.updatedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>
            {item.available !== undefined ? (
              <Checkbox
                checked={item.available}
                onChange={(event) => {
                  api.updateNotice(item.id, { available: event.target.checked });
                  notices[index].available = event.target.checked;
                  this.setState({ notices: notices });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            {item.isMain !== undefined ? (
              <Checkbox
                checked={item.isMain}
                onChange={(event) => {
                  api.updateNotice(item.id, { isMain: event.target.checked });
                  notices[index].isMain = event.target.checked;
                  this.setState({ notices: notices });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            <Link to={'/notice?id=' + item.id} target='_blank'>
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className='table-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchInput header={header} handleSearch={this.handleSearch} />
          <Button
            variant='contained'
            color='primary'
            style={{ marginBottom: 14, marginRight: 6 }}
            onClick={() => (document.location.href = '/notice')}
          >
            신규생성
          </Button>
        </div>
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, order) => this.fetchDatas(null, orderBy, order, null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className='table-footer'>
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notices);
