import React from "react";
import * as api from "../../company3/api";
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Switch from "@material-ui/core/Switch";
import momentLib from "moment-timezone";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router";
import IconButton from "@material-ui/core/IconButton/IconButton";
import OpenInNew from "@material-ui/icons/OpenInNew";

class EditorCompany extends React.Component {
  state = {
    companyPoint: {},
    routes: {}
  };

  render() {
    let {companyPoint, routes} = this.state;

    if (Object.keys(companyPoint).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{companyPoint.company.name}님의 정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>담당자명</label>
                  <TextField
                    fullWidth
                    value={companyPoint.company.charger}
                    onChange={(evt) => this.props.handleChange('charger', evt.target.value)}
                  />
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>담당자 연락처</label>
                      <TextField
                        fullWidth
                        value={companyPoint.company.contacts}
                        onChange={(evt) => this.props.handleChange('contacts', evt.target.value)}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{marginTop: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>업체 상세정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>업체명</label>
                      <TextField
                        fullWidth
                        value={companyPoint.company.name}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>사업자 등록번호</label>
                      <TextField
                        fullWidth
                        value={companyPoint.company.registration}
                      />
                    </div>
                  </div>
                  <label>담당자 이메일(아이디)</label>
                  <TextField
                    fullWidth
                    disabled
                    value={companyPoint.company.email}
                  />
                  <label>방문경로</label>
                  <Select
                    fullWidth
                    value={companyPoint.company.route}
                  >
                    {
                      Object.keys(routes).map((key) => {
                        return (
                          <MenuItem value={key}>{routes[key]}</MenuItem>
                        )
                      })
                    }
                  </Select>
                  <label>메일수신 동의여부</label>
                  <Switch
                    checked={companyPoint.company.agree_to_mail}
                  />
                  <label>추천인</label>
                  <TextField
                    fullWidth
                    value={companyPoint.company.recommended}
                  />
                  <label>업체</label>
                  <Link to={ '/company-user?id=' + companyPoint.companyId } target='_blank'>
                    <IconButton>
                      <OpenInNew />
                    </IconButton>
                  </Link>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div/>;
    }
  }

  async componentDidMount() {
    const routes = await api.getOptions('type=root');
    this.setState({routes});
    this.setState({companyPoint: this.props.companyPoint});
  }

  componentWillReceiveProps(newProps) {
    this.setState({companyPoint: newProps.companyPoint});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorCompany);