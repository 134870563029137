import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as api from '../api';
import {Button} from "@material-ui/core";

class EditorBasic extends React.Component {
  state = {
    premium: {}
  };
  render() {
    let { premium } = this.state;

    if (Object.keys(premium).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{premium.company.name}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '100%'}}>
                <section>
                  <label>{premium.adId ? "사용" : '충전'}인원</label>
                  <TextField
                    fullWidth
                    value={premium.number}
                    onChange={(evt) => this.handleChange('number', evt.target.value)}
                  />
                  <label>기간</label>
                  <TextField
                    fullWidth
                    value={premium.period}
                    onChange={(evt) => this.handleChange('period', evt.target.value)}
                    disabled={premium.adId}
                  />
                  <label>가격</label>
                  <TextField
                    fullWidth
                    value={premium.price}
                    onChange={(evt) => this.handleChange('price', evt.target.value)}
                    disabled={premium.adId}
                  />
                  <label>누적충전인원</label>
                  <TextField
                    fullWidth
                    value={premium.total}
                    onChange={(evt) => this.handleChange('total', evt.target.value)}
                  />
                  <label>모집가능인원</label>
                  <TextField
                    fullWidth
                    value={premium.balance}
                    onChange={(evt) => this.handleChange('balance', evt.target.value)}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
            <div className='row' style={{justifyContent: 'flex-end'}}>
              <Button color='secondary' onClick={this.handleDelete}>삭제</Button>
              <Button color='secondary' onClick={this.handleSave}>저장</Button>
            </div>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div/>;
    }
  }

  componentDidMount() {
    this.setState({premium: this.props.premium});
  }

  componentWillReceiveProps(newProps) {
    this.setState({premium: newProps.premium});
  }

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({premium : { ...this.state.premium, [name]: value }});
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const {premium} = this.state;
      api.deletePremium(premium.id).then(() => {
        this.setState({snackOpen: true});
        this.props.deactivateLoading();
        this.props.push('/premiums');
      }).catch((err) => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        }
      });
    }
  }
  handleSave = (json) => {
    this.props.activateLoading();
    const {id, premium} = this.state;

    api.updatePremium(id, premium).then(result => {
      this.setState({schema:json, snackOpen:true})
      this.props.deactivateLoading()
    }).catch(err => {
      console.log(err)
      this.props.deactivateLoading()
      alert('업데이트에 실패했습니다. 다시 확인해주세요.');
    })
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorBasic);