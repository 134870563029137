import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import * as api from '../api';
import {fetchData} from "../../api";
import momentLib from "moment-timezone";

import _ from 'lodash';

class InfoInsta extends PureComponent {
  state = {
    contracts: [],
    startDate: momentLib(new Date()).tz('Asia/Seoul').subtract(10, 'years').format('YYYY-MM-DD'),
    endDate: momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD')
  };
  render() {
    const { company } = this.props;
    const { contracts, startDate, endDate } = this.state;

    let data = [];

    let filteredContracts = contracts.filter((contract) => {
      return momentLib(contract.date0).tz('Asia/Seoul').isBetween(startDate, endDate, null, '[]');
    });

    filteredContracts.forEach((contract) => {
      if(contract.tags) {
        contract.tags.forEach((tag) => {
          if(data[tag]) {
            data[tag]++;
          }
          else {
            data[tag] = 1;
          }
        });
      }
    });

    let hashtagData = [];
    Object.keys(data).forEach((key) => {
      hashtagData.push({
        label: key,
        count: data[key],
        value: ((data[key]/contracts.length)*100).toFixed(2)
      })
    });

    hashtagData.sort((a, b) => {
      return (b.value - a.value);
    });

    if (company) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <div className='row'>
                <div style={{ width: '30%'}}>
                  <label>인스타 보고서 기간 시작일</label>
                  <TextField
                    id='date'
                    type='date'
                    defaultValue={startDate}
                    onChange={(event) => {
                      this.setState({ startDate: event.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div style={{ width: '30%'}}>
                  <label>인스타 보고서 기간 종료일</label>
                  <TextField
                    id='date'
                    type='date'
                    defaultValue={endDate}
                    onChange={(event) => {
                      this.setState({ endDate: event.target.value });
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div style={{ width: '30%'}}>
                  <label>인스타 총 팔로워</label>
                  <TextField
                    fullWidth
                    value={_.sumBy(filteredContracts, 'follower')}
                  />
                </div>
                <div style={{ width: '30%'}}>
                  <label>총 좋아요 수</label>
                  <TextField
                    fullWidth
                    value={_.sumBy(filteredContracts, 'like')}
                  />
                </div>
                <div style={{ width: '30%'}}>
                  <label>총 댓글 수</label>
                  <TextField
                    fullWidth
                    value={_.sumBy(filteredContracts, 'reply')}
                  />
                </div>
              </div>
              <div className='row' style={{marginTop: '20px'}}>
                <div style={{borderBottom: '1px solid #525252', width: '100%'}}>
                  인스타 포스트 <span>{filteredContracts.length}</span>
                </div>
              </div>
              <div className='row'>
                <Table style={{width: '100%'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>회원정보</TableCell>
                      <TableCell align="right">좋아요</TableCell>
                      <TableCell align="right">댓글</TableCell>
                      <TableCell align="right">영상</TableCell>
                      <TableCell align="right">팔로워</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredContracts.map((contract) => {
                      return (
                        <TableRow key={contract.id}>
                          <TableCell component="th" scope="row">
                            {contract.userOuterId ? (
                            <a href={'https://instagram.com/' + contract.userOuterId} target="_blank">
                              {contract.username + '(' + contract.userOuterId + ')'}
                            </a>
                            ): (
                            ''
                            )}
                          </TableCell>
                          <TableCell align="right">{contract.like}</TableCell>
                          <TableCell align="right">{contract.reply}</TableCell>
                          <TableCell align="right">{contract.play}</TableCell>
                          <TableCell align="right">{contract.follower}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className='row' style={{marginTop: '20px'}}>
                <div style={{borderBottom: '1px solid #525252', width: '100%'}}>
                  해시태그 통계 {hashtagData.length}
                </div>
              </div>
              <div className='row'>
                <Table style={{width: '100%'}}>
                  <TableBody>
                    {hashtagData.map((tag) => {
                      return (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {tag.label}
                          </TableCell>
                          <TableCell align="right">{tag.count}</TableCell>
                          <TableCell align="right">{tag.value}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const { company } = this.props;

    fetchData('get', '/contract', {type: 'insta', company: company.id})
      .then((datas) => {
        this.setState({ ...datas});
      })
      .catch((err) => {
        console.error(err);
        alert('인스타 이용내역 조회 실패');
      });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoInsta);
