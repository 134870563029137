import React, { useEffect, useState } from 'react';

// const {width} = Dimensions.get('screen');

const DetailedImages = ({ images }) => {
  const [opened, setOpened] = useState(false);

  const overlayButton = {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: 48,
    paddingBottom: 0,
    backgroundColor: 'rgb(0, 0, 0, 0.5)',
    alignItems: 'center',
    color: 'white',
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
  };

  if (images.length === 0 || images.includes('')) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div style={{}}>
        <div style={opened ? {} : { height: 275, overflow: 'hidden' }}>
          {images.map((image) => (
            <img
              src={image}
              style={{
                width: '100%',
                height: 'auto',
                verticalAlign: 'middle',
              }}
            />
          ))}
        </div>
        {opened ? (
          <div
            style={{
              ...overlayButton,
            }}
            onClick={() => setOpened(!opened)}
          >
            <div
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <span>See less</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              ...overlayButton,
            }}
            onClick={() => setOpened(!opened)}
          >
            <div
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <span>See more</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     justifyContent: 'space-between',
//   },
//   button: {
//     // width,
//     width: '100%',
//     position: 'absolute',
//     bottom: 0,
//     flexDirection: 'row',
//     height: 48,
//     paddingBottom: 0,
//     backgroundColor: Color.Overlay3,
//     alignItems: 'center',
//   },
// });
export default DetailedImages;
