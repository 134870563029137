import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Color } from '../constants/color';
import Typo from './Typo';

const useStyles = makeStyles({
  primary: {
    backgroundColor: Color.button_primary_background_default,
  },
  secondary: {
    backgroundColor: Color.button_secondary_background_default,
  },
});

const CustomButton = ({
  onClick,
  variant = 'contained',
  type = 'secondary',
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      variant={variant}
      className={type === 'primary' ? classes.primary : classes.secondary}
      {...props}
    >
      <Typo
        typography='Body2'
        fontWeight='500'
        color={
          type === 'primary'
            ? Color.button_primary_text_default
            : Color.button_secondary_text_default
        }
      >
        {children}
      </Typo>
    </Button>
  );
};

export default CustomButton;
