import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button} from "@material-ui/core";
import * as api from "../api";

class EditorBasic extends React.Component {
  state = {
    sumemPoint: {}
  };
  render() {
    let { sumemPoint } = this.state;

    if (Object.keys(sumemPoint).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{sumemPoint.company.name}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '100%'}}>
                <section>
                  <label>{sumemPoint.contractId ? "사용" : '충전'}금액</label>
                  <TextField
                    fullWidth
                    value={sumemPoint.amount}
                    onChange={(evt) => this.handleChange('number', evt.target.value)}
                  />
                  <label>contractId</label>
                  <TextField
                    fullWidth
                    value={sumemPoint.contractId}
                    onChange={(evt) => this.handleChange('period', evt.target.value)}
                    disabled={true}
                  />
                  <label>잔액</label>
                  <TextField
                    fullWidth
                    value={sumemPoint.balance}
                    onChange={(evt) => this.handleChange('price', evt.target.value)}
                    disabled={sumemPoint.adId}
                  />
                  <label>누적충전인원</label>
                  <TextField
                    fullWidth
                    value={sumemPoint.total}
                    onChange={(evt) => this.handleChange('total', evt.target.value)}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
            <div className='row' style={{justifyContent: 'flex-end'}}>
              <Button color='secondary' onClick={this.handleDelete}>삭제</Button>
              <Button color='secondary' onClick={this.handleSave}>저장</Button>
            </div>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div/>;
    }
  }

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({sumemPoint : { ...this.state.sumemPoint, [name]: value }});
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const {sumemPoint} = this.state;
      api.deleteSumemPoint(sumemPoint.id).then(() => {
        this.setState({snackOpen: true});
        this.props.deactivateLoading();
        this.props.push('/sumemPoints');
      }).catch((err) => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        }
      });
    }
  }
  handleSave = (json) => {
    this.props.activateLoading();
    const {id, sumemPoint} = this.state;

    api.updateSumemPoint(id, sumemPoint).then(result => {
      this.setState({schema:json, snackOpen:true})
      this.props.deactivateLoading()
    }).catch(err => {
      console.log(err)
      this.props.deactivateLoading()
      alert('업데이트에 실패했습니다. 다시 확인해주세요.');
    })
  }

  componentDidMount() {
    this.setState({sumemPoint: this.props.sumemPoint});
  }

  componentWillReceiveProps(newProps) {
    this.setState({sumemPoint: newProps.sumemPoint});
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(EditorBasic);