import React, { useEffect } from 'react';
import { useState } from 'react';
import CustomInput from './CustomInput';

const DetailModal = ({ selectedId, onCloseHandler, type, modifyHandler }) => {
  const [detail, setDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        onCloseHandler();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const request = async () => {
      const result = await fetch(
        `https://hashble.com/list?${
          type === 'members'
            ? `contractId=${selectedId}`
            : `propositionId=${selectedId}`
        }`
      );
      const parse = await result.json();
      console.log(parse[0]);
      setDetail(parse[0]);
    };

    request();
  }, []);

  if (!detail) {
    return null;
  }

  const isMembers = !!detail.Contracts;

  const fetchPut = async () => {
    setIsLoading(true);

    let URL = `https://hashble.com/modify?${
      isMembers ? 'contractId' : 'propositionId'
    }=${detail[isMembers ? 'contractId' : 'propositionId']}`;

    try {
      const result = await fetch(`${URL}`, {
        method: 'PUT',
        body: JSON.stringify({
          password: 'phozphoz1!',
          longUrl: detail.longUrl,
          ...(isMembers && {
            companyName: detail.Contracts.companyName,
          }),
          ...(!isMembers && {
            cname: detail.Propositions.cname,
          }),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const parse = await result.json();

      modifyHandler(
        detail[isMembers ? 'contractId' : 'propositionId'],
        detail.longUrl,
        isMembers ? detail.Contracts.companyName : detail.Propositions.cname
      );
    } catch (error) {
      setIsLoading(false);
      alert(error);
    }
  };

  return (
    <div
      onClick={(event) => {
        if (!!event.target.getAttribute('data-overlay')) {
          onCloseHandler();
        }
      }}
      data-overlay
      style={{
        background: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(7.4px)',
        WebkitBackdropFilter: 'blur(7.4px)',
        border: '1px solid rgba(255, 255, 255, 0.53)',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* Modal */}
      <div
        style={{
          zIndex: 2,
          border: '1px solid #e8ebf2',
          backgroundColor: '#fff',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          borderRadius: '0.8rem',
          padding: '0.8rem',
          maxWidth: '60%',
        }}
      >
        {/* Title */}
        <h2
          style={{
            fontSize: '24px',
            lineHeight: '32px',
            marginBottom: '24px',
          }}
        >
          {detail.id}
        </h2>
        {/* Contents */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomInput
              label='type'
              value={isMembers ? 'members' : 'chart'}
              disabled
            />
            <CustomInput
              label='views'
              description='조회수'
              value={detail.views}
              disabled
            />
          </div>
          <CustomInput
            label='longUrl'
            description='구매링크'
            inputStyle={{ width: '100%' }}
            value={detail.longUrl}
            onChange={(e) => {
              setDetail({ ...detail, longUrl: e.target.value });
            }}
          />
          <CustomInput
            label='shortUrl'
            description='중간링크(hashble)'
            inputStyle={{ width: '100%' }}
            value={detail.shortUrl}
            disabled
          />
          <CustomInput
            label='finalUrl'
            description='최종링크'
            inputStyle={{ width: '100%' }}
            value={detail.naverUrl}
            disabled
          />
          <div
            style={{
              border: '1px solid #dbe0eb',
              margin: '24px 0px',
            }}
          />
          <CustomInput
            inputStyle={{ width: '100%' }}
            label={isMembers ? 'contractId' : 'propositionId'}
            value={detail[isMembers ? 'contractId' : 'propositionId']}
            disabled
          />
          <CustomInput
            inputStyle={{ width: '100%' }}
            label={isMembers ? 'companyName' : 'cname'}
            value={
              isMembers
                ? detail.Contracts.companyName
                : detail.Propositions.cname
            }
            onChange={(e) => {
              setDetail({
                ...detail,
                [isMembers ? 'Contracts' : 'Propositions']: {
                  ...detail[isMembers ? 'Contracts' : 'Propositions'],
                  [isMembers ? 'companyName' : 'cname']: e.target.value,
                },
              });
            }}
          />
        </div>
        {/* Footer */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 8,
            marginTop: '48px',
          }}
        >
          <button
            onClick={onCloseHandler}
            style={{
              color: '#273144',
              border: '1px solid #dbe0eb',
              padding: '8px 16px',
              backgroundColor: '#fff',
            }}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            onClick={fetchPut}
            style={{
              position: 'relative',
              color: '#fff',
              border: '1px solid #2a5bd7',
              padding: '8px 16px',
              backgroundColor: '#2a5bd7',
            }}
          >
            {isLoading && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  border: '1px solid #2a5bd7',
                  backgroundColor: '#2a5bd7',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                {[...new Array(3)].map((_) => (
                  <div
                    key={_}
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      backgroundColor: '#fff',
                    }}
                  />
                ))}
              </div>
            )}
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailModal;
