import React, { PureComponent } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Delete from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Radio from '@material-ui/core/Radio';

import * as api from '../api';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";

class InfoAccount extends PureComponent {
  render() {
    const { companyUser, accounts } = this.props;

    if (accounts.length > 0) {
      return (
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <h3>{`${companyUser.name}님의 정산계좌`}</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
              <section>
                <label>계좌 상태</label>
                <TextField
                  fullWidth
                  value={accounts[0].status}
                  onChange={(evt) => this.props.handleChange('status', evt.target.value)}
                />
                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>예금주명</label>
                    <TextField
                      fullWidth
                      value={accounts[0].name}
                      onChange={(evt) => this.props.handleChange('name', evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>은행명</label>
                    <TextField
                      fullWidth
                      value={accounts[0].bankName}
                      onChange={(evt) => this.props.handleChange('bankName', evt.target.value)}
                    />
                  </div>
                </div>
                <label>계좌번호</label>
                <TextField
                  fullWidth
                  value={accounts[0].accountNumber}
                  onChange={(evt) => this.props.handleChange('accountNumber', evt.target.value)}
                />
                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>사업자등록증 사본</label>
                    <Button color='default' disabled={!accounts[0].licenseFile} onClick={() => {window.open(accounts[0].licenseFile)}}>
                      파일 열람
                    </Button>
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>통장 사본</label>
                    <Button color='default' disabled={!accounts[0].accountFile} onClick={() => {window.open(accounts[0].accountFile)}}>
                      파일 열람
                    </Button>
                  </div>
                </div>
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    else {
      return ('');
    }
  }
  async componentDidMount() {
  }
}

export default InfoAccount;
