import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const QuickScrollTopButton = ({ onClickHandler }) => {
  return (
    <div
      onClick={onClickHandler}
      style={{
        width: 56,
        height: 56,
        borderRadius: 56,
        position: 'fixed',
        border: '1px solid #e8ebf2',
        backgroundColor: '#fff',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        right: 56,
        bottom: 56,
        cursor: 'pointer',
      }}
    >
      <ExpandLessIcon style={{ width: 48, height: 48 }} />
    </div>
  );
};

export default QuickScrollTopButton;
