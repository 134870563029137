import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormLabel,
  Select,
} from "@material-ui/core";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { StyledTextField } from "../../StyledComponents";

const ChangePaymentInfoDialog = forwardRef(
  ({ paymentCards, onExecute }, ref) => {
    const schema = {
      plan: null,
      amount: null,
      payMethod: null,
      paymentCard: null,
    };
    const isNumber = (elem) => /\d+/.test(elem);

    const [formData, setFormData] = useState(
      JSON.parse(JSON.stringify(schema))
    );
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
    }));

    const isSubmitable = useMemo(() => {
      if (!formData) return false;
      else if (
        formData.plan === null ||
        formData.amount === null ||
        formData.payMethod === null
      )
        return false;
      else if (formData.payMethod === "card" && formData.paymentCard === null)
        return false;
      else return true;
    }, [formData]);

    const handleFormReset = () => {
      setFormData(JSON.parse(JSON.stringify(schema)));
    };

    const handleExecute = () => {
      console.log("@@ handleExecute", formData, isSubmitable);

      // if (onExecute) onExecute();
      // handleFormReset();
      // setOpen(false);
    };

    const handleClose = () => {
      handleFormReset();
      setOpen(false);
    };

    const handleChange = (fieldName, fieldValue) => {
      setFormData((prev) => {
        return { ...prev, [fieldName]: fieldValue };
      });
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>결제 정보 변경</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormLabel>결제 플랜</FormLabel>
              <Select
                type="number"
                variant="outlined"
                value={formData.plan === null ? "" : formData.plan}
                onChange={(evt) => {
                  if (isNumber(evt.target.value))
                    handleChange("plan", parseInt(evt.target.value));
                  else if (!evt.target.value) handleChange("plan", null);
                }}
                fullWidth
              >
                <option value={1}>1개월</option>
                <option value={3}>3개월</option>
                <option value={6}>6개월</option>
                <option value={12}>12개월</option>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>결제 금액</FormLabel>
              <StyledTextField
                type="number"
                variant="outlined"
                value={formData.amount === null ? "" : formData.amount}
                onChange={(evt) => {
                  if (isNumber(evt.target.value))
                    handleChange("amount", parseInt(evt.target.value));
                  else if (!evt.target.value) handleChange("amount", null);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormLabel>결제 수단</FormLabel>
              <Select
                variant="outlined"
                value={formData.payMethod === null ? "" : formData.payMethod}
                onChange={(evt) => {
                  if (evt.target.value)
                    handleChange("payMethod", evt.target.value);
                  else handleChange("payMethod", null);
                }}
                fullWidth
              >
                <option value="">없음</option>
                <option value="card">카드</option>
                <option value="cash">현금</option>
                <option value="free">무료제공</option>
              </Select>
            </Grid>
            {formData.payMethod === "card" && (
              <Grid item xs={12} md={6}>
                <FormLabel>결제 카드</FormLabel>
                <Select
                  value={
                    formData.paymentCard === null ? "" : formData.paymentCard
                  }
                  onChange={(evt) =>
                    handleChange("paymentCard", evt.target.value)
                  }
                  fullWidth
                  variant="outlined"
                >
                  <option value="">없음</option>
                  {(paymentCards || []).map((card, idx) => (
                    <option key={idx} value={card.id}>
                      {card.cardName + card.last4}
                    </option>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button
            onClick={handleExecute}
            color="secondary"
            variant="contained"
            disabled={!isSubmitable}
          >
            저장
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ChangePaymentInfoDialog;
