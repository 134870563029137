import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import _ from 'lodash';

import * as api from '../api';
import DetailBasic from './detail-basic';
import DetailProposition from "./detail-proposition";
import DetailCharge from "./detail-charge";
import DetailCompany from "./detail-company";
import {filterCompanyCharge, filterCompanyPropositions} from "../api";

class CompanyPoint extends React.Component {
  state = {
    id: this.props.location.query.id,
    schema: {},
    companyPoint: {},
    snackOpen: false,
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getCompanyPoint(this.state.id).then(async(result) => {
      let companyPoint = result.companyPoints[0];
      let result1 = await api.getCompanyUser(companyPoint.companyId);
      const company = result1.users[0];
      companyPoint.company = company;
      let result2 = await api.filterCompanyPropositions({companyId: companyPoint.companyId});
      companyPoint.waitPoint = _.sumBy(result2.propositions, (proposition) => {
        if (proposition.status === 1) {
          return proposition.point;
        }
        return 0;
      });
      let result3 = {};
      if (companyPoint.taggableType === 'charge') {
        result3 = await api.filterCompanyCharge({id: companyPoint.taggableId});
        companyPoint.charge = result3.charges[0];
      }
      else {
        result3 = await api.filterPropositions({id: companyPoint.taggableId, include: ['user']});
        companyPoint.proposition = result3.propositions[0];
      }
      console.log(companyPoint)
      this.setState({companyPoint: companyPoint});
    });
  }
  handleChange = (name, value) => {
    const companyPoint = { ...this.state.companyPoint };
    companyPoint[name] = value;
    this.setState({ companyPoint });
  };
  handleCancel = () => {
    this.props.push('/company-points');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteCompanyPoint(this.state.id)
        .then((result) => {
          alert('삭제 완료');
          this.props.push('/company-points');
        })
        .catch((err) => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .updateCompanyPoint(id, this.state.companyPoint)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };
  render() {
    const { companyPoint } = this.state;
    return (
      <div className='contents'>
        <div className='editor-container contents-editor'>
          <DetailBasic companyPoint={companyPoint} handleChange={this.handleChange} />
          {companyPoint.taggableType === 'charge' ?
            <DetailCharge companyPoint={companyPoint} handleChange={this.handleChange}/> :
            <DetailProposition companyPoint={companyPoint} handleChange={this.handleChange}/> }
        </div>
        <div className='additional-container contents-editor'>
          <DetailCompany companyPoint={companyPoint} handleChange={this.handleChange}/>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={4000}
          onClose={() => this.setState({ snackOpen: false })}
          message={<span>업데이트 성공</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={() => this.setState({ snackOpen: false })}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CompanyPoint);
