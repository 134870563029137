import { Checkbox } from '@material-ui/core';
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';
import Typo from '../../../../components/Typo';

const AdInformation = ({
  ad,
  size = 'lg',
  icon = null,
  showCheckbox = false,
  isChecked,
  onChange,
}) => {
  switch (size) {
    case 'lg':
      return (
        <div key={ad.title} style={styles.lgContainer}>
          <img src={ad.thumbnail} alt={ad.title} style={styles.lgImage} />
          <div style={styles.lgTitle}>{ad.title}</div>

          {showCheckbox && (
            <Checkbox
              size='small'
              color='primary'
              checked={isChecked}
              onChange={() => onChange(ad.adId)}
              icon={<CheckCircleOutline style={{ color: '#9f9f9f' }} />}
              checkedIcon={<CheckCircle style={{ color: 'green' }} />}
            />
          )}
        </div>
      );
    case 'sm':
      return (
        <div key={ad.title} style={styles.smContainer}>
          <div style={styles.smImageContainer}>
            <img src={ad.thumbnail} alt={ad.title} style={styles.smImage} />
          </div>
          <div style={styles.smTitleContainer}>
            <div style={styles.smTitle}>{ad.title || ' '}</div>
            <Typo style={{ fontSize: 10 }}>{ad.type}</Typo>
          </div>
          {icon && <div>{icon}</div>}
        </div>
      );
  }
};

const styles = {
  lgContainer: {
    width: '200px',
    height: '200px',
    padding: 8,
    border: '1px solid #eee',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  lgImage: {
    width: '100%',
    height: 100,
  },
  lgTitle: {
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  smContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '180px',
    height: '50px',
    padding: 4,
    border: '1px solid #eee',
    borderRadius: 8,
  },
  smImageContainer: {
    width: 24,
    height: 24,
    marginRight: 4,
  },
  smImage: {
    width: 24,
    height: 24,
  },
  smTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  smTitle: {
    width: 100,
    fontSize: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default AdInformation;
