import axios from "axios";

const HTTP_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export async function apiRequest(method, url, body = {}) {
  // console.log("@@ method, url, body", method, url, body);
  const BASE_URL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:4005"
      : "https://payment.supermembers.co.kr";

  try {
    const result = await axios({
      method,
      url: `${BASE_URL}${url}`,
      data: method !== HTTP_METHODS.GET ? body : undefined,
      headers: {
        Authorization: "Bearer soo",
      },
    });
    return {
      success: result.data.success,
      data: result.data.data,
      error: result.data.error,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          success: error.response.data.success,
          data: error.response.data.data,
          error: error.response.data.error,
        };
      } else if (error.request) {
        console.error("No response:", error.request);
        return {
          success: false,
          data: null,
          error: {
            code: "NETWORK_ERROR",
            message: "request not responded",
          },
        };
      } else {
        console.error("An unexpected error occurred (network):", error);
        return {
          success: false,
          data: null,
          error: {
            code: "INTERNAL_SERVER_ERROR",
            message: "An unexpected error occurred (network)",
          },
        };
      }
    } else {
      console.error("An unexpected error occurred:", error);
      return {
        success: false,
        data: null,
        error: {
          code: "INTERNAL_SERVER_ERROR",
          message: "An unexpected error occurred",
        },
      };
    }
  }
}
export async function apiRequestDummy(method, url, body = {}) {
  return { success: true, data: null, error: null };
}

export async function getAdsPaymentDetail({ adId }) {
  return await apiRequest(HTTP_METHODS.GET, `/ads/${adId}/detail`);
}

export async function updateAdsChannel({
  adId,
  adsBaseUpdatedAt,
  adsChanges,
  channelsBaseUpdatedAt,
  channelsChanges,
}) {
  return await apiRequest(HTTP_METHODS.PATCH, `/ads/${adId}/channel`, {
    adsBaseUpdatedAt,
    adsChanges,
    channelsBaseUpdatedAt,
    channelsChanges,
  });
}

export async function updateAdsPaymentMemo({
  adId,
  adsOrder,
  baseUpdatedAt,
  changes,
}) {
  return await apiRequest(HTTP_METHODS.PATCH, `/ads/${adId}/payment/memo`, {
    adsOrder,
    baseUpdatedAt,
    changes,
  });
}

export async function updateAdsPayment({
  adId,
  adsOrder,
  baseUpdatedAt,
  changes,
}) {
  return await apiRequestDummy(HTTP_METHODS.PATCH, `/ads/${adId}/payment`, {
    adsOrder,
    baseUpdatedAt,
    changes,
  });
}

export async function terminateAds({ adId }) {
  return await apiRequestDummy(HTTP_METHODS.POST, `/ads/${adId}/terminate`);
}

export async function createAdsPayment({ adId }) {
  return await apiRequest(HTTP_METHODS.POST, `/ads/${adId}/payment`);
}

export async function payWithCreditCard({ adId }) {
  return await apiRequestDummy(
    HTTP_METHODS.POST,
    `/ads/${adId}/pay/credit-card`,
    {}
  );
}

export async function payWithPackage({ adId }) {
  return await apiRequestDummy(
    HTTP_METHODS.POST,
    `/ads/${adId}/pay/package`,
    {}
  );
}

export async function payWithPremiumPackage({ adId }) {
  return await apiRequestDummy(
    HTTP_METHODS.POST,
    `/ads/${adId}/pay/premium-package`,
    {}
  );
}

export async function deleteAdsPayment({ adId, adsOrder }) {
  return await apiRequestDummy(
    HTTP_METHODS.DELETE,
    `/ads/${adId}/payment/${adsOrder}`,
    {}
  );
}
