import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../app/action';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './cards.css';
import { fetchData } from '../../app/api';
import Card from './card';
import { withStyles } from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import momentLib from 'moment-timezone';
import Button from "@material-ui/core/Button";

import ExcelDownload from '../excel-download';
import _ from 'lodash';

const styles = (theme) => ({
  Select: {
    marginTop: '4px',
    width: '100%',
  },
  save: {
    width: '100%',
    height: '30px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82,82,82,.12)',
  },
  date: {
    width: '30%',
  },
});

const today = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');

class KeywordShareCard extends Component {
  state = {
    total: 0,
    keywords1: [],
    keywords2: [],
    keywords: [],
    date: today,
    keyword: ''
  };

  componentDidMount() {
    if (this.props.category === 'R' || this.props.category.firstName === '매장') {
      fetchData('get', '/keyword', {category: '지역', orderBy: 'name'})
        .then((datas) => {
          this.setState({ keywords1: datas.keywords });
        })
        .catch((err) => {
          console.error(err);
          alert('지역 키워드 조회 실패');
        });

      fetchData('get', '/keyword', {category: '조합', orderBy: 'name'})
        .then((datas) => {
          this.setState({ keywords2: datas.keywords });
        })
        .catch((err) => {
          console.error(err);
          alert('조합 키워드 조회 실패');
        });
    } else {
      fetchData('get', '/keyword', {category: '제품', orderBy: 'name'})
        .then((datas) => {
          this.setState({ keywords1: datas.keywords });
        })
        .catch((err) => {
          console.error(err);
          alert('키워드 조회 실패');
        });
    }

    fetchData('get', '/company3/keyword2', { companyId: this.props.company })
      .then((datas) => {
        if (datas.length === 0) {
          datas.push({
            info: [ {}, {} ],
          });
        }
        this.setState({ keywords: datas });
      })
      .catch((err) => {
        console.error(err);
        alert('가맹점 키워드 조회 실패');
      });
  }

  handleSave = (keyword, confirm) => {
    this.props.activateLoading();
    let promiseFunc = Promise.resolve();
    if (!keyword.id && confirm) {
      if (keyword.info) {
        promiseFunc = fetchData('post', '/company3/keyword', {
          companyId: this.props.company,
          keywords: JSON.stringify(keyword.info)
        });
      }
    }
    else {
      if (keyword.id) {
        promiseFunc = fetchData('put', '/company3/keyword', {
          keywordId: keyword.id,
          companyId: this.props.company,
          name: keyword.name,
          keywords: JSON.stringify(keyword.info)
        });
      }
    }
    promiseFunc
      .then(() => {
        console.log('done')
        // window.location.reload();
        this.props.deactivateLoading();
      })
      .catch((err) => {
        this.props.deactivateLoading();
        console.error(err);
        alert('저장 실패');
      });
  }

  handleChangeKeyword = (keyword) => {
    this.setState({keyword: keyword});

    let keywords = [keyword];
    if (this.props.category === 'R' || this.props.category.firstName === '매장') {
      keywords = keyword.split('/') || [keyword];
    }
    let info = [];
    keywords.map((keyword, idx) => {
      let findKeyword = _.find(this.state['keywords' + (idx + 1)], {name: keywords[idx]});
      if (findKeyword) {
        info[idx] = findKeyword;
      }
    });
    if (this.state.keywords[this.state.keywords.length - 1].id) {
      this.setState((prevState) => ({
        keywords: [
          ...prevState.keywords,
          {
            info,
          },
        ],
      }));
    }
    else {
      this.state.keywords[this.state.keywords.length - 1].info = info;
      this.setState({ keywords: this.state.keywords });
    }
  }

  handleAddKeyword = async () => {
    if (!this.state.keywords[this.state.keywords.length - 1].id) {
      let keyword = this.state.keyword;
      let keywords = [keyword];
      if (this.props.category === 'R' || this.props.category.firstName === '매장') {
        let words = keyword.split('/') || [keyword];
        if (words.length > 1) {
          let [first, ...rest] = words;
          keywords = [first, rest.join(' ')];
        }
        else {
          keywords = words;
        }
      }
      await Promise.all(keywords.map(async (keyword, idx) => {
        let findKeyword = _.find(this.state['keywords' + (idx + 1)], {name: keywords[idx]});
        if (!findKeyword) {
          if (idx === 0) {
            if (this.props.category === 'R' || this.props.category.firstName === '매장') {
              fetchData('post', '/keyword', {
                category: '지역',
                name: keyword
              });
            }
            else {
              fetchData('post', '/keyword', {
                category: '제품',
                name: keyword
              });
            }
          }
          else {
            fetchData('post', '/keyword', {
              category: '조합',
              name: keyword
            });
          }
        }
      }));
      window.location.reload();
    }
  }

  requestKeywordDataAll = () => {
    const confirm = window.prompt('날짜를 입력해주세요 YYYY/MM/DD-YYYY/MM/DD');

    if(!confirm){
      return;
    }

    const [startDate, endDate] = confirm.split('-');
    const 시작날 = new Date(startDate.replace(/\//g, "-"));
    const 마지막날 = new Date(endDate.replace(/\//g, "-"));

    const 헤드 = '키워드,날짜,유저,등수';

    const csv =  [...this.state.keywords].reduce((누적, 키워드분석) => {
      Object.entries(키워드분석.ranks).forEach(([날짜,데이터]) => {
        const 날짜형식변환 = new Date(날짜);
        if(날짜형식변환.getTime() >= 시작날.getTime() && 날짜형식변환.getTime() <= 마지막날.getTime()){
          데이터.forEach(로우 => {
            누적.push(`${키워드분석.name},${날짜},${로우.level} ${로우.username},${로우.rank}`);
          })
        }
      });
      return 누적;
    },[헤드]).join('\n');

    let csvFile;
    let downloadLink;
        
    const BOM = "\uFEFF";
    const csv2 = BOM + csv;

    csvFile = new Blob([csv2],{type:'text/csv'});
    downloadLink = document.createElement('a');
    downloadLink.download = this.props.company; // 가맹점 아이디
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  render() {
    const { classes } = this.props;

    let groupedRanks = {};
    let fields = {};
    let cards = this.state.keywords.map((keyword, i) => {
      if (keyword.ranks) {
        groupedRanks = _.groupBy(Object.keys(keyword.ranks), (date) => {
          keyword.ranks[date].rank = _.minBy(keyword.ranks[date], 'rank');
          let dates = date.split('-');
          return dates[0] + dates[1];
        });
        Object.keys(groupedRanks).forEach((month) => {
          groupedRanks[month].minDate = _.minBy(groupedRanks[month], (date) => {
            // let rank = _.minBy(keyword.ranks[date], 'rank');
            return (keyword.ranks[date].rank || {}).rank;
          });

          fields['name'] = 'name';
          fields[month] = month;
          if (groupedRanks[month].minDate) {
            groupedRanks[month] = keyword.ranks[groupedRanks[month].minDate].rank.rank;
            this.state.keywords[i][month] = groupedRanks[month];
          } else {
            delete groupedRanks[month];
          }
        });
      }

      return (
        <Card
          key={i}
          index={i}
          keywords1={this.state.keywords1}
          keywords2={this.state.keywords2}
          keyword={keyword}
          isLast={this.state.keywords.length - 1 === i}
          date={this.state.date}
          handlePlus={() => {
            if (this.state.keywords.length < 20) {
              this.setState((prevState) => ({
                keywords: [
                  ...prevState.keywords,
                  {
                    info: [{}, {}],
                  },
                ],
              }));
            }
            else {
              alert('키워드는 최대 20개까지 등록 가능합니다.');
            }
          }}
          handleMinus={(index) => {
            if (window.confirm('정말 삭제 하시겠습니까')) {
              if (this.state.keywords[index].id) {
                fetchData('delete', '/company3/keyword', {
                  companyId: this.props.company,
                  keywordId: keyword.id,
                  keywordName: keyword.name
                });
              }
              let keywords = this.state.keywords.filter((keyword, i) => {
                return i !== index;
              });
              this.setState({ keywords: keywords });
            }
          }}
          handleChange={(index, keyword) => {
            this.state.keywords[index] = keyword;
            this.setState({ keywords: this.state.keywords });
          }}
          handleSave={(keyword, confirm) => {
            // let confirm = isFirst ? window.confirm('저장하시면 기존 데이타가 삭제 됩니다. 저장하시겠습니까?') : true;
            this.handleSave(keyword, confirm);
          }}
        />
      );
    });

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>상위 노출 공유</h3>
          <ExcelDownload
            data={this.state.keywords}
            filename={this.props.company}
            fields={fields}
          >
            Download
          </ExcelDownload>
          <Button style={{marginLeft: 'auto'}} onClick={this.requestKeywordDataAll}>
            DownloadAll
          </Button>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className='keyword-cards'>
            <div className='line'>
              <TextField
                id='date'
                type='date'
                defaultValue={today}
                className={classes.date}
                onChange={(event) => {
                  this.setState({ date: event.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                color='secondary'
                className={classes.button}
                onClick={() => {
                  Promise.all(this.state.keywords.map((keyword, i) => {
                    if (keyword.info[0].name || keyword.info[1].name && !keyword.rank) {
                      this.handleSave(keyword, true);
                    }
                  })).then(() => {
                    window.location.reload();
                    this.props.deactivateLoading();
                  }).catch((err) => {
                    this.props.deactivateLoading();
                    console.error(err);
                    alert('저장 실패');
                  });
                }}
              >
                일괄 저장
              </Button>
              <Button
                color='secondary'
                className={classes.button}
                onClick={() => {
                  this.state.keywords.map((keyword, i) => {
                    Promise.all(this.state.keywords.map((keyword, i) => {
                      if (keyword.info[0].name || keyword.info[1].name) {
                        this.handleSave(keyword, false);
                      }
                    }));
                  });
                }}
              >
                전체 재검색
              </Button>
              <TextField
                onChange={(evt)=>this.handleChangeKeyword(evt.target.value)}
                halfWidth
              />
              <Button
                color='secondary'
                className={classes.button}
                onClick={() => {
                  this.handleAddKeyword();
                }}
              >
                키워드 추가
              </Button>
            </div>
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(KeywordShareCard));
