import React from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { useEffect } from 'react';
import { useState } from 'react';

const GeneratedQna = () => {
  const [selected, setSelected] = useState('블로거');
  const [data, setData] = useState({
    광고주: [],
    블로거: [],
  });
  const [checkboxes, setCheckboxes] = useState({
    광고주: [],
    블로거: [],
  });
  const [selectedTap, setSelectedTap] = useState('');
  const [type, setType] = useState('');

  const getTypes = (items) => {
    let types = [];

    items.forEach((item) => {
      if (item.type.includes(',')) {
        types.push(...item.type.split(','));
      } else {
        types.push(item.type);
      }
    });

    return [...new Set(types)];
  };

  const generatedUuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const insertUuid = (data) => {
    return data.map((v) => ({ ...v, uuid: generatedUuidv4() }));
  };

  useEffect(() => {
    const getJSONData = async () => {
      try {
        const response = await fetch(
          'https://s3.ap-northeast-2.amazonaws.com/supermembers.upload/etc/qna.json'
        );
        const { 광고주, 블로거 } = await response.json();
        setData((prevData) => ({
          ...prevData,
          광고주: insertUuid(광고주),
          블로거: insertUuid(블로거),
        }));
        setCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          광고주: getTypes(광고주),
          블로거: getTypes(블로거),
        }));
      } catch (error) {}
    };

    getJSONData();
  }, []);

  const onAddType = () => {
    const updatedCheckboxes = { ...checkboxes };
    updatedCheckboxes[selected] = [...updatedCheckboxes[selected], type];
    setCheckboxes(updatedCheckboxes);
    setType('');
  };

  const onDeleteType = (target) => {
    const updatedCheckboxes = checkboxes[selected].filter((v) => v !== target);
    setCheckboxes({ ...checkboxes, [selected]: updatedCheckboxes });

    const updatedData = [...data[selected]].map((d) => {
      const { type, ...rest } = d;
      const newType = type
        .split(',')
        .filter((v) => v != target)
        .join(',');

      return {
        ...rest,
        type: newType,
      };
    });

    setData({ ...data, [selected]: updatedData });
  };

  const onQuestionHandler = (index, value) => {
    const updatedData = [...data[selected]];
    updatedData[index].question = value;
    setData({ ...data, [selected]: updatedData });
  };

  const onAnswerHandler = (index, value) => {
    const updatedData = [...data[selected]];
    updatedData[index].answer = value;
    setData({ ...data, [selected]: updatedData });
  };

  const onTypeHandler = (index, target, currentChecked) => {
    const updatedData = [...data[selected]];
    const types =
      updatedData[index].type === '' ? [] : updatedData[index].type.split(',');

    if (currentChecked) {
      types.push(target);
      console.log(types);
      const newType = [...types].join(',');
      updatedData[index].type = newType;
    } else {
      updatedData[index].type = types.filter((v) => v !== target).join(',');
    }

    setData({ ...data, [selected]: updatedData });
  };

  const onDeleteRow = (id) => {
    const updatedData = [...data[selected]];
    const except = updatedData.filter((v) => v.uuid !== id);
    setData({ ...data, [selected]: except });
  };

  const onAddRow = () => {
    const updatedData = [...data[selected]];
    updatedData.push({
      uuid: generatedUuidv4(),
      answer: '',
      question: '',
      type: '',
    });
    setData({ ...data, [selected]: updatedData });
  };

  const downloadJson = () => {
    const removeUuid = (data) => {
      return data.map((v) => {
        const { uuid, ...rest } = v;
        return {
          ...rest,
        };
      });
    };

    const blob = new Blob(
      [
        JSON.stringify({
          블로거: removeUuid(data.블로거),
          광고주: removeUuid(data.광고주),
        }),
      ],
      { type: 'application/json' }
    );
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'qna';
    a.click();
    a.remove();
  };

  return (
    <div>
      <ExpansionPanelSummary
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h3 style={{ flex: 1 }}>QNA JSON 생성기</h3>
        <span
          style={{
            height: '24px',
            borderRadius: '8px',
            background: '#4770bd',
            color: 'white',
            padding: '6px 8px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            downloadJson();
          }}
        >
          다운로드
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <section>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '16px' }}
            >
              {['블로거', '광고주'].map((v) => (
                <div
                  onClick={() => {
                    setSelected(v);
                  }}
                  style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: selected === v ? '#4770bd' : 'rgba(0, 0, 0, 0.26)',
                    height: '44px',
                    borderBottom: '1px solid',
                    borderBottomColor: selected === v ? '#4770bd' : 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {v}
                </div>
              ))}
            </div>
            <div style={{ marginBottom: '16px' }}>
              <input
                type='text'
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
              />
              <button onClick={onAddType}>추가</button>
            </div>
            <div>
              <div
                style={{
                  gap: '8px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: '16px',
                }}
              >
                {checkboxes[selected].map((v) => (
                  <span
                    style={{
                      height: '24px',
                      borderRadius: '8px',
                      background: '#4770bd',
                      color: 'white',
                      padding: '6px 8px',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {v}
                    <span
                      style={{
                        marginLeft: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDeleteType(v);
                      }}
                    >
                      X
                    </span>
                  </span>
                ))}
              </div>
            </div>
            <div>
              {data[selected].map((v, index) => {
                const types = v.type.split(',');

                return (
                  <div style={{ width: '100%' }} key={v.uuid}>
                    <div
                      style={{
                        display: 'flex',
                        padding: '16px 24px',
                        border: '1px solid #dfdfdf',
                      }}
                      onClick={() => {
                        if (selectedTap === v.uuid) {
                          setSelectedTap('');
                        } else {
                          setSelectedTap(v.uuid);
                        }
                      }}
                    >
                      <p
                        style={{
                          flex: 1,
                          fontWeight: '500',
                        }}
                      >
                        {v.question}
                      </p>
                      <span
                        style={{
                          color:
                            selectedTap === v.uuid
                              ? '#4770bd'
                              : 'rgba(0, 0, 0, 0.26)',
                        }}
                      >
                        {v.uuid === selectedTap ? '▲' : '▼'}
                      </span>
                    </div>
                    {v.uuid === selectedTap && (
                      <div
                        style={{
                          padding: '16px 24px',
                          border: '1px solid #dfdfdf',
                        }}
                      >
                        <div>
                          <div>
                            <div style={{ marginBottom: 4, fontWeight: 500 }}>
                              type
                            </div>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {checkboxes[selected].map((check) => (
                                <label
                                  style={{
                                    margin: '0px 4px 0px 0px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <input
                                    type='checkbox'
                                    value={check}
                                    checked={types.includes(check)}
                                    onChange={(event) => {
                                      onTypeHandler(
                                        index,
                                        check,
                                        event.target.checked
                                      );
                                    }}
                                  />
                                  {check}
                                </label>
                              ))}
                            </div>
                            <div style={{ marginBottom: 4, fontWeight: 500 }}>
                              question
                            </div>
                            <div>
                              <textarea
                                rows='5'
                                style={{
                                  width: '100%',
                                  resize: 'none',
                                  boxSizing: 'border-box',
                                }}
                                value={v.question}
                                onChange={(event) => {
                                  onQuestionHandler(index, event.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <div style={{ marginBottom: 4, fontWeight: 500 }}>
                              answer
                            </div>
                            <div>
                              <textarea
                                rows='5'
                                style={{
                                  width: '100%',
                                  resize: 'none',
                                  boxSizing: 'border-box',
                                }}
                                value={v.answer}
                                onChange={(event) => {
                                  onAnswerHandler(index, event.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span
                              style={{
                                height: '24px',
                                borderRadius: '8px',
                                background: '#4770bd',
                                color: 'white',
                                padding: '6px 8px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                              onClick={() => {
                                onDeleteRow(v.uuid);
                              }}
                            >
                              삭제하기
                            </span>
                            <span
                              onClick={() => {
                                setSelectedTap('');
                              }}
                              style={{
                                height: '24px',
                                borderRadius: '8px',
                                background: '#4770bd',
                                color: 'white',
                                padding: '6px 8px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              닫기
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}

              <div
                style={{
                  display: 'flex',
                  padding: '16px 24px',
                  border: '1px solid #dfdfdf',
                }}
                onClick={() => {
                  onAddRow();
                }}
              >
                <p
                  style={{
                    flex: 1,
                    fontWeight: '500',
                    textAlign: 'center',
                  }}
                >
                  추가하기
                </p>
              </div>
            </div>
          </section>
        </div>
      </ExpansionPanelDetails>
    </div>
  );
};

export default GeneratedQna;
