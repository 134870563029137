import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../../../action';
import { LEVEL, PLATFORM_TYPE } from '../../constants';
import momentLib from 'moment-timezone';

import CustomTextField from '../../../../../components/CustomTextField';
import CustomSwitch from '../../../../../components/CustomSwitch';
import CustomSelect from '../../../../../components/CustomSelect';

const InfoBasic = (props) => {
  const { adsWithDetails, setAdsWithDetails } = props;

  const channels = PLATFORM_TYPE.map((item, index) => ({
    value: item,
    name: item,
  }));

  const types = [
    { value: 'STANDARD', name: 'STANDARD' },
    { value: 'EXPRESS', name: 'EXPRESS' },
  ];

  const status = [
    { value: 'CLOSED', name: 'CLOSED' },
    { value: 'OPEN', name: 'OPEN' },
  ];

  const InfoDetails = (
    <>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'id'}
          value={adsWithDetails.ad.id}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                id: event.target.value,
              },
            });
          }}
        />
        <CustomTextField
          label={'companyId'}
          value={adsWithDetails.ad.companyId}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                companyId: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'title'}
          value={adsWithDetails.ad.title}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                title: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomSelect
          menus={channels}
          label={'channel'}
          value={adsWithDetails.ad.channel}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                channel: event.target.value,
              },
            });
          }}
        />
        <CustomSelect
          menus={types}
          label={'type'}
          value={adsWithDetails.ad.type}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                type: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomSelect
          menus={status}
          label={'status'}
          value={adsWithDetails.ad.status}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                status: event.target.value,
              },
            });
            if (event.target.value === 'OPEN') {
              // NOTE: OPEN으로 바꾸면 OPEN시 반드시 들어가야할 값들 넣기
              let updateData = {
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  status: event.target.value,
                },
                channel: {
                  ...adsWithDetails.channel,
                  ...(!adsWithDetails.channel.startDate && {
                    startDate: momentLib(new Date())
                      .tz('Asia/Seoul')
                      .format('YYYY-MM-DD'),
                  }),
                  ...(!adsWithDetails.channel.endDate && {
                    endDate: momentLib(
                      adsWithDetails.channel.startDate || new Date()
                    )
                      .tz('Asia/Seoul')
                      .add(30, 'day')
                      .format('YYYY-MM-DD'),
                  }),
                  ...(!adsWithDetails.channel.deadlineRecruitment && {
                    deadlineRecruitment: 14,
                  }),
                  ...(!adsWithDetails.channel.deadlineAnnouncement && {
                    deadlineAnnouncement: 2,
                  }),
                  ...(!adsWithDetails.channel.deadlineReview && {
                    deadlineReview: 10,
                  }),
                },
              };

              // LEVEL.map((item, _) => {
              //   const levelAvailable = adsWithDetails.ad.availableLevels.includes(item);
              //   if (levelAvailable && (adsWithDetails.channel.totalPrice || {})[item]) {
              //     // let discountRate = adsWithDetails.channel.discountRate[item] ??

              //     // adsWithDetails.channel.totalPrice[item] - adsWithDetails.channel.finalPrice[item]

              //     // adsWithDetails.channel.discountRate[item]
              //     // let finalPrice = adsWithDetails.channel.finalPrice[item];
              //     updateData = {
              //       ...updateData,
              //       channel: {
              //         ...updateData.channel,
              //         // discountRate: {
              //         //   ...updateData.channel.discountRate,
              //         //   [item]: Number(discountRate),
              //         // },
              //         // finalPrice: {
              //         //   ...updateData.channel.finalPrice,
              //         //   [item]: Number(finalPrice),
              //         // }
              //       },
              //     };
              //   }
              // });
              setAdsWithDetails(updateData);
            }
          }}
        />
        <CustomTextField
          label={'currentAdsOrder'}
          value={adsWithDetails.ad.currentAdsOrder}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                currentAdsOrder: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'startDate'}
          value={adsWithDetails.channel.startDate}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                startDate: event.target.value,
              },
            });
          }}
        />
        <CustomTextField
          label={'endDate'}
          value={adsWithDetails.channel.endDate}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                endDate: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'createdAt'}
          value={adsWithDetails.ad.createdAt}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                createdAt: event.target.value,
              },
            });
          }}
        />
        <CustomTextField
          label={'updatedAt'}
          value={adsWithDetails.ad.updatedAt}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                updatedAt: event.target.value,
              },
            });
          }}
        />
      </div>

      <div>
        <CustomSwitch
          label='verified'
          checked={Boolean(adsWithDetails.ad.verified) || false}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                verified: event.target.checked,
              },
            });
          }}
        />
      </div>
    </>
  );

  return <>{InfoDetails}</>;
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoBasic);
