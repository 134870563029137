import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../app/action';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './cards.css';
import { fetchData } from '../../app/api';
import Card from './card';
import { withStyles } from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import momentLib from 'moment-timezone';
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  Select: {
    marginTop: '4px',
    width: '100%',
  },
  save: {
    width: '100%',
    height: '30px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82,82,82,.12)',
  },
  date: {
    width: '30%',
  },
});

const today = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');

class PlaceKeywordCard extends Component {
  state = {
    total: 0,
    placeKeywords: [],
    date: today,
  };

  componentDidMount() {
    fetchData('get', 'company3/place/keyword', { adId: this.props.company })
      .then((datas) => {
        if (datas.length === 0) {
          datas.push({
            adId: this.props.company
          });
        }
        this.setState({ placeKeywords: datas });
      })
      .catch((err) => {
        console.error(err);
        alert('플레이스 키워드 조회 실패');
      });
  }

  handleSave = (keyword, confirm) => {
    this.props.activateLoading();
    let promiseFunc = Promise.resolve();
    if (!keyword.id && confirm) {
      if (keyword.name) {
        promiseFunc = fetchData('post', 'company3/place/keyword', {
          adId: this.props.company,
          keywords: keyword.name
        });

        promiseFunc
          .then(() => {
            console.log('done')
            window.location.reload();
            this.props.deactivateLoading();
          })
          .catch((err) => {
            this.props.deactivateLoading();
            console.error(err);
            alert('저장 실패');
          });
      }
    }
    else {
      this.props.deactivateLoading();
      alert('키워드 수정이 불가능합니다.');
    }
  }

  render() {
    const { classes } = this.props;

    let cards = this.state.placeKeywords.map((keyword, i) => {
      return (
        <Card
          key={i}
          index={i}
          placeKeyword={keyword}
          isLast={this.state.placeKeywords.length - 1 === i}
          date={this.state.date}
          handlePlus={() => {
            if (this.state.placeKeywords.length < 3) {
              this.setState((prevState) => ({
                placeKeywords: [
                  ...prevState.placeKeywords,
                  {adId: this.props.company},
                ],
              }));
            }
            else {
              alert('키워드는 최대 3개까지 등록 가능합니다.');
            }
          }}
          handleMinus={(index) => {
            if (window.confirm('정말 삭제 하시겠습니까')) {
              if (this.state.placeKeywords[index].id) {
                fetchData('delete', 'company3/place/keyword', {
                  id: this.state.placeKeywords[index].id
                });
              }
              let keywords = this.state.placeKeywords.filter((keyword, i) => {
                return i !== index;
              });
              this.setState({ placeKeywords: keywords });
            }
          }}
          handleSave={(keyword, confirm) => {
            this.handleSave(keyword, confirm);
          }}
        />
      );
    });

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>네이버 플레이스 키워드 순위</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className='keyword-cards'>
            <div className='line'>
              <TextField
                id='date'
                type='date'
                defaultValue={today}
                className={classes.date}
                onChange={(event) => {
                  this.setState({ date: event.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(PlaceKeywordCard));
