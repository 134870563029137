import React, { useState } from 'react';
import ActionCreators from '../action';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import { saveAs } from 'file-saver';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { SortableTableHead, SearchInput } from '../../components';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import {
  getInquiries,
  updateInquiry,
} from '../../services/supermembers/inquiry';
import { INQUIRY_MODIFY_ALLOWED_CATEGORY } from '../../constants';

const header = [
  { sortable: false, id: 'id', label: 'id' },
  { sortable: false, id: 'createdAt', label: '등록일' },
  { sortable: false, id: 'os', label: 'OS' },
  { sortable: false, id: 'appVersion', label: '앱버전' },
  { sortable: false, id: 'isAnswered', label: '처리여부' },
  { sortable: false, id: 'category', label: '카테고리' },
  { sortable: false, id: 'subCategory', label: '카테고리 2' },
  { sortable: false, id: 'adId', label: 'adId' },
  { sortable: false, id: 'contentText', label: '상세내용' },
  { sortable: false, id: 'user', label: '블로거' },
  { sortable: false, id: 'edit', label: '상세보기' },
];

const excludedHeaders = ['id', 'createdAt', 'edit'];

const searchHeader = header.filter(
  (item) => !excludedHeaders.includes(item.id)
);

const Inquiries = (props) => {
  const [state, setState] = useState({
    page: 1,
    rowsPerPage: 20,
    orderBy: 'registedAt',
    direction: 'desc',
    inquiries: [],
    total: 0,
    options: {},
  });

  const { rowsPerPage, orderBy, direction, inquiries, total, options } = state;

  const fetchDatas = (pageNum, orderBy, direction, pageOptions) => {
    props.activateLoading();

    // NOTE: page를 계산하는 과정에서 pageNum이 null이 들어가는 경우 or pageNum에 수가 들어가는 경우 모두 true인 조건으로 분기되기 때문에 'pageNum &&'가 추가되어야 함. 이후에, 큰 문제점이 없으면 해당 코드로 모두 대체

    const page = pageNum != null && pageNum >= 0 ? pageNum + 1 : state.page;

    const options = {
      ...options,
      ...pageOptions,
    };

    const filter = {
      page: page,
      limit: rowsPerPage,
      ...options,
    };

    getInquiries(filter).then((results) => {
      setState({
        ...state,
        ...results,
        page: page,
        total: results.count,
        options,
      });
      props.deactivateLoading();
    });
  };

  const handleChangePage = (event) => {
    fetchDatas(event.selected, orderBy, direction, options);
  };

  const handleSearch = (selectItem, findText) => {
    fetchDatas(0, orderBy, direction, {
      [selectItem]: findText,
    });
  };

  const handleDateOnChange = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return;
    }

    fetchDatas(0, orderBy, direction, { startDate, endDate });
  };

  const handleDataInRange = async (startDate, endDate) => {
    if (!startDate || !endDate) {
      alert('시작 날짜와 종료 날짜를 선택해주세요.');
      return;
    }

    props.activateLoading();

    const startOfStartDate = momentLib(startDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss');

    const endOfEndDate = momentLib(endDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');

    const filter = {
      fetchAll: true,
    };

    const results = await getInquiries(filter);

    const filteredInquiries = results.inquiries.filter((inquiry) => {
      return momentLib(inquiry.createdAt)
        .tz('Asia/Seoul')
        .isBetween(startOfStartDate, endOfEndDate, null, '[]');
    });

    let data =
      'id, 등록일, OS, 앱버전, 처리여부, 카테고리, 카테고리2, 상세내용, uid\n';

    filteredInquiries.forEach((inquiry) => {
      const replacedContentText = `${inquiry.contentText
        .replace(/\n/g, ' ')
        .replace(/"/g, '""')}`;

      data += `${inquiry.id}, ${inquiry.createdAt}, ${inquiry.os}, ${inquiry.appVersion}, ${inquiry.isAnswered}, ${inquiry.category}, ${inquiry.subCategory},`;
      data += `"${replacedContentText}",`;
      data += `${inquiry.uid}\n`;
    });

    let blob = new Blob([data], {
      type: 'text/plain',
      charset: 'utf-8',
    });

    saveAs(blob, ['inquiries' + '.' + 'csv']);

    props.deactivateLoading();
  };

  const pageCount =
    parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

  let bodys =
    inquiries &&
    inquiries.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.id}</TableCell>
          <TableCell>
            {momentLib(item.createdAt).format('YY-MM-DD HH:mm')}
          </TableCell>
          <TableCell>{item.os}</TableCell>
          <TableCell>{item.appVersion}</TableCell>
          <TableCell>
            <Checkbox
              checked={item.isAnswered}
              disabled={
                item.isAnswered ||
                !INQUIRY_MODIFY_ALLOWED_CATEGORY.includes(item.category)
              }
              onChange={(event) => {
                updateInquiry(item.id, {
                  ...inquiries[index],
                  isAnswered: event.target.checked,
                });

                setState({
                  ...state,
                  inquiries: [
                    ...state.inquiries.slice(0, index),
                    {
                      ...state.inquiries[index],
                      isAnswered: event.target.checked,
                    },
                    ...state.inquiries.slice(index + 1),
                  ],
                });
              }}
            />
          </TableCell>
          <TableCell>{item.category}</TableCell>
          <TableCell>{item.subCategory}</TableCell>
          <TableCell>{item.adId || '-'}</TableCell>
          <TableCell style={{ maxWidth: 600 }}>{item.contentText}</TableCell>
          <TableCell>
            <Link to={`/user?uid=${item.uid}`}>{item.uid}</Link>
          </TableCell>
          <TableCell>
            <Link to={`/inquiry?id=${item.id}`} target='_blank'>
              보기
            </Link>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className='table-container'>
      <SearchInput
        header={searchHeader}
        handleSearch={handleSearch}
        handleDataInRange={handleDataInRange}
        handleDateOnChange={handleDateOnChange}
        handleDataInRangeLabel='엑셀로 추출'
      />
      <Table>
        <SortableTableHead
          orderBy={orderBy}
          order={direction}
          columns={header}
          handleSort={(orderBy, order) =>
            fetchDatas(null, orderBy, order, null)
          }
        />
        <TableBody>{bodys}</TableBody>
      </Table>
      <div className='table-footer'>
        <div></div>
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={(event) => handleChangePage(event)}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div></div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Inquiries);
