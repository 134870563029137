import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableRow from '@material-ui/core/TableRow';


import * as api from './api'
import ActionCreators from '../action'
import {SearchInput, SortableTableHead} from '../../components'
import _ from 'lodash';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const header = [
  {sortable: true, id: 'order', label: '차수'},
  {sortable: true, id: 'name', label: '가맹점명'},
  {sortable: true, id: 'channel', label: '채널명'},
  {sortable: true, id: 'type', label: '광고 상품'},
  {sortable: true, id: 'payState', label: '결제 상태'},
  {sortable: true, id: 'extend', label: '연장'},
  {sortable: true, id: 'dday', label: 'D-day'},
  {sortable: true, id: 'alarm1st', label: '1차'},
  {sortable: true, id: 'alarm2nd', label: '2차'},
  {sortable: true, id: 'payRequestCnt', label: '결제 요청건'},
  {sortable: true, id: 'adsStart', label: '결제 예정일'},
  {sortable: true, id: 'contractCount', label: '이용자 수'},
  {sortable: true, id: 'payMethod', label: '결제 수단'},
  {sortable: true, id: 'edit', label: '정보'}
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  paycards: [],
  total: 0
};

class CompanyPaycards extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, 'id', 'desc', {[selectItem]: findText})
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined
    });

    this.setState({...state, ...initialState});
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { paycards, total, rowsPerPage, schema, ...rest } = (_.pickBy(options, _.identity) || {});

    const page = (pageNum >= 0) ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction
    };

    api.filterCompanyPayCards(filter).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const {rowsPerPage, orderBy, direction, paycards, total} = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = paycards.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.id + '-' + item.order}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.channel}</TableCell>
          <TableCell>{item.type === 'N' ? '제품-실속형' : (item.type === 'S' ? '제품-표준형' : '매장')}</TableCell>
          <TableCell>{item.payState === 0 ? '결제예정' : item.payState === 1 ? '결제완료' : item.payState === 2 ? '결제요청' : '결제중지'} </TableCell>
          <TableCell>
            {item.extend !== undefined ? <Checkbox checked={item.extend} disabled/> : ''}
          </TableCell>
          <TableCell>{item.dday}</TableCell>
          <TableCell>
            {item.alarm1st !== undefined ? <Checkbox checked={item.alarm1st} onChange={(event) => {
              api.updateCompanyPayCard(item.id ,item.channel, {alarm1st: event.target.checked, id: item.alarmOrder});
              paycards[index].alarm1st = event.target.checked;
              this.setState({paycards: paycards});
            }}/> : ''}
          </TableCell>
          <TableCell>
            {item.alarm2nd !== undefined ? <Checkbox checked={item.alarm2nd} onChange={(event) => {
              api.updateCompanyPayCard(item.id ,item.channel, {alarm2nd: event.target.checked, id: item.alarmOrder});
              paycards[index].alarm2nd = event.target.checked;
              this.setState({paycards: paycards});
            }}/> : ''}
          </TableCell>
          <TableCell>{item.payRequestCnt}</TableCell>
          <TableCell>{item.dday > 0 ? item.nextPaidAt : item.adsStart}</TableCell>
          <TableCell>{item.contractCount}</TableCell>
          <TableCell>{item.payMethod}</TableCell>
          <TableCell><Link to={'/paycard?id=' + item.id + '&channel=' + item.channel} target="_blank">편집</Link></TableCell>
        </TableRow>
      )
    });
    return (
      <div className="table-container">
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}/>
          <TableBody>
            {bodys}
          </TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate previousLabel={"< 이전"}
                         nextLabel={"다음 >"}
                         breakLabel={<span>...</span>}
                         pageCount={pageCount}
                         marginPagesDisplayed={1}
                         pageRangeDisplayed={10}
                         onPageChange={this.handleChangePage}
                         containerClassName={"pagination"}
                         subContainerClassName={"pages pagination"}
                         activeClassName={"active"}
                         initialPage={0}
                         forcePage={this.state.page - 1}/>
          <div></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPaycards)