import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { uploadFile } from '../../app/api';

class ImageForm extends PureComponent {
  static propTypes = {
    initialURL: PropTypes.string,
  };
  state = {
    url: this.props.initialURL ? this.props.initialURL : '',
    file: null,
  };
  handleChange = (name, value) => {
    this.setState({ [name]: value });
    const state = { ...this.state, [name]: value };
    this.props.handleChange({ url: state.url });
  };
  handleUpload = (evt) => {
    const file = evt.target.files[0];
    const id = document.location.href.split('id=')[1];
    const path = 'notice/' + id + '/' + new Date().getTime();
    uploadFile(path, file, (progress) => {
      console.log(progress);
    })
      .then((url) => {
        this.handleChange('url', url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { url } = this.state;
    return (
      <div>
        <section>
          <label>이미지 업로드</label>
          <Button variant='contained' onClick={() => this.fileinput.click()}>업로드</Button>
          <input
            type='file'
            ref={(comp) => (this.fileinput = comp)}
            accept='image/*'
            onChange={this.handleUpload}
            style={{ display: 'none' }}
          />
          <label>이미지 URL</label>
          <TextField
            fullWidth
            value={url}
            onChange={(evt) => this.handleChange('url', evt.target.value)}
            margin='normal'
          />
        </section>
      </div>
    );
  }
}

export default ImageForm;
