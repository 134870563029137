import React, { PureComponent } from 'react';
import momentLib from 'moment-timezone'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getAnswers } from '../api';
import Button from "@material-ui/core/Button";

import * as api from '../api'; 

class QuestionAnswer extends PureComponent {
  state = {
    qS: [],
    qI: [],
  };
  render() {
    const { company } = this.props;
    const { qS, qI } = this.state;

    const qSElements = qS.map((q) => { 
      return (
        <div
          key={q.id}
          style={{ display: 'flex', justifyContent: 'space-between', padding: 12, borderBottom: '1px solid #dbdbdb' }}
        >
          <h4>{q.question}</h4>
          <div>
            <span className='red'>{q.score.toFixed(2)}</span>/5.0
          </div>
        </div>
      );
    });
    const qIElements = qI.map((q) => { 
      const aList = q.answers.map((a) => {
        return (
          <div
            key={a.id}
            style={{ padding: 12, borderBottom: '1px solid #dbdbdb' }}
          >
            <div style={{display: 'flex'}}>
              <h4 style={{width: 110}}>{momentLib(a.usedDate).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</h4>
              <div style={{marginLeft: 20, width: 150}}>
                <a className='red' href={'/user?uid='+a.user}>
                  {
                    a.username ?
                      a.username :
                      '미입력'
                  }
                </a>
              </div>
              <div className='row' style={{justifyContent:'flex-end'}}>
                <Button color='secondary' onClick={() => this.handleDelete(q.id, a.id)}>삭제</Button>
              </div>
            </div>
            <div style={{marginTop: 12, flexGrow: 0}}>
              {a.answer}
            </div>
          </div>
        );
      });
      return (
        <div key = {q.id}>
          <h4 style={{ marginTop: 20, paddingBottom: 8, borderBottom: '1px solid #757575' }}>
            <span style={{ fontWeight: 400 }}>텍스트 입력 항목</span> {q.question}
          </h4>
          {aList}
        </div>
      );
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${company.name} 평가내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <h4 style={{ paddingBottom: 8, borderBottom: '1px solid #757575' }}>
              <span style={{ fontWeight: 400 }}>별점선택 항목</span>
              {qS.length}
            </h4>
            {qSElements}
            {qIElements}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() { 
    const {company} = this.props;
    if (company.question) { 
      Promise.all(company.question.map((questionId) => getAnswers(questionId, company.id))).then((results) => {
        const qS = [];
        const qI = [];
        results.forEach(([question, answers]) => {
          if (question.questions[0].questionType === 'S') { 
            const q = {id: question.questions[0].id, question: question.questions[0].question, score: []};
            const answers_type_s = answers.filter(elem => elem.score !== null)  
            answers_type_s.forEach((answer) => {
              q.score.push(answer.score);
            });
            q.score = q.score.reduce((prev, curr) => prev + curr, 0) / q.score.length;
            qS.push(q);
          } else { 
            const answers_type_i = answers.filter(elem => !!elem.answer) 
            qI.push({id: question.questions[0].id, question: question.questions[0].question, answers:answers_type_i});
          }
        }); 
        this.setState({qS, qI});
      }); 
    }
    else {
      this.setState({qs: [], qI: []});
    }
  }

  handleDelete = (questionId, answerId) => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      api.deleteAnswer(questionId, answerId).then(() => {
        window.location.reload();
      }).catch(console.log);
    }
  };
}

export default QuestionAnswer;
