import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dropzone from 'react-dropzone'

import {uploadFile} from '../../app/api';

export default class File extends React.Component {
    state = {
        oldURL:this.props.field.value,
        file:null,
        filename:'',
        uploadStep:0,
        progress:null
    }
    onDrop = (acceptedFiles, rejectedFiles) => {
        this.setState({file:acceptedFiles[0], filename:acceptedFiles[0].name, uploadStep:1})
    }
    handleClose = () => {
        this.setState({uploadStep:0, progress:null})
    }
    handleUpload = () => {
        const {field} = this.props;
        const {file, filename} = this.state;
        let fileRef = field.filepath + '/';
        if(field.filename) {
            fileRef += field.filename;
        } else {
            fileRef += filename;
        }
        uploadFile(fileRef, file, progressObj => {
            this.setState({progress:progressObj.progress})
        }).then(downloadURL => {
            this.props.handleChange(downloadURL)
            this.setState({uploadStep:2})
        }).catch(err => {
            console.log(err)
            alert('파일 업로드에 실패했습니다.')
        })
    }
    render() {
        const {field} = this.props;
        let {uploadStep, preview, progress} = this.state;

        let dialogTitle = '';
        let dialogBody = '';
        let dialogActions = '';

        if(uploadStep === 1) {
            dialogTitle = (
                <DialogTitle>파일 업로드</DialogTitle>
            );
            dialogBody = (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    아래 파일을 업로드하시겠습니까?
                    </DialogContentText>
                    <div className="preview">
                        <img src={preview} width="100%" alt="이미지 프리뷰"/>
                        {
                            progress  !== null ?
                            <div className="overlay">
                                <div className="progress">{progress}%</div>
                            </div> : ''
                        }
                    </div>
                </DialogContent>
            );
            dialogActions = (
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                    취소
                    </Button>
                    <Button onClick={()=>document.getElementById('dropper').click()} color="primary">
                    다시선택하기
                    </Button>
                    <Button onClick={this.handleUpload} color="secondary" autoFocus>
                    업로드하기
                    </Button>
                </DialogActions>
            )
        }   
        else if(uploadStep === 3) {
            dialogTitle = (
                <DialogTitle>이미지 업로드 완료</DialogTitle>
            );
            dialogBody = (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    파일 업로드가 완료되었습니다.<br/>
                    저장 버튼을 누르셔야 URL이 DB에 저장됩니다.
                    </DialogContentText>
                </DialogContent>
            );
            dialogActions = (
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                    닫기
                    </Button>
                </DialogActions>
            )
        }  
                    
        return (
            <div className="row">
                <label>{field.name}</label>
                <div className="flex-row">
                <TextField
                    fullWidth
                    multiline={field.multiple ? true : false}
                    rows={6}
                    value={field.value === null ? '' : field.value}
                    onChange={(evt)=>this.props.handleChange(evt.target.value)} />
                    <button className="icon-button" onClick={()=>document.getElementById('dropper').click()}>
                        <i className="material-icons">file_upload</i>
                    </button>
                    {
                        field.value ? 
                        <button className="icon-button" onClick={()=>window.open(field.value)}>
                            <i className="material-icons">open_in_new</i>
                        </button>:''
                    }
                </div>
                <Dropzone 
                    onDrop={this.onDrop} 
                    id={'dropper'} 
                    style={{display:'none'}} 
                    accept={field.filetype === undefined ? '*' : field.filetype}>
                    <p>Click or drag and drop</p>
                </Dropzone>
                <Dialog
                    open={uploadStep > 0}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    {dialogTitle}
                    {dialogBody}
                    {dialogActions}
                </Dialog>
            </div>
        )
    }
}