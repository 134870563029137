import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../../../action';
import CustomTextField from '../../../../../components/CustomTextField';
import CustomSelect from '../../../../../components/CustomSelect';

const InfoBasic = (props) => {
  const { productReviewConfig, setProductReviewConfig } = props;

  const targets = [
    { value: 'shopify', name: 'shopify' },
    { value: 'cafe24', name: 'cafe24' },
  ];

  const onChangeHandler = (event, field) => {
    setProductReviewConfig({
      ...productReviewConfig,
      [field]: event.target.value,
    });
  };

  return (
    <>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'id'}
          value={productReviewConfig.id}
          layoutStyle={{ flex: 1 }}
          disabled
          onChange={(event) => onChangeHandler(event, 'id')}
        />
        <CustomTextField
          label={'name'}
          value={productReviewConfig.name}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => onChangeHandler(event, 'name')}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'sourceUrl'}
          value={productReviewConfig.sourceUrl}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => onChangeHandler(event, 'sourceUrl')}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomSelect
          menus={targets}
          label={'target'}
          value={productReviewConfig.target}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => onChangeHandler(event, 'target')}
        />
        <CustomTextField
          label={'targetId'}
          value={productReviewConfig.targetId}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => onChangeHandler(event, 'targetId')}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'updatedAt'}
          value={productReviewConfig.updatedAt}
          layoutStyle={{ flex: 1 }}
          disabled
          onChange={(event) => onChangeHandler(event, 'updatedAt')}
        />
        <CustomTextField
          label={'createdAt'}
          value={productReviewConfig.createdAt}
          layoutStyle={{ flex: 1 }}
          disabled
          onChange={(event) => onChangeHandler(event, 'createdAt')}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoBasic);
