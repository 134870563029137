import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import _ from 'lodash';
import {getUser} from "../../users/api";
import * as api from "../../etc/api";

class InfoBlock extends PureComponent {
  state = {
    blocked: '',
  };
  render() {
    const { blocked } = this.state;

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary >
          <h3>유저 차단</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <section>
              <div className="row">
                <span>UID: </span>
                <TextField value={blocked} onChange={(evt) => this.setState({blocked: evt.target.value})} fullWidth/>
                <IconButton onClick={() => this.handleAdd()}>
                  <Add/>
                </IconButton>
                <IconButton onClick={() => this.handleRemove()}>
                  <Remove/>
                </IconButton>
              </div>
            </section>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  handleAdd = async () => {
    const { companies } = this.props;
    const { blocked } = this.state;
    if (companies.length === 0) {
      alert('대상 광고가 없습니다.');
      return;
    }

    const userData = (await api.getUser(blocked)).users[0];
    companies.forEach((company) => {
      userData.blocked = userData.blocked || [];
      userData.blocked.push(company.id + "");
    });
    await api.updateUser(blocked, userData);
    alert("차단 완료")
  }
  handleRemove = async () => {
    const { companies } = this.props;
    const { blocked } = this.state;
    if (companies.length === 0) {
      alert('대상 광고가 없습니다. 광고 로딩중...');
      return;
    }

    const userData = (await api.getUser(blocked)).users[0];
    companies.forEach((company) => {
      userData.blocked = userData.blocked || [];
      userData.blocked = userData.blocked.filter((block) => Number(block) !== Number(company.id));
    });
    await api.updateUser(blocked, userData);
    alert("제거 완료")
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoBlock);
