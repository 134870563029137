import React, { useState, useRef, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import momentLib from 'moment-timezone';
import { SortableTableHead } from '../../../../components';
import SearchInput from '../../../../components/search-input';
import ReactPaginate from 'react-paginate';
import { useEffect } from 'react';
import { getAdsAll } from '../../api/ads';

const header = [
  { sortable: true, id: 'adId', label: 'adId' },
  { sortable: true, id: 'companyId', label: 'companyId' },
  { sortable: true, id: 'platform', label: 'channel' },
  { sortable: false, id: 'productName', label: '제품명' },
  { sortable: false, id: 'enrolled', label: '선정 인원(실제/표기값)' },
  { sortable: false, id: 'applied', label: '신청 인원(실제/표기값)' },
  { sortable: false, id: 'maxCapacity', label: '최대 인원' },
  { sortable: false, id: 'adsType', label: '타입' },
  { sortable: true, id: 'createdAt', label: '등록일' },
];

const excludedHeaders = ['enrolled', 'applied', 'maxCapacity'];

const searchHeader = [...header].filter(
  (header) => !excludedHeaders.includes(header.id)
);

const ROWS_PER_PAGE = 20;

const initialState = {
  page: 1,
  rowsPerPage: ROWS_PER_PAGE,
  // orderBy: 'createdAt',
  direction: 'desc',
  companies: [],
  total: 0,
};

const Ads = (props) => {
  const searchOptions = useRef({
    selectItem: '',
    findText: '',
  });

  const [state, setState] = useState(initialState);

  const fetch = async (page) => {
    const result = await getAdsAll({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),
    });

    setState({
      ...state,
      companies: [...result.data.data.ads],
      page: page,
      total: result.data.data.totalRows,
    });
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleSearch = (selectItem, findText) => {
    searchOptions.current = {
      selectItem,
      findText,
    };

    fetch(1);
  };

  const body = state.companies.map((company, index) => {
    return (
      <TableRow key={index}>
        <TableCell>{company.ad.id}</TableCell>
        <TableCell>{company.ad.companyId}</TableCell>
        <TableCell>{company.ad.channel}</TableCell>
        <TableCell>{company.ad.title}</TableCell>
        <TableCell>
          {company.enrolled} / {company.modifiedEnrolled}
        </TableCell>
        <TableCell>
          {company.applied} / {company.modifiedApplied}
        </TableCell>
        <TableCell>{company.channel.maxCapacity}</TableCell>
        <TableCell>{company.ad.type}</TableCell>
        <TableCell>
          {momentLib(company.ad.createdAt)
            .tz('Asia/Seoul')
            .format('YY-MM-DD HH:mm')}
        </TableCell>

        <TableCell>
          <Link to={'/cosduck/ad?id=' + company.ad.id} target='_blank'>
            편집
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const pageCount =
    parseInt(state.total / state.rowsPerPage, 10) +
    (state.total % state.rowsPerPage === 0 ? 0 : 1);

  const handleChangePage = (event) => {
    fetch(event.selected + 1);
  };

  return (
    <div className='table-container'>
      <SearchInput header={searchHeader} handleSearch={handleSearch} />
      <Table>
        <SortableTableHead order={state.direction} columns={header} />
        <TableBody>{body}</TableBody>
      </Table>
      <div className='table-footer'>
        <div />
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={handleChangePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
