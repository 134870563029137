import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import { ContentsEditor } from '../../components';
import * as api from './api';

class Company3Manual extends React.Component {
  state = {
    id: this.props.location.query.id,
    name: '',
    manual: [],
    modalOpened: false,
    snackOpen: false,
    loaded: false,
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getCompany(this.state.id).then((result) => {
      this.setState({
        manual: result.companies[0].manual === undefined ? [] : result.companies[0].manual,
        name: result.companies[0].name,
        loaded: true,
      });
      this.props.deactivateLoading();
    });
  }
  handleSave = (json) => {
    this.props.activateLoading();
    api
      .updateCompany(this.state.id, { manual: json })
      .then(() => {
        this.setState({ manual: json, snackOpen: true });
        this.props.deactivateLoading();
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };
  handleCancel = () => {
    this.props.push('/notice?id=' + this.state.id);
  };
  render() {
    const { manual, name, loaded } = this.state;

    if (!loaded) {
      return null;
    }
    return (
      <div>
        <ContentsEditor
          title={name}
          initialValue={manual}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={4000}
          onClose={() => this.setState({ snackOpen: false })}
          message={<span>업데이트 성공</span>}
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={() => this.setState({ snackOpen: false })}
            >
              <i className='material-icons'>close</i>
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Company3Manual);
