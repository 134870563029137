import React, { useRef, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Edit } from '@material-ui/icons';
import Typo from './Typo';
import { Color } from '../constants/color';

const CustomSelect = (prop) => {
  const { showModifiedStatus = true, disabled, ...props } = prop;
  const originalValue = useRef(props.value || '');
  const [modified, setModified] = useState(false);

  const onChangeHandler = (event) => {
    if (originalValue.current === event.target.value) {
      setModified(false);
    } else {
      setModified(true);
    }
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div style={props.layoutStyle}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '6px',
          fontFamily: 'Pretendard-Regular',
        }}
      >
        <Typo typography='Detail_1' fontWeight='500' color={Color.label_type1}>
          {props.label}
        </Typo>

        {showModifiedStatus && modified && (
          <span
            style={{
              marginLeft: 10,
              fontSize: 9,
              fontFamily: 'Pretendard-SemiBold',
              color: '#8BC34A',
            }}
          >
            <span>수정됨</span>
            <Edit fontSize='inherit' />
          </span>
        )}
      </div>
      <Select
        fullWidth
        value={props.value}
        disabled={disabled}
        onChange={onChangeHandler}
      >
        {props.menus.map((menu) => (
          <MenuItem value={menu.value}>{menu.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default CustomSelect;
