import React from 'react';
import PropTypes from 'prop-types';

import {bindActionCreators} from 'redux'
import {connect} from "react-redux";
import ActionCreators from '../../app/action'

import {withStyles} from '@material-ui/core/styles';
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import VirtualizedSelect from 'react-virtualized-select';
// import _ from 'lodash';
// import {debounce} from 'lodash/debounce';

import {fetchData} from "../../app/api";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '30px',
    margin: '10px 0'
  },
  category: {
    position: 'relative',
    top: '-40%'
  },
  plus: {
    float: 'right',
    width: '30px',
    height: '100%',
    marginLeft: '4px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82,82,82,.12)'
  },
  search: {
    float: 'right',
    width: '36px',
    height: '100%',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82,82,82,.12)'
  },
  '@global': {
    '.Select': {
      display: 'inline-block',
      width: '30%',
      marginRight: '4px'
    },
    '.Select-control': {
      alignItems: 'center',
      height: 'auto',
      background: 'transparent',
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: 'absolute',
      left: 0
    },
  }
});

class Card extends React.Component {
  state = {
    single: null,
    multi: null,
    regions: [],
    combinations: [],
    regionSelected: {},
    combinationSelected: {},
    rank: 0
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      regions: newProps.regions,
      combinations: newProps.combinations,
    });
  }

  render() {
    const {classes, index, ranks, postUrl, handlePlus, handleMinus} = this.props;

    let buttons = (ranks.length > 1 && index < ranks.length - 1) ?
      <button className={classes.plus} onClick={() => {
        delete ranks[index];
        handleMinus(ranks);
      }}>
        -
      </button> :
      <button className={classes.plus} onClick={() => {
        ranks.push({category: '', rank: 0});
        handlePlus(ranks);
      }}>
        +
      </button>;

    return (
      <div className={classes.root}>
        <VirtualizedSelect
          autoFocus
          clearable={true}
          disabled={false}
          labelKey='name'
          multi={false}
          onChange={(regionSelected) => {
            this.setState({regionSelected: regionSelected});
            // if((this.state.combinationSelected || {}).level) {
            // }
          }}
          options={this.props.regions}
          searchable={true}
          value={(this.state.regionSelected || {})}
        />
        <VirtualizedSelect
          autoFocus
          clearable={true}
          disabled={false}
          labelKey='name'
          multi={false}
          onChange={(combinationSelected) => {
            this.setState({combinationSelected: combinationSelected})
            // if((this.state.regionSelected || {}).level) {
            // }
          }}
          options={this.props.combinations}
          searchable={true}
          value={(this.state.combinationSelected || {})}
        />
        <span className={classes.category}>
          {'[' + ((this.state.regionSelected || {}).level || '_') +
          ((this.state.combinationSelected || {}).level || '_') + '] => Rank: ' + this.state.rank}
        </span>
        {buttons}
        <button className={classes.search} onClick={() => {
          if ((this.state.regionSelected || {}).level && (this.state.combinationSelected || {}).level) {
            // _.debounce(() => {
              this.props.activateLoading();
              fetchData('get', '/keyword/search', {
                search: this.state.regionSelected.name + ' ' + this.state.combinationSelected.name,
                postUrl: encodeURIComponent(postUrl)
              }).then((json) => {
                this.props.deactivateLoading();
                this.setState({rank: json.rank});
              }).catch(err => {
                this.props.deactivateLoading();
                console.error(err);
                alert('검색 실패');
              });
            // }, 1000);
          }
        }}>
          검색
        </button>
      </div>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Card));