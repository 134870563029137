import * as api from '../api';

export async function getOptions(query) {
  const url = '/company/option?' + query;
  return await api.fetchData('get', url);
}

export function getCategory() {
  return api
    .fetchData('get', '/category')
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function createCategory(json) {
  return api
    .fetchData('post', '/category', json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function updateCategory(id, json) {
  return api
    .fetchData('put', '/category', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteCategory(id) {
  return api.fetchData('delete', '/category', { id: id }).then(result => {
    return Promise.resolve(result);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

function influencerProgress (ev) {
  console.log('influencerProgress', ev);
}

export function createInfluencer(json) {
  return api
    .fetchData('post', '/influencer/rank', null, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function refreshInfluencerRank(json) {
  return api
    .fetchData('get', '/influencer/rank/refresh')
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function updateUser(id, json) {
  return api
    .fetchData("put", "/user/console", { uid: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getUser(id) {
  return api
    .fetchData("get", "/user/console", { uid: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function setPostLevel(uid, token, level, preserved) {
  return api
    .fetchData("post", "/user/postlevel", null, { uid, token, level, preserved })
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}