import React, {Component} from 'react';
import {fetchData} from '../../app/api';
import Card from './card';
import './cards.css'

class PayCard extends Component {
  state = {
    payment: {},
    iamport: {},
    paids: []
  };

  componentDidMount() {
    const {company} = this.props;

    fetchData('get', '/pay/cardinfo', {id: company}).then((infos) => {
      this.setState({
        iamport: infos.cards[0].iamport,
        payment: infos.cards[0].payment
      });
    }).catch(err => {
      console.error(err);
      alert('카드정보 조회 실패');
    });

    fetchData('get', '/pay', {id: company}).then((paids) => {
      this.setState({paids: paids});
    }).catch(err => {
      console.error(err);
      alert('결제내역 조회 실패');
    });
  }

  render() {
    const {paids, iamport, payment} = this.state;

    let cards = paids.length > 0 ? paids.map(paid => {
      return (
        <Card key={paid.imp_uid} paid={paid} paids={paids}/>
      )
    }) : <Card/>;
    return (
      <div className="pay-cards">
        <div className="count">카드정보</div>
        <Card payment={payment}/>
        <div className="count">빌키</div>
        <Card iamport={iamport} payAgain={() => {
          fetchData('post', '/pay', null,
            {id: this.props.company, amount: this.props.amount, name: '슈퍼멤버스 정기결제'}).then(() => {
            alert('결제 성공');
          }).catch(err => {
            console.error(err);
            alert('결제 실패');
          });
        }}/>
        <div className="count">결제 내역</div>
        {cards}
      </div>
    )
  }
}

export default PayCard;