import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import _ from 'lodash';

import ImageEditor from '../../../components/image-editor';

import * as api from '../api';

class AdsBasic extends PureComponent {
  state = {
    locals:[],
  }
  render() {
    const { company } = this.props;

    if (company && Object.keys(company).length > 0) {
      if (company.category.firstName === '매장') {
        const { locals } = this.state;
        let regionItems = company.local ? <MenuItem value={company.local.region}>{company.local.region}</MenuItem> : '';
        let localItems = company.local ? <MenuItem value={company.local.code}>{company.local.code}</MenuItem> : '';

        if ( Object.keys(locals).length > 0 ) {
          regionItems = Object.keys(locals).map((item) => {
            return <MenuItem key={item} value={item}>{item}</MenuItem>;
          })
          localItems = _.isEmpty((company.local || {}).region) ? '' : Object.keys(locals[company.local.region]).map((key) => {
            return <MenuItem key={key} value={key}>{locals[company.local.region][key]}</MenuItem>;
          });
        }
        return (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>가맹점 기본정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div className='row'>
                  <Select
                    fullWidth
                    value={company.local ? company.local.region : ''}
                    onChange={(evt) => {
                      const localCode = Object.keys(locals[evt.target.value])[0];
                      this.props.handlePropChange('local', 'region', evt.target.value);
                      this.props.handlePropChange('local', 'code', localCode);
                      this.props.handlePropChange('local', 'local', locals[evt.target.value][localCode]);
                    }}
                  >
                    {regionItems}
                  </Select>
                  <Select
                    fullWidth
                    value={company.local ? company.local.code : ''}
                    onChange={(evt) => {
                      this.props.handlePropChange('local', 'code', evt.target.value);
                      this.props.handlePropChange('local', 'local', locals[company.local.region][evt.target.value]);
                    }}
                  >
                    {localItems}
                  </Select>
                </div>
                <label>매장 연락처</label>
                <TextField
                  fullWidth
                  value={company.contacts}
                  onChange={(evt) => this.props.handleChange('contacts', evt.target.value)}
                />
                <label>매장 영업시간</label>
                <TextField
                  fullWidth
                  value={company.time}
                  onChange={(evt) => this.props.handleChange('time', evt.target.value)}
                />
                <label>매장 주소</label>
                <TextField
                  fullWidth
                  value={company.address}
                  onChange={(evt) => this.props.handleChange('address', evt.target.value)}
                />
                <label>상세 주소</label>
                <TextField
                  fullWidth
                  value={company.addressDetail}
                  onChange={(evt) => this.props.handleChange('addressDetail', evt.target.value)}
                />
                <div className='row'>
                  <div>
                    <label>위도</label>
                    <TextField
                      style={{ width: 100 }}
                      value={company.lat}
                      onChange={(evt) => this.props.handleChange('lat', evt.target.value)}
                    />
                  </div>
                  <div>
                    <div>
                      <label>경도</label>
                      <TextField
                        style={{ width: 100 }}
                        value={company.lon}
                        onChange={(evt) => this.props.handleChange('lon', evt.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      }

      // 제품일 경우
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>가맹점 기본정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <label>제품 구매링크</label>
              <div className='label-description'>블로그 포스팅에 들어갈 제품 구매 링크를 입력해주세요.</div>
              <TextField
                fullWidth
                value={company.site}
                onChange={(evt) => this.props.handleChange('site', evt.target.value)}
              />
              <div>
                <ImageEditor 
                  label='제품 상세 이미지' 
                  images={company.images ? company.images : []}
                  main={null}
                  handleChange={(arr) => this.props.handleChange('images', arr)} />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const locals = await api.getOptions('type=local');
    this.setState({ locals })
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(AdsBasic);
