import React from 'react'
import { bindActionCreators } from 'redux'
import ActionCreators from '../action'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import {Editor, ContractCards, PayCard, KeywordShareCard} from '../../components'
import * as api from './api'

class Company extends React.Component {
  state = {
    id:this.props.location.query.id,
    schema:{},
    company:{},
    snackOpen:false
  }
  componentDidMount() {
    this.props.activateLoading()
    api.getCompany(this.state.id).then(result => {
      let schema = result.schema.properties;
      delete schema.id;
      delete schema.contractCount;
      delete schema.updated;
      delete schema.keywordCount;
      delete schema.category;
      let company = result.companies[0];
      let keys = Object.keys(schema);
      for(let i = 0 ; i < keys.length ; i++ ) {
        schema[keys[i]].name = keys[i];
        schema[keys[i]].value = (company[keys[i]]===undefined)?null:company[keys[i]];
      }
      this.setState({schema:schema, company:company})
      this.props.deactivateLoading()
    })
  }
  handleSave = (json) => {
    this.props.activateLoading();
    const {id} = this.state;
    const keys = Object.keys(json);
    let requestBody = {};
    for(let i = 0 ; i < keys.length ; i ++) {
      requestBody[keys[i]] = json[keys[i]].value;
    }
    api.updateCompany(id, requestBody).then(result => {
      this.setState({schema:json, snackOpen:true})
      this.props.deactivateLoading()
    }).catch(err => {
      this.props.deactivateLoading();
      if(err.response && err.response.data) {
        alert(err.response.data);
      }
      else {
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      }
    })
  }
  handleCancel = () => {
    this.props.push('/companies');
  }
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api.deleteCardInfo(this.state.id).then(result => {
        this.setState({snackOpen: true})
        this.props.deactivateLoading()
      }).catch(err => {
        console.log(err)
        this.props.deactivateLoading()
        alert('삭제에 실패했습니다. 다시 확인해주세요.');
      })
    }
  }
  render() {
    const {schema, company} = this.state;
    if(Object.keys(schema).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container">
            <Editor
              schema={schema}
              handleSave={this.handleSave}
              handleCancel={this.handleCancel}
              handleDelete={this.handleDelete}
              handleDeleteText={'카드정보 삭제'}
            />
            <PayCard company={company.id} amount={company.amount}/>
            <KeywordShareCard company={company.id} category={company.category}/>
          </div>
          <div className="additional-container">
            <ContractCards company={company.id}/>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={()=>this.setState({snackOpen:false})}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={()=>this.setState({snackOpen:false})}
              >
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      )
    } else {
      return <div></div>
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(null, mapDispatchToProps) (Company)