import React, { PureComponent } from 'react';
import momentLib from 'moment-timezone'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import _ from 'lodash';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCard from '@material-ui/icons/CreditCard';
import Pageview from '@material-ui/icons/Pageview';
import IconButton from "@material-ui/core/IconButton/IconButton";

class InfoNotice extends PureComponent {
  state = {
    payList: []
  };

  render() {
    const { companyUser } = this.props;
    const { payList} = this.state;

    const items = payList.map((pay, idx) => {
      return (
        <div key={idx} style={{ display: 'flex', justifyContent: 'space-between', padding: 12, borderBottom: '1px solid #dbdbdb' }}>
          <div>
            <span>{pay.name}</span>
          </div>
          <div>
            <span style={pay.channel.indexOf('naver') > -1 ? {color: '#1bb200'} : {color: '#ef0b50'}}>{pay.channel}</span>
          </div>
          <div>
            <span>{pay.type}</span>
          </div>
          <div>
            <span>{pay.verified}</span>
          </div>
          <div>
            <span>{pay.state}</span>
          </div>
          <div>
            <span>{pay.dday}</span>
          </div>
          <div>
            <IconButton onClick={() => window.open(pay.paylink)} style={{padding: 0}}>
              <CreditCard />
            </IconButton>
          </div>
          <div>
            <IconButton onClick={() => window.open(pay.companylink)} style={{padding: 0}}>
              <Pageview />
            </IconButton>
          </div>
        </div>
      );
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name} 광고목록`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            {items}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {
  }
  async componentWillReceiveProps(nextProps) {
    this.state.payList = [];
    this.props.companies.forEach((company) => {
      let channels = ['naver', 'insta'];
      channels.forEach((channel) => {
        if(company[channel]) {
          let state = '결제 예정';
          let paycard = {};
          paycard.adsStart = company[channel].startDate || company[channel].payInfo_nextPaidAt;
          let payIdx = -1;
          if(!_.isEmpty(company[channel + 'PayCards'])) {
            payIdx = _.map(company[channel + 'PayCards'], 'payState').indexOf(2);
            if(payIdx === -1) {
              payIdx = company[channel + 'PayCards'].length - 1;
            }
            if(payIdx !== -1) {
              paycard = company[channel + 'PayCards'][payIdx];

              if (paycard.payState === 0) {
                state = '결제 예정';
              }
              else if (paycard.payState === 1) {
                state = '결제 완료';
              }
              else if (paycard.payState === 2) {
                state = '결제 요청';
              }
              else if (paycard.payState === 3) {
                state = '결제 중지';
              }
              else {
                state = '중지 후 재시작';
              }
            }
          }

          let date = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');
          let dday = momentLib(date).diff(momentLib(paycard.adsStart), 'day');
          this.state.payList.push({
            name: company.name,
            channel: channel,
            type: company[channel].type === 'N' ? '제품-실속형' : (company[channel].type === 'S' ? '제품-표준형' : '매장'),
            verified: company.verified ? '진행' : '중지',
            state: state,
            dday: dday > 0 ? ('+' + dday): dday,
            paylink: 'paycard?id=' + company.id + '&channel=' + (paycard.channel || 'naver'),
            companylink: '/company3?id=' + company.id
          });
        }
      });
    })
  }
}

export default InfoNotice;
