import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import FormatColorFill from '@material-ui/icons/FormatColorFill';

import ColorSelector from './color-selector';

class BackgroundForm extends Component {
  static propTypes = {
    initialStyle: PropTypes.object,
  };
  static defaultProps = {
    initialStyle: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      backgroundColor: 'transparent',
    },
  };
  state = {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    backgroundColor: 'transparent',
    ...this.props.initialStyle,
  };
  componentDidMount() {
    this.props.handleChange({
      backgroundStyle: { ...this.state }
    });
  }
  handleChange = (name, value) => {
    this.setState({ [name]: name === 'backgroundColor' ? value : Number(value) });
    this.props.handleChange({
      backgroundStyle: { ...this.state, [name]: name === 'backgroundColor' ? value : Number(value)}
    });
  };
  render() {
    const { paddingTop, paddingLeft, paddingRight, paddingBottom, backgroundColor } = this.state;
    return (
      <div>
        <label>백그라운드 컬러</label>
        <ColorSelector label={<FormatColorFill />} initialColor={backgroundColor} handleChange={(colorCode) => this.handleChange('backgroundColor', colorCode)} />
        <div className="row">
          <div>
            <label>왼쪽 여백</label>
            <TextField
              type='number'
              style={{width: 60}}
              value={paddingLeft}
              onChange={(evt) => this.handleChange('paddingLeft', evt.target.value)}
              margin='normal'
            />
          </div>
          <div>
            <label>윗쪽 여백</label>
            <TextField
              type='number'
              style={{width: 60}}
              value={paddingTop}
              onChange={(evt) => this.handleChange('paddingTop', evt.target.value)}
              margin='normal'
            />
          </div>
          <div>
            <label>오른쪽 여백</label>
            <TextField
              type='number'
              style={{width: 60}}
              value={paddingRight}
              onChange={(evt) => this.handleChange('paddingRight', evt.target.value)}
              margin='normal'
            />
          </div>
          <div>
            <label>아래쪽 여백</label>
            <TextField
              type='number'
              style={{width: 60}}
              value={paddingBottom}
              onChange={(evt) => this.handleChange('paddingBottom', evt.target.value)}
              margin='normal'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BackgroundForm;
