import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import InquiryQnALists from '../inquiry/components/InquiryQnALists';

import {
  Editor,
  UserCard,
  ContractCards,
  InstaHistoryCard,
  NaverHistoryCard,
  DestinationCard,
  PostLevelCard,
  PenaltyHistoryCard,
  InfoLog,
  InfoMessage,
} from '../../components';
import * as api from './api';
import { fetchData } from '../api';
import { getInquiries } from '../../services/supermembers/inquiry';
import { ROWS_PER_PAGE } from '../../constants';

class User extends React.Component {
  state = {
    uid: this.props.location.query.uid,
    schema: {},
    user: {},
    inquiry: [],
    snackOpen: false,
  };
  render() {
    const { schema, user, inquiry } = this.state;
    if (Object.keys(schema).length > 0) {
      return (
        <div className='contents'>
          <div className='editor-container'>
            <Editor
              schema={schema}
              handleSave={this.handleSave}
              handleCancel={this.handleCancel}
              handleDelete={this.handleDelete}
              handleDeleteText={'회원 탈퇴'}
              handleAddToChart={this.handleAddToChart}
            />
            <DestinationCard uid={user.uid} />
            <div className='contents-editor'>
              <div>
                <PenaltyHistoryCard uid={user.uid} />
              </div>
            </div>
            <InfoMessage type={'users'} phone={user.phone} />

            <InfoLog type={'users'} id={user.uid} />
            {inquiry.length > 0 && <InquiryQnALists inquiries={inquiry} />}
          </div>
          <div className='additional-container'>
            <UserCard user={user} />
            <PostLevelCard
              user={user}
              activateLoading={this.props.activateLoading}
              deactivateLoading={this.props.deactivateLoading}
              openSnack={() => this.setState({ snackOpen: true })}
              setUserState={this.setUserState}
            />
            <div className='contents-editor'>
              {user.type === 'insta' ? (
                <InstaHistoryCard user={user} />
              ) : user.email ? (
                <div>
                  <NaverHistoryCard uid={user.uid} />
                  {/*<KeywordCard uid={user.uid} email={user.email} />*/}
                </div>
              ) : (
                ''
              )}
            </div>
            <ContractCards user={user} />
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => this.setState({ snackOpen: false })}
              >
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }

  setUserState = async () => {
    try {
      this.props.activateLoading();
      const schemaResult = await api.getSchema(this.state.uid);
      console.log('@@@ schemaResult', schemaResult);
      const schema = schemaResult.properties;
      delete schema.instaHistory;
      const data = await fetchData('get', '/user', { uid: this.state.uid });
      if (!data.total) {
        alert('정보를 불러오던 중 오류가 발생했습니다. uid를 확인해주세요.');
        return;
      }
      const user = data.users[0];
      const keys = Object.keys(schema);
      keys.forEach((key) => {
        schema[key].name = key;
        if (key === 'postQuality') {
          schema[key].value = user[key] || 0;
        } else {
          schema[key].value = user[key];
        }
      });

      const inquiryData = await getInquiries({
        uid: user.uid,
        page: 1,
        limit: ROWS_PER_PAGE,
      });

      if (inquiryData.inquiries.length > 0) {
        this.setState({ schema, user, inquiry: inquiryData.inquiries });
      } else {
        this.setState({ schema, user });
      }
    } catch (err) {
      console.error(err);
      alert('데이터를 불러오던 중 오류가 발생했습니다.');
    } finally {
      this.props.deactivateLoading();
    }
  };

  componentDidMount() {
    this.setUserState();
  }
  componentWillUnmount() {
    this.state.remover && this.state.remover();
  }
  handleSave = (json) => {
    this.props.activateLoading();
    const { uid } = this.state;
    const keys = Object.keys(json);
    let requestBody = {};
    for (let i = 0; i < keys.length; i++) {
      requestBody[keys[i]] = json[keys[i]].value;
    }
    api
      .updateUser(uid, requestBody)
      .then((result) => {
        this.setUserState();
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      })
      .finally(() => {
        this.props.deactivateLoading();
      });
  };
  handleCancel = () => {
    this.props.push('/users');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteUser(this.state.uid)
        .then((result) => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
        })
        .catch((err) => {
          console.log(err);
          this.props.deactivateLoading();
          alert('회원 탈퇴에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleAddToChart = () => {
    this.props.activateLoading();
    const { uid } = this.state;
    api
      .updateChartAdd(uid)
      .then((result) => {
        alert('차트추가에 추가되었습니다.');
        this.props.deactivateLoading();
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('차트추가에 실패했습니다. 다시 확인해주세요.');
      });
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(User);
