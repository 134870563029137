import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux'

import auth from './auth/reducer';
import app from './app/reducer';

const reducer = combineReducers({
    routing: routerReducer,
    auth,
    app
});

export default reducer;