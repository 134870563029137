import * as api from '../api';

export function getSchema() {
    return api.fetchData('get', '/proposition/schema')
    .then((datas) => {
        return Promise.resolve(datas)
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function filterPropositions(query) {
    return api.fetchData('get', '/proposition/console', query)
    .then((datas) => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function getProposition(id) {
    return api.fetchData('get','/proposition/detail/console', {id:id})
    .then(datas => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export function updateProposition(id, json) {
    return api.fetchData('put', '/proposition/console', {id:id}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}
export function deleteProposition(id) {
    return api.fetchData('delete', '/proposition/console', {id:id})
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}

export function filterManuscripts(query) {
    return api.fetchData('get', '/proposition/manuscript', query)
      .then((datas) => {
          return Promise.resolve(datas);
      }).catch((err) => {
          return Promise.reject(err)
      })
}

export function updateManuscript(id, json) {
    return api.fetchData('put', '/proposition/manuscript', {id:id}, json)
      .then(result => {
          return Promise.resolve(result);
      })
      .catch((err) => {
          return Promise.reject(err)
      })
}

export async function getOptions(query) {
    const url = '/company/option?' + query;
    return await api.fetchData('get', url);
}

export async function createPurchaseLink(id) {
    return await api.fetchData('put', '/proposition/purchaseLink', {id:id});
}

export function getLog(id) {
    let query = {
        bool: {
            must: [
                {
                    terms: {
                        baseUrl: ['proposition']
                    }
                },
                {
                    multi_match: {
                        query: id,
                        fields: ["mergeParams.propositionId", "mergeParams.id"]
                    }
                }
            ]
        }
    }
    let params = new URLSearchParams({
        query: JSON.stringify(query).replace(/\\n/g, " ")
    });
    return api.fetchData("get", "/log/auto?" + params.toString())
      .then(result => {
          return Promise.resolve(result);
      })
      .catch(err => {
          return Promise.reject(err);
      });
}