import ReviewingRule from "./reviewing";
import PendingPaymentRule from "./pending-payment";

export const validateField = (schema, fieldName, value) => {
  const { error } = schema.validate({ [fieldName]: value });
  return !error;
};

export const ruleSets = {
  REVIEWING: Object.keys(ReviewingRule.name).reduce((acc, fieldName) => {
    acc[fieldName] = null;
    return acc;
  }, {}),
  PENDING_PAYMENT: Object.keys(PendingPaymentRule.name).reduce(
    (acc, fieldName) => {
      acc[fieldName] = null;
      return acc;
    },
    {}
  ),
  PENDING_ACTIVE: {},
  ACTIVE: {},
  PENDING_TERMINATION: {},
  TERMINATED: {},
};
export { ReviewingRule, PendingPaymentRule };
