import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../../action';
import { connect } from 'react-redux';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ImageEditor } from '../../../../../components';
import ModifiableList from '../../../components/ModifiableList';

const AdsEtc = (props) => {
  const { adsWithDetails, setAdsWithDetails } = props;

  const InfoDetails = (
    <>
      <div style={{ gap: 16 }}>
        <div className='row'>
          <label
            style={{
              marginTop: '20px',
              marginBottom: '6px',
              display: 'block',
              fontSize: '12px',
              color: '#4770bd',
            }}
          >
            광고 이미지
          </label>
          <ImageEditor
            images={
              adsWithDetails.ad.thumbnails ? adsWithDetails.ad.thumbnails : []
            }
            main={adsWithDetails.ad.thumbnail}
            handleChange={(arr) => {
              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  thumbnails: arr,
                },
              });
            }}
            handleMainChange={(url) => {
              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  thumbnail: url,
                },
              });
            }}
          />
        </div>
        <div className='row'>
          <label
            style={{
              marginTop: '20px',
              marginBottom: '6px',
              display: 'block',
              fontSize: '12px',
              color: '#4770bd',
            }}
          >
            제품 상세 이미지
          </label>
          <ImageEditor
            images={
              adsWithDetails.ad.contentImages
                ? adsWithDetails.ad.contentImages
                : []
            }
            handleChange={(arr) => {
              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  contentImages: arr,
                },
              });
            }}
          />
        </div>
        <div>
          <ModifiableList
            layoutStyle={{ gap: 16 }}
            list={adsWithDetails.ad.questions}
            label={'questions'}
            multiline={true}
            rows={3}
            onAddHandler={(newQuestion) => {
              const clone = [...adsWithDetails.ad.questions];
              clone.push(newQuestion);

              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  questions: clone,
                },
              });
            }}
            onRemoveHandler={(removeIndex) => {
              const clone = [...adsWithDetails.ad.questions].filter(
                (_, index) => index !== removeIndex
              );

              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  questions: clone,
                },
              });
            }}
          />
        </div>
      </div>
    </>
  );
  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>기타 상세정보</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        {InfoDetails}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsEtc);
