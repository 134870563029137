import React, { PureComponent } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import _ from 'lodash';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Receipt from '@material-ui/icons/Receipt';
import IconButton from "@material-ui/core/IconButton/IconButton";
import momentLib from "moment-timezone";
import {Link} from "react-router";

class InfoUserPay extends PureComponent {
  render() {
    const { companyUser, settlements, companies, accounts } = this.props;

    const items = settlements.map((settlement, idx) => {
      if (accounts.length > 0) {
        let company = companies.filter((company) => company.id == settlement.cid)[0];
        return (
          <div key={settlement.id} style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 12,
            borderBottom: '1px solid #dbdbdb'
          }}>
            <div>
              {/*<span>{}차</span>*/}
            </div>
            <div>
              <span>{company ? company.name : ''}</span>
            </div>
            <div>
              <span>{accounts[0].name + '|' + accounts[0].bankName}</span>
            </div>
            <div>
              <span>{settlement.price}</span>
            </div>
            <div>
              <span>{settlement.status}</span>
            </div>
            <div>
              <span>{momentLib(settlement.paidAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</span>
            </div>
            <div>
              <Link to={`/userpay?id=${settlement.id}`} target="_blank">
                <IconButton style={{padding: 0}}>
                  <Receipt/>
                </IconButton>
              </Link>
            </div>
          </div>
        );
      }
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name}님의 정산내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            {items}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {
  }
  async componentWillReceiveProps(nextProps) {
  }
}

export default InfoUserPay;
