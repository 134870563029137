import React, { useState } from 'react';
import AdsCarousel from '../../AdsCarousel';
import { Button, Tooltip } from '@material-ui/core';
import { addAdsGroup, mergeAdsGroup } from '../../../api/ads';

import NumberStepper from '../../../components/NumberStepper';
import Typo from '../../../../../components/Typo';

const AddMergeAdsGroup = ({
  adsGroups,
  adsGroupIds,
  selectedAdIds,
  setIsModifying,
  setModifyGroupIndex,
}) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [availableCount, setAvailableCount] = useState(1);

  const handleMergeAdsGroup = async () => {
    const result = await mergeAdsGroup(selectedGroups);
    if (result.data.success) {
      alert('그룹이 병합되었습니다.');
      window.location.reload();
    }
  };

  const handleAddAdsGroup = async () => {
    const result = await addAdsGroup(selectedAdIds, availableCount);
    if (result.data.success) {
      alert('그룹이 추가되었습니다.');
      window.location.reload();
    }
  };

  const handleGroupSelect = (groupId) => {
    if (selectedGroups.includes(groupId)) {
      setSelectedGroups(selectedGroups.filter((id) => id !== groupId));
    } else {
      setSelectedGroups([...selectedGroups, groupId]);
    }
  };

  const isGroupSelected = (groupId) => {
    return selectedGroups.includes(groupId);
  };

  return (
    <>
      {[...adsGroups].map((adGroup, index) => {
        return (
          <div style={styles.carouselContainer}>
            <AdsCarousel ads={adGroup} />
            <div style={styles.buttonContainer}>
              <Button
                style={
                  isGroupSelected(adsGroupIds[index])
                    ? styles.selectedButton
                    : styles.notSelectedButton
                }
                onClick={() => handleGroupSelect(adsGroupIds[index])}
              >
                선택
              </Button>
              <Button
                style={styles.notSelectedButton}
                onClick={() => {
                  setIsModifying((isModifying) => !isModifying);
                  setModifyGroupIndex(index);
                }}
              >
                그룹 편집
              </Button>
            </div>
          </div>
        );
      })}

      <div
        style={{
          ...styles.buttonContainer,
          padding: 8,
          ...(selectedAdIds.length === 0
            ? { color: '#f2f2f2', fontSize: 12 }
            : { color: '#000000', fontSize: 12 }),
        }}
      >
        <Tooltip title='두 개 이상의 그룹을 선택해주세요.' arrow>
          <Button
            disabled={selectedGroups.length < 2}
            onClick={handleMergeAdsGroup}
            style={{ height: '40px' }}
          >
            그룹 병합
          </Button>
        </Tooltip>
        <Tooltip title='그룹에 추가할 광고를 선택해주세요.' arrow>
          <span>
            <Button
              disabled={selectedAdIds.length === 0}
              onClick={handleAddAdsGroup}
              style={{ height: '40px' }}
            >
              새 그룹 만들기
            </Button>
          </span>
        </Tooltip>

        <Typo>이용 제한 횟수</Typo>
        <NumberStepper
          disabled={selectedAdIds.length === 0}
          value={availableCount}
          setValue={setAvailableCount}
          min={0}
          max={100}
        />
      </div>
    </>
  );
};

const styles = {
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 8,
  },
  carouselContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 8,
  },
  selectedButton: {
    borderRadius: 16,
    backgroundColor: '#1976d2',
    color: '#fff',
  },

  notSelectedButton: {
    borderRadius: 16,
    border: '1px solid #e0e0e0',
    color: '#757575',
  },
};

export default AddMergeAdsGroup;
