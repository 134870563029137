import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import EditorBasic from './editor-basic';
import EditorPoint from './editor-point';
import EditorWithdrawal from './editor-withdrawal';
import EditorAuth from './editor-auth';
import * as api from '../api';
import {UserCard} from "../../../components";

class UserPoint extends React.Component {
  state = {
    id: this.props.location.query.id,
    proposition: {},
    snackOpen: false,
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getUserPointDetail(this.state.id).then((result) => {
      this.setState({ proposition: result.propositions[0] });
    });
    this.props.deactivateLoading();
  }
  handleChange = (name, value) => {
    const proposition = { ...this.state.proposition };
    proposition[name] = value;
    this.setState({ proposition });
  };
  handleCancel = () => {
    this.props.push('/user-points');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteProposition(this.state.id)
        .then((result) => {
          alert('삭제 완료');
          this.props.push('/user-points');
        })
        .catch((err) => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api.updateProposition(id, this.state.proposition)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };
  render() {
    const { proposition } = this.state;
    if (Object.keys(proposition).length > 0) {
      return (
        <div className='contents'>
          <div className='editor-container contents-editor'>
            <EditorBasic proposition={proposition} handleChange={this.handleChange} />
            <EditorPoint proposition={proposition} handleChange={this.handleChange} />
          </div>
          <div className='additional-container'>
            <UserCard user={proposition.User}/>
            <div className='contents-editor'>
              <EditorWithdrawal proposition={proposition} handleChange={this.handleChange} handleSave={this.handleSave}/>
              <EditorAuth proposition={proposition} handleChange={this.handleChange} />
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => this.setState({ snackOpen: false })}
              >
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(UserPoint);
