import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import _ from 'lodash';

const MenuItem = ({menu, idx, handleChange, handleDelete}) => {
  return (
    <div className='row'>
      <TextField
        fullWidth
        value={menu.name}
        onChange={(evt) => handleChange('menu', idx, { ...menu, name:evt.target.value })}
      />
      <TextField
        value={menu.price}
        onChange={(evt) => handleChange('menu', idx, { ...menu, price:evt.target.value })}
      />
      <IconButton onClick={() => handleDelete(idx)}><Remove  /></IconButton>
    </div>
  )
}

class AdsMenu extends Component {
  state = {
    newMenuName: '',
    newMenuPrice: '',
  }
  render() {
    const { menu, handlePropChange } = this.props;
    const { newMenuName, newMenuPrice } = this.state;
    const menus = _.isEmpty(menu) ? '' : menu.map((item, idx) => {
      return <MenuItem menu={item} key={idx} idx={idx} handleChange={handlePropChange} handleDelete={this.handleDelete}/>
    })
    return (
      <section>
        <label>품목명 / 가격리스트</label>
        {menus}
        <div className='row'>
      <TextField
        fullWidth
        value={newMenuName}
        onChange={(evt) => this.setState({newMenuName: evt.target.value})}
      />
      <TextField
        value={newMenuPrice}
        onChange={(evt) =>  this.setState({newMenuPrice: evt.target.value})}
      />
      <IconButton onClick={() => this.handleAdd()}><Add  /></IconButton>
    </div>
      </section>
    )
  }
  handleAdd = () => {
    const { menu, handleChange } = this.props;
    const { newMenuName, newMenuPrice } = this.state;

    if ( newMenuName.length === 0 || newMenuPrice.length === 0) {
      return;
    }
    const newMenu = [ ...menu ];
    newMenu.push({name: newMenuName, price: newMenuPrice });
    this.setState({ newMenuName: '', newMenuPrice: '' });
    
    handleChange('menu', newMenu);
  }
  handleDelete = (idx) => {
    const { menu, handleChange } = this.props;

    const newMenu = [ ...menu ];
    newMenu.splice(idx, 1);
    
    handleChange('menu', newMenu);
  }
}

export default AdsMenu;