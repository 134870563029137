import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import Preview from './preview';
import FormDialog from './form-dialog';

import './contents-editor.css';

class ContentsEditor extends Component {
  state = {
    value: this.props.initialValue ? this.props.initialValue : [],
    dialogOpened: false,
    currentIdx: null,
  };
  handleUp = (idx) => {
    if (idx > 0 ) {
      const value = [ ...this.state.value ];
      const prev = value[idx - 1];
      value[idx - 1] = value[idx];
      value[idx] = prev;
      this.setState({ value });
    }
  }
  handleDown = (idx) => {
    if (idx < this.state.value.length - 1 ) {
      const value = [ ...this.state.value ];
      const next = value[idx + 1];
      value[idx + 1] = value[idx];
      value[idx] = next;
      this.setState({ value });
    }
  }
  handleDelete = (idx) => {
    const value = [ ...this.state.value ];
    value.splice(idx, 1);
    this.setState({ value });
  }
  handleOpen = (idx) => {
    this.setState({ dialogOpened: true, currentIdx: idx });
  };
  handleSave = (idx) => (newValue) => {
    const value = [ ...this.state.value ];
    value[idx] = newValue;
    this.setState({ value, dialogOpened: false });
  };
  render() {
    const { value, dialogOpened, currentIdx } = this.state;
    const { title } = this.props;

    const valueList = value.map((item, idx) => {
      let label = item.label ? item.label : item.text;
      if (label === undefined) {
        const url = decodeURIComponent(item.url.split('/')[item.url.split('/').length - 1]);
        label = url.split('?')[0];
      }
      return (
        <TableRow key={idx}>
          <TableCell>
            <h4
              style={{ textAlign: 'left' }}
              className={item.type === 'image' ? 'red' : item.type === 'button' ? 'blue' : ''}
            >
              {item.type === 'image' ? '이미지' : item.type === 'button' ? '버튼' : '텍스트'}
            </h4>
          </TableCell>
          <TableCell>
            <pre style={{ textAlign: 'left' }}>{label}</pre>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleUp(idx)}>
              <ExpandLess />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleDown(idx)}>
              <ExpandMore />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleOpen(idx)}>
              <Edit />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleDelete(idx)}>
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
    let buttons = [];
    if (this.props.handleCancel) {
      let buttonText = this.props.handleCancelText ? this.props.handleCancelText : '취소';
      buttons.push(
        <Button
          key={'bottomButton1'}
          variant='contained'
          color='primary'
          onClick={() => this.props.handleCancel()}
        >
          {buttonText}
        </Button>
      );
    }
    if (this.props.handleSave) {
      let buttonText = this.props.handleSaveText ? this.props.handleSaveText : '저장';
      buttons.push(
        <Button
          key={'bottomButton2'}
          style={{ marginLeft: 12 }}
          variant='contained'
          color='secondary'
          onClick={() => this.props.handleSave(this.state.value)}
        >
          {buttonText}
        </Button>
      );
    }
    return (
      <div>
        <div className='contents'>
          <div className='editor-container large'>
            <div className='editor'>
              <div style={{ fontSize: 18, marginBottom: 20 }}>
                <span style={{ fontWeight: 700 }}>{title}</span>
                &nbsp;편집하기
              </div>
              <div>
                등록 컨텐츠 <span>{value.length}</span>
              </div>
              <div className='table-container'>
                <Table style={{minWidth: 600}}>
                  <TableBody>{valueList}</TableBody>
                </Table>
              </div>
              <div style={{ marginTop: 20, textAlign: 'right' }}>
                <Button variant='contained' color='primary' onClick={() => this.handleOpen(null)}>
                  추가
                </Button>
              </div>
              <div className='row button' style={{ marginTop: 180 }}>
                {buttons}
              </div>
            </div>
          </div>
          <div className='additional-container small'>
            <div style={{ fontSize: 18 }}>내용 미리보기</div>
            <Preview contents={value} />
          </div>
        </div>
        <FormDialog
          key={'dialog'+currentIdx}
          open={dialogOpened}
          close={() => this.setState({dialogOpened: false})}
          initialValue={currentIdx === null ? {} : value[currentIdx]}
          handleSave={this.handleSave(currentIdx === null ? valueList.length : currentIdx)}
        />
      </div>
    );
  }
}

ContentsEditor.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  handleSave: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default ContentsEditor;
