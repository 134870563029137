import React from "react";
import { styled } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Receipt from "@material-ui/icons/Receipt";
import momentLib from "moment-timezone";

const ReceiptPaper = styled(Paper)({
  padding: "30px",
  backgroundColor: "#fff",
  color: "#333",
  fontFamily: "'Courier New', Courier, monospace",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
    height: "6px",
    background:
      "repeating-linear-gradient(90deg, #ddd 0, #ddd 6px, transparent 6px, transparent 12px)",
  },
});

const ReceiptRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 0",
  borderBottom: "1px dashed #ddd",
  "&:last-child": {
    borderBottom: "none",
  },
});

const ReceiptLabel = styled("span")({
  color: "#666",
  fontWeight: "normal",
});

const ReceiptValue = styled("span")({
  fontWeight: "bold",
});

const PaymentReceipt = ({ paymentData }) => {
  if (!paymentData.paidAt) {
    return (
      <Paper
        style={{
          padding: "20px",
          textAlign: "center",
          color: "#666",
          backgroundColor: "#f5f5f5",
        }}
      >
        결제내역이 없습니다.
      </Paper>
    );
  }

  return (
    <ReceiptPaper elevation={2}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "20px",
          borderBottom: "2px solid #333",
          paddingBottom: "10px",
        }}
      ></div>

      <ReceiptRow>
        <ReceiptLabel>결제일시:</ReceiptLabel>
        <ReceiptValue>
          {momentLib(paymentData.paidAt)
            .tz("Asia/Seoul")
            .format("YYYY-MM-DD HH:mm:ss")}
        </ReceiptValue>
      </ReceiptRow>

      <ReceiptRow>
        <ReceiptLabel>결제번호:</ReceiptLabel>
        <ReceiptValue>{paymentData.imp_uid || "-"}</ReceiptValue>
      </ReceiptRow>

      <ReceiptRow>
        <ReceiptLabel>결제수단:</ReceiptLabel>
        <ReceiptValue>{paymentData.payMethod || "-"}</ReceiptValue>
      </ReceiptRow>

      <ReceiptRow>
        <ReceiptLabel>카드사:</ReceiptLabel>
        <ReceiptValue>{paymentData.card_name || "-"}</ReceiptValue>
      </ReceiptRow>

      {paymentData.receipt ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="outlined"
            color="default"
            href={paymentData.receipt}
            target="_blank"
            startIcon={<Receipt />}
          >
            영수증 상세보기
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<Receipt />}
            disabled
          >
            영수증 상세보기
          </Button>
        </div>
      )}
    </ReceiptPaper>
  );
};

export default PaymentReceipt;
