import React, { useEffect, useState } from 'react';
import { CheckCircle, CheckCircleOutline, Edit } from '@material-ui/icons';
import AdInformation from '../AdInformation';
import AdsCarousel from '../AdsCarousel';
import { Button, Checkbox } from '@material-ui/core';
import useAdsGroups from '../../hooks/useAdsGroups';
import ModifyAdsGroup from './components/ModifyAdsGroup';
import AddMergeAdsGroup from './components/AddMergeAdsGroup';
import CustomModal from '../../../../components/CustomModal';

const AdsGroupEditor = (props) => {
  const { adId, companyId } = props;

  const [isModifying, setIsModifying] = useState(false);
  const [modifyGroupIndex, setModifyGroupIndex] = useState(0);
  const [selectedAdIds, setSelectedAdIds] = useState([]);
  const { adsGroups, adsGroupIds, notGroupedAds } = useAdsGroups(companyId);

  useEffect(() => {
    setSelectedAdIds([]);
  }, [isModifying]);

  const handleCheckboxChange = (adId) => {
    if (selectedAdIds.includes(adId)) {
      setSelectedAdIds(selectedAdIds.filter((id) => id !== adId));
    } else {
      setSelectedAdIds([...selectedAdIds, adId]);
    }
  };

  return (
    <CustomModal
      title=''
      content={
        <div style={styles.container}>
          {isModifying ? (
            <ModifyAdsGroup
              ads={[...adsGroups[modifyGroupIndex]]}
              groupId={adsGroupIds[modifyGroupIndex]}
              selectedAdIds={selectedAdIds}
              isModifying={isModifying}
              setIsModifying={setIsModifying}
            />
          ) : (
            <AddMergeAdsGroup
              adsGroups={adsGroups}
              adsGroupIds={adsGroupIds}
              selectedAdIds={selectedAdIds}
              setIsModifying={setIsModifying}
              setModifyGroupIndex={setModifyGroupIndex}
            />
          )}

          <div style={styles.notGroupContainer}>
            {notGroupedAds.length > 0 && (
              <>
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#eee',
                  }}
                />
                {notGroupedAds.map((ad, index) => {
                  return (
                    <AdInformation
                      ad={ad}
                      size='sm'
                      icon={
                        <Checkbox
                          size='small'
                          color='primary'
                          checked={selectedAdIds.includes(ad.adId)}
                          onChange={() => handleCheckboxChange(ad.adId)}
                          icon={
                            <CheckCircleOutline style={{ color: '#9f9f9f' }} />
                          }
                          checkedIcon={
                            <CheckCircle style={{ color: 'green' }} />
                          }
                        />
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      }
      buttonIcon={<Edit />}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    ></CustomModal>
  );
};

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    overflowY: 'scroll',
    padding: 8,
  },
  notGroupContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  carouselContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 8,
  },
};

export default AdsGroupEditor;
