import React, { Component } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { fetchData } from "../../app/api";
import Card from "./card";
import "./cards.css";
import _ from "lodash";

class ContractCards extends Component {
  state = {
    contracts: [],
    companies: [],
    total: 0,
    filter: 0
  };
  componentDidMount() {
    const { company, user } = this.props;
    const query = {
      orderBy: "date0",
      direction: "desc"
    };
    let forWho = null;
    if (company !== undefined) {
      query.company = company;
      forWho = "company";
    }
    if (user !== undefined) {
      query.user = user.uid;
      forWho = "user";
    }

    fetchData("get", "/contract/console", query)
      .then(datas => {
        if (datas.total > 0 && datas.contracts[0].appliedAt) {
          datas.contracts = _.orderBy(datas.contracts, ['appliedAt'], ['desc']);
        }
        this.setState({ ...datas, forWho });
      })
      .catch(err => {
        console.error(err);
        alert("이용내역 조회 실패");
      });
  }
  render() {
    const { companyName } = this.props;
    const { contracts, total, forWho, companies, filter } = this.state;
    let naverCnt = 0,
      instaCnt = 0;
    let cards = contracts.map(contract => {
      let type = contract.type || "naver";
      if (
        (filter === 1 && type === "naver") ||
        (filter === 2 && type === "insta") ||
        filter === 0
      ) {
        if (type === "naver") {
          naverCnt++;
        } else if (type === "insta") {
          instaCnt++;
        }
        return (
          <Card
            key={contract.id || contract.rKey}
            contract={contract}
            forWho={forWho}
            companies={companies}
            user={this.props.user}
          />
        );
      } else {
        return null;
      }
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>
            {companyName === undefined
              ? "유저 이용내역"
              : `${companyName}   이용내역`}
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="contract-cards">
            <div>
              <Tabs value={filter} onChange={this.handleFilterChange}>
                <Tab label={"전체(" + total + ")"} />
                <Tab label={"네이버(" + naverCnt + ")"} />
                <Tab label={"인스타(" + instaCnt + ")"} />
              </Tabs>
            </div>
            {/*<div className='count'>*/}
            {/*총 이용회수 <span>{total}</span>*/}
            {/*</div>*/}
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  handleFilterChange = (event, filter) => {
    this.setState({ filter });
  };
}

export default ContractCards;
