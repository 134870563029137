import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import OpenInNew from '@material-ui/icons/OpenInNew';

import * as api from '../api';
import {ListItemIcon} from "../../../components/keyword-share-card/card";
import {Link} from "react-router";

class InfoBasic extends React.Component {
  state = {
    categories:[],
    lastCompanyPoint: 0
  }
  render() {
    const { campaign } = this.props;
    const { categories, lastCompanyPoint } = this.state;

    let firstItems = <MenuItem value={campaign.category.first}>{campaign.category.firstName}</MenuItem>;
    let secondItems = <MenuItem value={campaign.category.second}>{campaign.category.secondName}</MenuItem>;
    let thirdItems = <MenuItem value={campaign.category.third}>{campaign.category.thirdName}</MenuItem>;
    if ( Object.keys(categories).length > 0 ) {
      firstItems = Object.keys(categories).map((item) => {
        return <MenuItem key={item} value={item}>{item}</MenuItem>;
      });
      const second = categories[campaign.category.firstName].sub;
      secondItems = Object.keys(second).map((item) => {
        return <MenuItem key={item} value={item}>{item}</MenuItem>;
      });
      const third = campaign.category.secondName ? categories[campaign.category.firstName].sub[campaign.category.secondName].sub : {};
      thirdItems = Object.keys(third).map((item) => {
        return <MenuItem key={item} value={item}>{item}</MenuItem>;
      });
    }

    if (Object.keys(campaign).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>캠페인 정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <label>캠페인명</label>
                <TextField
                  fullWidth
                  value={campaign.name}
                  onChange={(evt) => this.props.handleChange('name', evt.target.value)}
                />
                <div className='row'>
                  <div style={{ flex: 1 }}>
                    <label>판매자명</label>
                    <TextField
                      fullWidth
                      value={campaign.companyName || ''}
                      onChange={(evt) => this.props.handleChange('companyName', evt.target.value)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>회원문의 연락처</label>
                    <TextField
                      fullWidth
                      value={campaign.contacts}
                      onChange={(evt) => this.props.handleChange('contacts', evt.target.value)}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div style={{ flex: 0.5 }}>
                    <label>캠페인 상태</label>
                    <Select
                      fullWidth
                      value={campaign.status}
                      disabled
                    >
                      <MenuItem value={undefined}>제안전</MenuItem>
                      <MenuItem value={0}>제안전</MenuItem>
                      <MenuItem value={1}>제안중</MenuItem>
                      <MenuItem value={2}>제안완료</MenuItem>
                    </Select>
                  </div>
                  <div style={{ flex: 0.5 }}>
                    <label>캠페인 단계</label>
                    <Select
                      fullWidth
                      value={campaign.step}
                      onChange={(evt) => this.props.handleChange('step', Number(evt.target.value))}
                    >
                      <MenuItem value={1}>1단계</MenuItem>
                      <MenuItem value={2}>2단계</MenuItem>
                      <MenuItem value={3}>3단계</MenuItem>
                      <MenuItem value={4}>4단계</MenuItem>
                      <MenuItem value={5}>5단계</MenuItem>
                      <MenuItem value={6}>6단계</MenuItem>
                      <MenuItem value={7}>7단계</MenuItem>
                      <MenuItem value={99}>캠페인 생성 완료</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className='row'>
                  <div style={{ flex: 0.5 }}>
                    <label>체험단/기자단</label>
                    <TextField
                      fullWidth
                      value={campaign.type === 'P' ? '기자단' : '체험단'}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div style={{ flex: 1 }}>
                    <label>대분류</label>
                    <Select
                      fullWidth
                      value={campaign.category.firstName}
                      onChange={(evt) => {
                        this.props.handlePropChange('category', 'first', categories[evt.target.value].id);
                        this.props.handlePropChange('category', 'firstName', evt.target.value);
                        this.props.handlePropChange('category', 'second', '');
                        this.props.handlePropChange('category', 'secondName', '');
                        this.props.handlePropChange('category', 'third', '');
                        this.props.handlePropChange('category', 'thirdName', '');
                      }}
                    >
                      {firstItems}
                    </Select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>중분류</label>
                    <Select
                      fullWidth
                      value={campaign.category.secondName}
                      onChange={(evt) => {
                        this.props.handlePropChange('category', 'second', categories[campaign.category.firstName].sub[evt.target.value].id);
                        this.props.handlePropChange('category', 'secondName', evt.target.value);
                        this.props.handlePropChange('category', 'third', '');
                        this.props.handlePropChange('category', 'thirdName', '');
                      }}
                    >
                      {secondItems}
                    </Select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>소분류</label>
                    <Select
                      fullWidth
                      value={campaign.category.thirdName}
                      onChange={(evt) => {
                        this.props.handlePropChange('category', 'third', categories[campaign.category.firstName].sub[campaign.category.secondName].sub[evt.target.value].id);
                        this.props.handlePropChange('category', 'thirdName', evt.target.value);
                      }}
                    >
                      {thirdItems}
                    </Select>
                  </div>
                </div>
                <div className='row'>
                  <div>
                    <label>최근 충전 내역</label>
                    {lastCompanyPoint ? (<Link to={ '/company-point?id=' + lastCompanyPoint.id } target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>) : ''}
                  </div>
                  <div>
                    <label>업체</label>
                    <Link to={ '/company-user?id=' + campaign.companyId } target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const categories = await api.getOptions('type=category');
    this.setState({ categories });
    const companyPoints = await api.getCompanyPoint(this.props.campaign.companyId);
    this.setState({lastCompanyPoint: companyPoints.companyPoints[companyPoints.total - 1]})
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoBasic);
