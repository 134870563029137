import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class DetailBasic extends React.Component {
  state = {
    companyPoint: {}
  };
  render() {
    let { companyPoint } = this.state;

    if (Object.keys(companyPoint).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{companyPoint.company.name}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>업체명</label>
                  <TextField
                    fullWidth
                    value={companyPoint.company.name}
                  />
                  <label>companyId</label>
                  <TextField
                    fullWidth
                    value={companyPoint.companyId}
                  />
                  <label>증감 금액</label>
                  <TextField
                    fullWidth
                    value={companyPoint.taggableType === 'charge' ? companyPoint.amount : -companyPoint.amount}
                  />
                  <label>현재 잔액</label>
                  <TextField
                    fullWidth
                    value={companyPoint.balance}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }
  componentDidMount() {
    this.setState({companyPoint: this.props.companyPoint});
  }

  componentWillReceiveProps(newProps) {
    this.setState({companyPoint: newProps.companyPoint});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(DetailBasic);