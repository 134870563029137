import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';
import {createAccount} from "./api";

const header = [
  { sortable: true, id: 'uid', label: 'uid' },
  { sortable: true, id: 'email', label: '이메일' },
  { sortable: false, id: 'displayName', label: '이름' },
  { sortable: false, id: 'phoneNumber', label: '연락처' },
  { sortable: false, id: 'disabled', label: '차단' },
  { sortable: false, id: 'creationTime', label: '생성일' },
  { sortable: false, id: 'lastSignInTime', label: '로그인' },
  { sortable: false, id: 'edit', label: '정보' },
];
const styles = (theme) => ({
  transparent: {
    backgroundColor: 'transparent',
    color: 'transparent',
  }
});

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  accounts: [],
  schema: {},
  total: 0,
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
  }
  componentDidMount() {
    this.fetchDatas(null, null, null, null);
  }
  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {[selectItem]: findText})
  };
  resetState = () => {
    let state = {};
    Object.keys(state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };
  handleCreateAccount = (email) => {
    api.createAccount({email: email});
  };
  handleChangePage(event) {
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, _.omit(this.state, Object.keys(initialState)));
  }
  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const page = (pageNum >= 0) ? pageNum : this.state.page;
    const filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      ...options
    };

    api.filterAccounts(filter).then((results) => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
  render() {
    const { rowsPerPage, orderBy, direction, accounts, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    const { classes } = this.props;
    let bodys = accounts.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.uid}</TableCell>
          <TableCell>{item.email || ''}</TableCell>
          <TableCell>{item.displayName}</TableCell>
          <TableCell>{item.phoneNumber}</TableCell>
          <TableCell>{item.disabled}</TableCell>
          <TableCell>{item.metadata.creationTime}</TableCell>
          <TableCell>{item.metadata.lastSignInTime}</TableCell>
          <TableCell>
            <Link to={'/account?uid=' + item.uid} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} handleCreateAccount={this.handleCreateAccount}/>
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(List));
