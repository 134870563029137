import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router';
import Table from '@material-ui/core/Table'; import TableBody from '@material-ui/core/TableBody'; import TableCell from '@material-ui/core/TableCell'; import TableRow from '@material-ui/core/TableRow';


import * as api from './api'
import ActionCreators from '../action'
import {SearchInput, SortableTableHead} from '../../components'
import _ from 'lodash';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const header = [
  {sortable: true, id: 'paidAt', label: '결제일시'},
  {sortable: true, id: 'cid', label: '가맹점'},
  {sortable: true, id: 'channel', label: '유저'},
  {sortable: true, id: 'type', label: '결제금액'},
  {sortable: true, id: 'payState', label: '수수료'},
  {sortable: true, id: 'extend', label: '결제수단'},
  {sortable: true, id: 'dday', label: '정산예정금액'},
  {sortable: true, id: 'alarm1st', label: '결제 상태'},
  {sortable: true, id: 'edit', label: '상세'}
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'paidAt',
  direction: 'desc',
  settlements: [],
  total: 0
};

class UserPays extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, 'id', 'desc', {[selectItem]: findText})
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined
    });

    this.setState({...state, ...initialState});
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { settlements, total, rowsPerPage, schema, ...rest } = (_.pickBy(options, _.identity) || {});

    const page = (pageNum >= 0) ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction
    };

    api.filterUserPay(filter).then(results => {
      console.log(results);
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const {rowsPerPage, orderBy, direction, settlements, total} = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = settlements.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.paidAt}</TableCell>
          <TableCell>{item.P_MNAME + '(' + item.cid + ')'}</TableCell>
          <TableCell>{item.username}</TableCell>
          <TableCell>{item.price}</TableCell>
          <TableCell>{item.settlementPrice ? (item.price - item.settlementPrice) : ''} </TableCell>
          <TableCell>신용카드</TableCell>
          <TableCell>{item.settlementPrice}</TableCell>
          <TableCell>{{0: '결제중', 1: '실결제완료', 3: '결제완료', 5: '정산예정', 7: '정산완료', 10: '결제취소', 20: '이용내역생성실패취소'}[item.status]}</TableCell>
          <TableCell><Link to={'/userpay?id=' + item.id} target="_blank">편집</Link></TableCell>
        </TableRow>
      )
    });
    return (
      <div className="table-container">
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}/>
          <TableBody>
            {bodys}
          </TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate previousLabel={"< 이전"}
                         nextLabel={"다음 >"}
                         breakLabel={<span>...</span>}
                         pageCount={pageCount}
                         marginPagesDisplayed={1}
                         pageRangeDisplayed={10}
                         onPageChange={this.handleChangePage}
                         containerClassName={"pagination"}
                         subContainerClassName={"pages pagination"}
                         activeClassName={"active"}
                         initialPage={0}
                         forcePage={this.state.page - 1}/>
          <div></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPays)