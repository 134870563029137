import React from 'react';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';

const header = [
  // {sortable:false, id:'rKey', label:'ID'},
  // { sortable: true, id: 'virtualId', label: '계약명' },
  { sortable: true, id: 'category', label: '대분류' },
  { sortable: true, id: 'company', label: '가맹점' },
  { sortable: true, id: 'type', label: '이용채널' },
  { sortable: true, id: 'username', label: '유저' },
  { sortable: true, id: 'currentState', label: '이용상태' },
  { sortable: true, id: 'date0', label: '이용일' },
  { sortable: false, id: 'reviewState', label: '리뷰상태' },
  { sortable: true, id: 'extended', label: '연장여부' },
  { sortable: false, id: 'rank', label: '순위' },
  { sortable: true, id: 'feedback', label: '피드백' },
  { sortable: true, id: 'quality', label: '포스팅퀄러티' },
  { sortable: false, id: 'edit', label: '편집' },
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'DATE(date0)',
  direction: 'desc',
  contracts: [],
  total: 0,
};

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  render() {
    const { rowsPerPage, orderBy, direction, contracts, total } = this.state;
    const pageCount =
      parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = contracts.map((item, index) => {
      let naverId = '';
      let currentState = '이용완료';
      let email = (item.userEmail || item.useremail) + '';
      let feedback = item.feedback || false;
      if (!email) {
        if (item.currentState === 1) currentState = '리뷰등록';
        else if (item.currentState === 2) currentState = '평가완료';
        else if (item.currentState === -1) currentState = '이용취소';
      } else {
        naverId = email.indexOf('@') > 0 ? email.split('@')[0] : email;
        if (item.link !== undefined) {
          if (item.currentState === 1) {
            currentState =
              item.type === 'insta' ? (
                <a href={item.link} target='_blank' rel='noopener noreferrer'>
                  리뷰등록
                </a>
              ) : (
                <a
                  href={`http://blog.naver.com/${naverId}/${item.link}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  리뷰등록
                </a>
              );
          } else if (item.currentState === 2)
            currentState =
              item.type === 'insta' ? (
                <a
                  className={'blue'}
                  href={item.link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  평가완료
                </a>
              ) : (
                <a
                  className={'blue'}
                  href={`http://blog.naver.com/${naverId}/${item.link}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  평가완료
                </a>
              );
          else if (item.currentState === -1) {
            currentState = '이용취소';
          }
        } else {
          if (item.currentState === 1) currentState = '리뷰등록';
          else if (item.currentState === 2) currentState = '평가완료';
          else if (item.currentState === -1) currentState = '이용취소';
        }
      }

      let reviewRemain = '등록완료';
      if (item.currentState === 0) {
        let today = new Date();
        let dateUsed = new Date(item.date0.split('T')[0]);
        let timeDiff = Math.abs(today.getTime() - dateUsed.getTime());
        let deadline = item.extended ? 9 : 4;
        reviewRemain = Math.ceil(timeDiff / (1000 * 3600 * 24)) - 1;
        reviewRemain =
          reviewRemain > deadline ? (
            <Typography color='secondary'>{reviewRemain + '일'}</Typography>
          ) : (
            reviewRemain + '일'
          );
      }
      let user =
        item.level === undefined && item.username === undefined
          ? '탈퇴유저'
          : item.level + ' ' + item.username;
      return (
        <TableRow
          key={index}
          style={
            item.currentState === -1 ? { textDecoration: 'line-through' } : {}
          }
        >
          {/*<TableCell>{item.rKey}</TableCell>*/}
          {/*<TableCell>{item.virtualId}</TableCell>*/}
          <TableCell>
            {item.category === 'R'
              ? '매장'
              : item.category === 'P'
              ? '제품'
              : (item.category || {}).firstName || item.first}
          </TableCell>
          <TableCell>
            <Link to={'/company3?id=' + item.company} target='_blank'>
              {item.companyName || item.companyname}
            </Link>
          </TableCell>
          <TableCell className='level'>
            {item.type === 'insta' ? (
              <span className='insta'>인스타</span>
            ) : (
              <span className='naver'>
                네이버{`${item.receiptOnly ? '(영수증)' : ''}`}
              </span>
            )}
          </TableCell>
          <TableCell>
            <Link to={'/user?uid=' + item.user} target='_blank'>
              {user}
            </Link>
            {item.type === 'insta' ? (
              item.userOuterId ? (
                <a
                  href={'https://instagram.com/' + item.userOuterId}
                  target='_blank'
                >
                  {' (' + item.userOuterId + ')'}
                </a>
              ) : (
                ''
              )
            ) : email ? (
              <a
                href={'https://blog.naver.com/' + (email || '').split('@')[0]}
                target='_blank'
              >
                {' (' + email + ')'}
              </a>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>{currentState}</TableCell>
          <TableCell>
            {momentLib(item.date0).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}
          </TableCell>
          <TableCell>{reviewRemain}</TableCell>
          <TableCell>{item.extended ? 'O' : 'X'}</TableCell>
          <TableCell>
            {item.type === 'insta' ? (
              item.like
            ) : item.rank > 0 ? (
              <Link to={'/company3?id=' + item.company} target='_blank'>
                {item.rank}
              </Link>
            ) : (
              0
            )}
          </TableCell>
          <TableCell>
            {feedback !== undefined ? (
              <Checkbox
                checked={feedback}
                onChange={(event) => {
                  api.updateContract(item.rKey || item.id, {
                    feedback: event.target.checked,
                  });
                  contracts[index].feedback = event.target.checked;
                  this.setState({ contracts: contracts });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            <TextField
              halfwidth
              type='number'
              value={item.quality}
              style={{ width: 40 }}
              onChange={(event) => {
                api.updateContract(item.rKey || item.id, {
                  quality: event.target.value,
                });
                contracts[index].quality = Number(event.target.value);
                this.setState({ contracts: contracts });
              }}
            />
          </TableCell>
          <TableCell>
            <Link to={'/contract?id=' + (item.id || item.rKey)} target='_blank'>
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className='table-container'>
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
          handlePrivate={this.handlePrivate}
          handleExceed={this.handleExceed}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy[0]}
            order={direction[0]}
            columns={header}
            handleSort={(orderBy, order) => {
              this.state.orderBy.unshift(orderBy);
              this.state.direction.unshift(order);
              let o = _.uniq(this.state.orderBy);
              let d = this.state.direction;
              if (o.length > 2) {
                this.state.orderBy.pop();
                this.state.direction.pop();
              }
              if (o.length !== d.length) {
                this.state.direction.pop();
              }

              this.setState({ orderBy: o, direction: d });
              this.fetchDatas(null, o, d, null);
            }}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className='table-footer'>
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {
      [selectItem]: findText,
    });
  };

  handlePrivate = () => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {
      private: true,
    });
  };

  handleExceed = () => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {
      exceedDeadline: true,
    });
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(
      event.selected,
      this.state.orderBy,
      this.state.direction,
      this.state
    );
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { contracts, total, rowsPerPage, schema, ...rest } =
      _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
    };

    api.filterContracts(filter).then((results) => {
      if (options && options.private) {
        results.contracts = results.contracts.filter((contract) => {
          let date = momentLib.tz('Asia/Seoul').format('YYYY-MM-DD');
          let privateCheckDate = momentLib(contract.date1)
            .add(90, 'day')
            .format('YYYY-MM-DD');
          return momentLib(date)
            .tz('Asia/Seoul')
            .isSameOrBefore(privateCheckDate);
        });
      }
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
