import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import EditorBasic from './editor-basic';
import EditorEtc from './editor-etc';
import EditorDelivery from './editor-delivery';
import EditorDeliveryHistory from './editor-delivery-history';
import EditorManuscriptHistory from './editor-manuscript-history';
import UserCard from '../../../components/user-card';
import * as api from '../api';
import EditorMemo from "./editor-memo";
import {InfoLog} from '../../../components'

class Proposition extends React.Component {
  state = {
    id: this.props.location.query.id,
    proposition: {},
    snackOpen: false,
    categories: {},
    manuscripts: []
  };
  async componentDidMount() {
    this.props.activateLoading();
    api.getProposition(this.state.id).then((result) => {
      let proposition = result.propositions[0];
      this.setState({ proposition });
    });
    let manuscripts = await api.filterManuscripts({propositionId: this.state.id});
    this.setState({ manuscripts: manuscripts.manuscripts });
    const categories = await api.getOptions('type=category');
    this.setState({ categories })

    this.props.deactivateLoading();
  }
  handleChange = (name, value) => {
    const proposition = { ...this.state.proposition };
    if (name === 'extended' && value && !proposition[name]) {
      proposition['deadline'] += 5;
    }
    else if (name === 'extended' && !value && proposition[name]) {
      proposition['deadline'] -= 5;
    }
    proposition[name] = value;
    this.setState({ proposition });
  };
  handleCancel = () => {
    this.props.push('/propositions');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteProposition(this.state.id)
        .then((result) => {
          alert('삭제 완료');
          this.props.push('/propositions');
        })
        .catch((err) => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .updateProposition(id, this.state.proposition)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };
  handleCreatePurchaseLink = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .createPurchaseLink(id)
      .then(() => {
        this.props.deactivateLoading();
        alert('생성 성공');
      })
      .catch((err) => {
        console.log(err);
        this.props.deactivateLoading();
        alert('생성에 실패했습니다. 다시 확인해주세요.');
      });
  };
  render() {
    const { proposition, categories, manuscripts } = this.state;
    if (Object.keys(proposition).length > 0) {
      return (
        <div className='contents'>
          <div className='editor-container contents-editor'>
            <EditorBasic categories={categories} proposition={proposition} handleChange={this.handleChange} />
            <EditorEtc proposition={proposition} handleChange={this.handleChange} handleCreatePurchaseLink={this.handleCreatePurchaseLink}/>
            <EditorMemo
              proposition={proposition}
              handleChange={this.handleChange}
              handleDelete={this.handleDelete}
              handleSave={this.handleSave}
            />
            <InfoLog type={'proposition'} id={proposition.id}/>
          </div>
          <div className='additional-container'>
            <UserCard uid={proposition.uid} />
            {proposition.delivery && (
              <div className='contents-editor'>
                <EditorDelivery proposition={proposition} handleChange={this.handleChange} handleSave={this.handleSave}/>
                <EditorDeliveryHistory
                  proposition={proposition}
                  handleChange={this.handleChange}
                  handleSave={this.handleSave}
                />
                <EditorManuscriptHistory
                  proposition={proposition}
                  manuscripts={manuscripts}
                  handleChange={this.handleChange}
                  handleSave={this.handleSave}
                />
              </div>
            )}
            <div className='contents-editor'>
              {/*<Exposure proposition={proposition} />*/}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => this.setState({ snackOpen: false })}
              >
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Proposition);
