import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import momentLib from 'moment-timezone';

import { fetchData } from '../../app/api';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import * as api from "../../app/drawn/api";

class PenaltyHistoryCard extends React.PureComponent {
  state = {
    penaltyHistory: [],
    history : {
      violatedRule: 1,
      isDeleted: false
    }
  };
  render() {
    let { penaltyHistory, history } = this.state;

    const elements = penaltyHistory
      ? penaltyHistory.map((item, idx) => {
        return (
          <TableRow key={item.id}>
            <TableCell>{item.createdAt}</TableCell>
            <TableCell>{item.violatedRule}</TableCell>
            <TableCell>
              <Checkbox checked={item.isDeleted} onChange={(evt) => {
                let penaltyHistory = [...this.state.penaltyHistory];
                penaltyHistory[idx] = {
                  ...item,
                  isDeleted: evt.target.checked
                }
                this.setState({penaltyHistory: penaltyHistory});
                this.forceUpdate();
              }}/>
            </TableCell>
            <Button
              color='secondary'
              onClick={() => {
                if (window.confirm('삭제시 패널티 히스토리가 삭제되고 delayed값은 변하지 않습니다. 정말 삭제 하시겠습니까')) {
                  let penaltyHistory = [...this.state.penaltyHistory];
                  penaltyHistory.splice(idx, 1);
                  this.setState({penaltyHistory: penaltyHistory});
                  this.forceUpdate();
                }
              }}
            >
              항목 삭제
            </Button>
          </TableRow>
        );
      }) : '';

    return (
      <div style={{marginBottom: '14px'}}>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>블로거 패널티</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section style={{marginBottom: '0px'}}>
              <div className='table-container'>
                  <Table style={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>일자</TableCell>
                        <TableCell>위반룰</TableCell>
                        <TableCell>해제</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {elements}
                    </TableBody>
                  </Table>
                </div>
              </section>
              <div className='row'>
                <div style={{ flex: 1 }}>
                  <label>위반룰</label>
                  <Select
                    fullWidth
                    value={history.violatedRule}
                    onChange={(evt) => {
                      history.violatedRule = evt.target.value;
                      this.setState({history: history});
                      this.forceUpdate();
                    }}
                  >
                    <MenuItem value={1}>1 포스팅 업로드 3개월 유지 위반</MenuItem>
                    <MenuItem value={2}>2 매너 안좋음</MenuItem>
                    <MenuItem value={3}>3 업로드 날짜 초과</MenuItem>
                  </Select>
                </div>
                <Button
                  color='secondary'
                  onClick={() => {
                    history.id = penaltyHistory.length > 0 ? (penaltyHistory[penaltyHistory.length - 1].id || 0) + 1 : 0;
                    history.createdAt = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');
                    this.setState((prevState) => ({
                      penaltyHistory: [
                        ...prevState.penaltyHistory,
                        {
                          ...history
                        }
                      ]
                    }));
                  }}
                >
                  추가
                </Button>
              </div>
              <div className='row' style={{ justifyContent: 'flex-end' }}>
                <Button color='secondary' onClick={this.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
  componentDidMount() {
    fetchData('get', '/user/console', { uid: this.props.uid }).then((datas) => {
      this.setState({
        penaltyHistory: datas.users[0].penaltyHistory || [],
        uid: datas.users[0].uid
      });
    });
  }
  handleSave = () => {
    let { penaltyHistory } = this.state;
    let updateData = {
      uid: this.props.uid,
      penaltyHistory: JSON.stringify(penaltyHistory)
    }
    let penaltyCount = (penaltyHistory || []).filter((history) => !history.isDeleted).length;
    if (penaltyCount > 3) {
      updateData.level = '등급중지';
    }
    fetchData('put', '/user/console', updateData).then(() => {
      alert('저장되었습니다.');
    }).catch(() => {
      alert('저장에 실패했습니다.');
    });
  }
}

export default PenaltyHistoryCard;
