import { useCallback, useState } from 'react';
import { uploadFile } from '../app/api';

const useUploadFiles = () => {
  const [fileURLs, setFileURLs] = useState([]);

  const uploadFiles = useCallback(async (id, files) => {
    try {
      const uploadedFileURLs = await Promise.all(
        files.map(async (file) => {
          const fileURL = await uploadFile(
            'inquiry_answer/' + id + '/' + file.name,
            file,
            (progress) => {}
          );
          return fileURL;
        })
      );
      setFileURLs(uploadedFileURLs);

      return uploadedFileURLs;
    } catch (error) {
      console.error(error);
    }
  }, []);

  return { fileURLs, uploadFiles };
};

export default useUploadFiles;
