import React from 'react';

import CarouselImage from './CarouselImage';
import ProductInfo from './ProductInfo';
import DateInfo from './DateInfo';
import ApplicationStatus from './ApplicationStatus';
import IntroductionContainer from './IntroductionContainer';
import ShortFormGuide from './ShortFormGuide';

const ProductDetail = (props) => {
  const userLevel = props.userLevel || 'EGG';
  const { ad, channel } = props.adsWithDetails;

  return (
    <div>
      <CarouselImage thumbnails={ad.thumbnails} />
      <ProductInfo
        title={ad.title}
        abstract={ad.abstract}
        salePrice={`$${channel.finalPrice[userLevel]}`}
        ogPrice={`$${channel.totalPrice[userLevel]}`}
      />

      <div style={{ display: 'flex', gap: 8, margin: '20px 16px' }}>
        {ad.deliveryMethod === 'REIMBURSEMENT' && (
          <div style={{ flex: 1 }}>
            <span style={{ fontWeight: 500 }}>Cashback </span>
            <span>
              ${channel.totalPrice[userLevel] - channel.finalPrice[userLevel]}
            </span>
          </div>
        )}

        {channel.rewardPoint[userLevel] > 0 && (
          <div style={{ flex: 1 }}>
            <span style={{ fontWeight: 500 }}>Point </span>
            <span>${channel.rewardPoint[userLevel]}</span>
          </div>
        )}
      </div>
      <DateInfo
        type={ad.type}
        startDate={channel.startDate}
        deadlineRecruitment={channel.deadlineRecruitment}
        deadlineAnnouncement={channel.deadlineAnnouncement}
        deadlineReview={channel.deadlineReview}
      />
      <ApplicationStatus enrolled={1} maxCapacity={10} />
      <IntroductionContainer
        introduction={ad.introduction}
        images={ad.contentImages}
      />
      <ShortFormGuide
        requiredHashTags={ad.requiredKeywords}
        optionalHashTags={ad.optionalKeywords}
        officialAccount={ad.instaOfficial}
        tasks={ad.tasks}
      />
    </div>
  );
};

export default ProductDetail;
