import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';

import Preview from './components/Preview';
import AdsInformation from './components/AdsInformation';
import ContractInformation from './components/ContractInfomation';
import UserInformation from './components/UserInformation';
import QuestionAnswersTable from './components/QuestionAnswersTable';
import StatusHistoryTable from './components/StatusHistoryTable';
import DeliveryHistoryTable from './components/DeliveryHistoryTable';
import CashbackHistoryTable from './components/CashbackHistoryTable';
import RewardHistoryTable from './components/RewardHistoryTable';
import HandBook from '../../components/HandBook';

import Button from '@material-ui/core/Button';
import { useEffect } from 'react';
import { getContract, updateContract } from '../../api/contract';
import Tools from './components/Tools';

const Contract = (props) => {
  const [contract, setContract] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await getContract(props.location.query.id);
      setContract(result.data.data.contract);
    };

    fetch();
  }, []);

  if (!contract) {
    return null;
  }

  const onChangeContract = (name, value) => {
    setContract({ ...contract, [name]: value });
  };

  const onPushHistory = (target, value) => {
    const clone = { ...contract };
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString();
    clone[target].push({
      status: value,
      updatedAt: formattedDate,
    });

    setContract(clone);
  };

  const onSave = async () => {
    const result = await updateContract(props.location.query.id, contract);
    if (result.data.success) {
      alert('저장되었습니다.');
      window.location.reload();
    }
  };

  return (
    <div className='contents'>
      <div className='editor-container contents-editor'>
        <Preview contract={contract} onChangeContract={onChangeContract} />
        <AdsInformation
          contract={contract}
          onChangeContract={onChangeContract}
        />
        <ContractInformation
          contract={contract}
          onChangeContract={onChangeContract}
        />

        <div style={{ textAlign: 'right' }}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              onSave();
            }}
            style={{ marginLeft: 12 }}
          >
            저장
          </Button>
        </div>
      </div>
      <div className='additional-container'>
        <Tools contract={contract} onChangeContract={onChangeContract} />

        <UserInformation
          contract={contract}
          onChangeContract={onChangeContract}
        />
        <QuestionAnswersTable
          questionAnswers={contract.questionAnswers || []}
        />
        <StatusHistoryTable
          statusHistory={contract.statusHistory}
          onPushHistory={onPushHistory}
        />
        {contract.deliveryMethod === 'DIRECT_SHIPPING' && (
          <DeliveryHistoryTable
            deliveryHistory={contract.deliveryHistory}
            onPushHistory={onPushHistory}
          />
        )}
        {contract.deliveryMethod === 'REIMBURSEMENT' && (
          <CashbackHistoryTable
            cashbackHistory={contract.cashbackHistory}
            onPushHistory={onPushHistory}
          />
        )}
        {contract.rewardAmount > 0 && (
          <RewardHistoryTable
            rewardHistory={contract.rewardHistory}
            onPushHistory={onPushHistory}
          />
        )}
      </div>
      <HandBook category='CONTRACT' />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
