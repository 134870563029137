import React, { PureComponent } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import _ from 'lodash';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Receipt from '@material-ui/icons/Receipt';
import IconButton from "@material-ui/core/IconButton/IconButton";
import momentLib from "moment-timezone";

class InfoNotice extends PureComponent {
  render() {
    const { company, paycards, channel } = this.props;



    let items = <div/>;
    if(!_.isEmpty(paycards)) {
      items = paycards.map((paycard, idx) => {
        if(paycard.payState === 1 || paycard.payState === 2) {
          let payState = '결제 예정';
          if (paycard.payState === 1) {
            payState = '결제 완료';
          }
          else if (paycard.payState === 2) {
            payState = '결제 요청';
          }

          return (
            <div key={idx} style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 12,
              borderBottom: '1px solid #dbdbdb'
            }}>

              <div>
                <span>{paycard.order}차</span>
              </div>
              <div>
                <span>{company[channel].payInfo_payMethod || paycard.payMethod}</span>
              </div>
              <div>
                <span>{paycard.contractCount + (momentLib(new Date()).tz('Asia/Seoul').isSameOrBefore(paycard.nextPaidAt) ? '(진행중)' : '')}</span>
              </div>
              <div>
                <span>{paycard.card_name}</span>
              </div>
              <div>
                <span>{paycard.amount}</span>
              </div>
              <div>
                <span>{payState}</span>
              </div>
              <div>
                <span>{momentLib(paycard.paidAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</span>
              </div>
              <div>
                <IconButton>
                  <Receipt/>
                </IconButton>
              </div>
            </div>
          );
        }
        else {
          return(<div/>);
        }
      });
    }
    else {
      return(<div/>)
    }

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{channel + `${company.name} 결제내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            {items}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {
  }
  async componentWillReceiveProps(nextProps) {
  }
}

export default InfoNotice;
