import React from 'react';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';
import {filterManuscripts} from "./api";

const header = [
  // {sortable:false, id:'rKey', label:'ID'},
  { sortable: true, id: 'category', label: '대분류'},
  { sortable: true, id: 'campaign', label: '캠페인명' },
  { sortable: true, id: 'type', label: '이용채널' },
  { sortable: true, id: 'username', label: '유저' },
  { sortable: true, id: 'status', label: '상태' },
  { sortable: true, id: 'pointStatus', label: '포인트상태' },
  { sortable: true, id: 'proposedAt', label: '제안일' },
  { sortable: false, id: 'point', label: '제안 금액' },
  { sortable: true, id: 'feedback', label: '피드백' },
  { sortable: false, id: 'edit', label: '상세' },
];

const toggles = [
  // {sortable:false, id:'rKey', label:'ID'},
  { sortable: true, id: 'proposeStatus', label: '제안 단계', filter: ['제안', '수락', '거절', '무응답'], value: [1, 2, 3, 4]},
  { sortable: true, id: 'useStatus', label: '이용 단계', filter: ['발송대기', '미방문'], value: [10, 12]},
  { sortable: true, id: 'manuStatus', label: '원고 단계', filter: ['원고대기', '원고수정요청', '원고확인중'], value: [20, 22, 24]},
  { sortable: true, id: 'reviewStatus', label: '리뷰 단계', filter: ['리뷰대기중', '리뷰완료', '평가완료'], value: [30, 32, 40]},
  { sortable: true, id: 'etcStatus', label: '기타', filter: ['취소'], value: [50]}
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  propositions: [],
  total: 0,
};

class Propositions extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  render() {
    const { rowsPerPage, orderBy, direction, propositions, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = propositions.map((item, index) => {
      let status = '제안';
      let pointStatus = '캠페인진행중';
      let adminFeedback = item.adminFeedback || false;
      if (item.status === 0) {
        status = '목록';
      }
      else if (item.status === 1) {
        status = '제안';
      }
      else if (item.status === 2) {
        status = '수락';
      }
      else if (item.status === 3) {
        status = '거절';
      }
      else if (item.status === 4) {
        status = '무응답';
      }
      else if (item.status === 10) {
        status = '발송대기';
      }
      else if (item.status === 12) {
        status = '미방문';
      }
      else if (item.status === 20) {
        status = '이용완료';
      }
      else if (item.status === 22) {
        status = (<a onClick={() => this.openManuscript(item)} target="_blank">
          원고수정요청
        </a>)
      }
      else if (item.status === 24) {
        status = (<a onClick={() => this.openManuscript(item)} target="_blank">
          원고확인중
        </a>)
      }
      else if (item.status === 30) {
        status = '리뷰대기중';
      }
      else if (item.status === 32) {
        status = (<a href={`${item.review}`} target="_blank" rel="noopener noreferrer">
          리뷰완료
        </a>)
      }
      else if (item.status === 40) {
        status = (<a className={'blue'}
          href={item.review}
          target="_blank"
          rel="noopener noreferrer">
          평가완료
        </a>)
      }
      else if (item.status === 50) {
        status = '이용취소';
      }

      if (item.pointStatus === 0) {
        pointStatus = '캠페인진행중';
      }
      else if (item.pointStatus === 1) {
        pointStatus = '적립대기';
      }
      else if (item.pointStatus === 2) {
        pointStatus = '적립보류';
      }
      else if (item.pointStatus === 5) {
        pointStatus = '취소';
      }
      else if (item.pointStatus === 10) {
        pointStatus = '적립완료';
      }
      else if (item.pointStatus === 101) {
        pointStatus = '인출대기';
      }
      else if (item.pointStatus === 110) {
        pointStatus = '인출완료';
      }

      return (
        <TableRow key={index} style={item.currentState === -1 ? {textDecoration: 'line-through'} : {}}>
          {/*<TableCell>{item.rKey}</TableCell>*/}
          <TableCell>
            {((item.category || {}).firstName || item.first)}
          </TableCell>
          <TableCell>
            <Link to={'/campaign?id=' + item.campaignId} target="_blank">
              {item.cname}
            </Link>
          </TableCell>
          <TableCell className="level">
            {item.type === 'youtube' ? <span className="youtube">유튜브</span> : item.type === 'insta' ? <span className="insta">인스타</span> : <span className="naver">네이버</span>}
          </TableCell>
          <TableCell>
            <Link to={'/user?uid=' + item.uid} target="_blank">
              {item.Influencer.account}
            </Link>
          </TableCell>
          <TableCell>{status}</TableCell>
          <TableCell>{pointStatus}</TableCell>
          <TableCell>{item.proposedAt ? momentLib(item.proposedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm') : '-'}</TableCell>
          <TableCell>{item.point}</TableCell>
          <TableCell>
            {adminFeedback !== undefined ? (
              <Checkbox
                checked={adminFeedback}
                onChange={(event) => {
                  api.updateProposition(item.id, { adminFeedback: event.target.checked });
                  propositions[index].adminFeedback = event.target.checked;
                  this.setState({ propositions: propositions });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            <Link to={'/proposition?id=' + (item.id || item.rKey)} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={toggles} handleSearch={this.handleSearch} handlePropositionFilter={this.handlePropositionFilter}/>
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => {
              this.setState({orderBy: orderBy, direction: direction});
              this.fetchDatas(null, orderBy, direction, null);
            }}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };

  handlePropositionFilter = (sel) => {
    let options = {}
    console.log(sel)
    sel.forEach((item, idx) => {
      if (item.length > 0) {
        let index = toggles[idx].filter.indexOf(item[0]);
        options.status = toggles[idx].value[index];
      }
    });
    console.log(options)
    this.fetchDatas(0, 'id', 'desc', options)
  }

  openManuscript = (item) => {
    api.filterManuscripts({propositionId: item.id, orderBy: 'step', direction: 'desc'}).then((manuscripts) => {
      window.open(manuscripts.manuscripts[0].manuscript)
    })
  }

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { propositions, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
    };

    api.filterPropositions(filter).then((results) => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Propositions);
