import React, { Component } from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";

import { fetchData } from "../../app/api";
import Card from "./card";
import "./cards.css";

class PropositionCards extends Component {
  state = {
    propositions: [],
    campaigns: [],
    manuscript: [],
    total: 0,
    filter: 0
  };
  async componentDidMount() {
    const { campaign, user } = this.props;
    const query = {
      orderBy: "proposedAt",
      direction: "desc"
    };
    let forWho = null;
    if (campaign !== undefined) {
      query.campaignId = campaign;
      forWho = "campaign";
    }
    if (user !== undefined) {
      query.uid = user;
      forWho = "user";
    }
    query.include = ['user'];

    try {
      let datas = await fetchData("get", "/proposition/console", query);
      datas.propositions = datas.propositions.filter((proposition) => proposition.status > 0);
      datas.total = datas.propositions.length;
      this.setState({...datas, forWho});

      let option = '';
      datas.propositions.map((proposition, idx) => {
        option += 'propositionId=' + proposition.id;
        if (idx !== datas.propositions.length - 1) {
          option += ' OR '
        }
      });

      let scripts = await fetchData("get", "/proposition/manuscript", {orderBy: 'step', direction: 'desc', query: option});
      let manuscript = _.groupBy(scripts.manuscripts, 'propositionId');
      this.setState({manuscript: manuscript});
    }
    catch (e) {
      console.error(e);
      alert("제안내역 조회 실패");
    }
  }
  render() {
    const { campaignName } = this.props;
    const { propositions, total, forWho, campaigns, filter, manuscript } = this.state;
    let waitCnt = 0, acceptCnt = 0, refuseCnt = 0, noResCnt = 0;
    let cards = propositions.map(proposition => {
      let type = proposition.status || 0;
      if (type === 1) {
        waitCnt++;
      } else if (type === 2 || type > 10) {
        acceptCnt++;
      } else if (type === 3) {
        refuseCnt++;
      }
      else if (type === 4) {
        noResCnt++;
      }
      if (
        (filter === 1 && type === 1) ||
        (filter === 2 && (type === 2 || type > 10)) ||
        (filter === 3 && type === 3) ||
        (filter === 4 && type === 4) ||
        filter === 0
      ) {
        return (
          <Card
            key={proposition.id || proposition.rKey}
            proposition={proposition}
            forWho={forWho}
            companies={campaigns}
            manuscript={manuscript[proposition.id]}
          />
        );
      } else {
        return null;
      }
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>
            {campaignName === undefined
              ? "유저 이용내역"
              : `${campaignName}   이용내역`}
          </h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="proposition-cards">
            <div>
              <Tabs value={filter} onChange={this.handleFilterChange}>
                <Tab label={"전체(" + total + ")"} style={{minWidth: 140}}/>
                <Tab label={"확인중(" + waitCnt + ")"} style={{minWidth: 140}}/>
                <Tab label={"수락(" + acceptCnt + ")"} style={{minWidth: 140}}/>
                <Tab label={"거절(" + refuseCnt + ")"} style={{minWidth: 140}}/>
                <Tab label={"무응답(" + noResCnt + ")"} style={{minWidth: 140}}/>
              </Tabs>
            </div>
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  handleFilterChange = (event, filter) => {
    this.setState({ filter });
  };
}

export default PropositionCards;
