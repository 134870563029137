import React, { useRef, useState } from 'react';
import Typo from '../../../components/Typo';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import CustomTextField from '../../../components/CustomTextField';
import ImageGallery from '../../../components/ImageList';
import CustomButton from '../../../components/CustomButton';
import CustomDialog from '../../../components/CustomDialog';
import useUploadFiles from '../../../hooks/useUploadFiles';
import { updateAnswer } from '../../../services/supermembers/inquiry';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';

const ModifyInquiryAnswer = ({ id, inquiryAnswer, setModify, ...props }) => {
  const { contentText, contentImages } = inquiryAnswer;

  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [answer, setAnswer] = useState(contentText);
  const [previewImages, setPreviewImages] = useState(contentImages);

  const { fileURLs, uploadFiles } = useUploadFiles();

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    const newImages = Array.from(newFiles).map((file) =>
      URL.createObjectURL(file)
    );

    setFiles([...files, ...newFiles]);
    setPreviewImages([...previewImages, ...newImages]);
  };

  const submitAnswer = async () => {
    const uploadedFileURLs = await uploadFiles(id, files);

    try {
      props.activateLoading();
      const response = await updateAnswer(id, inquiryAnswer.id, {
        contentText: answer,
        contentImages: [...previewImages, ...uploadedFileURLs],
      });

      if (response) {
        alert('답변이 등록되었습니다.');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.deactivateLoading();
    }
  };

  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelSummary>
        <Typo typography={'Body1'} fontWeight={600}>
          답변 수정
        </Typo>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <CustomTextField
            value={answer}
            onChange={(event) => {
              setAnswer(event.target.value);
            }}
            multiline
            showModifiedStatus={false}
          />

          <ImageGallery
            images={previewImages}
            onDeleteImage={(index) => {
              setPreviewImages(
                previewImages.filter((_, imageIdx) => imageIdx !== index)
              );
            }}
            showDeleteButton={true}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              사진 첨부
            </CustomButton>
            <input
              type='file'
              accept='image/*'
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />

            <div style={{ display: 'flex', gap: 12 }}>
              <CustomButton
                type='secondary'
                onClick={() => {
                  setModify({ isModifying: false, index: null });
                }}
              >
                취소
              </CustomButton>

              <CustomButton
                type='primary'
                onClick={() => {
                  setOpen(true);
                }}
              >
                수정완료
              </CustomButton>
            </div>

            <CustomDialog
              title='수정 완료 확인'
              content='수정을 완료하시겠습니까?'
              open={open}
              setOpen={setOpen}
              handleConfirm={submitAnswer}
            />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(ModifyInquiryAnswer);
