import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import { mapSeries } from 'p-iteration';

import * as api from '../api';
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

class LoadInfluencer extends React.Component {
  state = {
    influencer: [],
    progress: -1,
    categories:[],
  }
  render() {
    const { categories, progress } = this.state;

    if (Object.keys(categories).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>인플로언서 업데이트</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <div className='row'>
                  <div style={{ flex: 1 }}>
                    <input
                      accept="text/tab-separated-values, .csv"
                      id="tsv-button-file"
                      onChange={this.handleLoad}
                      type="file"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    { progress == -1 ?
                      <IconButton onClick={this.handleUpload}>
                        <CloudUploadIcon />
                      </IconButton> :
                      <CircularProgress variant="static" value={progress} color="secondary"/>
                    }
                  </div>
                  <div style={{ flex: 1}}>
                    {progress > -1 ? (progress + '%') : ''}
                  </div>
                </div>
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const catobj = await api.getOptions('type=category');
    const result = await api.getCategory();
    result.categories.forEach((category) => {
      if(category.second && category.third) {
        category.firstOrder = catobj[category.first].order;
        category.secondOrder = catobj[category.first].sub[category.second].order;
        category.thirdOrder = category.order;
      }
      else if(category.second) {
        category.firstOrder = catobj[category.first].order;
        category.secondOrder = category.order;
        category.thirdOrder = -1;
      }
      else {
        category.firstOrder = category.order;
        category.secondOrder = -1;
        category.thirdOrder = -1;
      }
    });
    result.categories = _.sortBy(result.categories, ['firstOrder', 'secondOrder', 'thirdOrder']);
    result.categories.push({first: '', second: '', third: ''});

    this.setState({ categories: result.categories })
  }

  handleLoad = (event) => {
    const fileReader = new FileReader();

    // fileReader.readAsDataURL(event.target.files[0]);
    fileReader.readAsText(event.target.files[0]);
    fileReader.onload = (e) => {
      let items = e.target.result.split('\n');
      items = items.filter((item) => {
        return item.length > 0;
      });
      let cat2 = [];
      items = items.map((item, idx) => {
        item = item.split(/[\t,]/);
        let keywords = (item[7] || '').split(':');
        let categories = {}
        for(let i = 8; i < item.length; i++) {
          if (idx === 0) {
            cat2.push(item[i])
          }
          else {
            categories[cat2[i-8]] = parseFloat(item[i]);
          }
        }
        return {
          account: item[0],
          rank: parseInt(item[4].replace(/\,/g,'')),
          percentage: parseFloat(item[5]),
          adFee: parseFloat(item[6].replace(/\,/g,'')),
          score: parseFloat(item[1]),
          visitor: parseFloat(item[2]),
          sp: parseFloat(item[3]),
          keywords: JSON.stringify(keywords),
          categories: JSON.stringify(categories)
        }
      });
      items.splice(0, 1);
      this.setState({
        influencer: items
      });
    }
  };

  handleUpload = async () => {
    let chunks = _.chunk(this.state.influencer, 100);
    await mapSeries(chunks, async (chunk, idx) => {
      await api.createInfluencer({influencers: chunk});
      if ((this.state.influencer || []).length > 0) {
        this.setState({
          progress: Math.floor(((idx + 1) * 100 * 100) / ((this.state.influencer || []).length))
        });
      }
      return null;
    });
    await api.refreshInfluencerRank();
    this.setState({ progress: -1 });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(LoadInfluencer);
