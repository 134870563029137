import React, { useEffect, useMemo, useRef, useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import {
  StyledPaper,
  StepContent,
  ChecklistHeader,
  ButtonContainer,
  CheckList,
  CheckListItem,
  CheckListItemDot,
  ChecklistContainer,
  ChecklistItemContainer,
  localTheme,
  COLOR,
  ErrorList,
  ErrorListItem,
} from "./StyledComponents";
import Divider from "@material-ui/core/Divider";

import {
  AdsProgressStepEnum,
  AdsProgressStepLabelEnum,
} from "../../paycard.enum";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";
import { ReviewingRule } from "../rules";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import ChangePaymentInfoDialog from "./dialogs/pending-payment/ChangePaymentInfoDialog";

const PaymentStepper = ({ ad, activeStep, errors, onAction }) => {
  useEffect(() => {
    console.log("@@@@@ errors", errors);
  }, [errors]);

  const hasError = useMemo(() => {
    if (!errors) return null;
    else
      return Object.entries(errors).reduce((acc, [errorStep, errorFields]) => {
        acc[errorStep] = Object.values(errorFields).some((field) => !!field);
        return acc;
      }, {});
  }, [errors]);

  const executePaymentDialogRef = useRef();

  const renderStepContent = (step) => {
    switch (step) {
      case AdsProgressStepEnum.REVIEWING:
        const reviewingErrors = errors.REVIEWING;
        console.log("@@ reviewingErrors", reviewingErrors);
        return (
          <>
            <ChecklistContainer>
              <ChecklistHeader complete={hasError && !hasError.REVIEWING}>
                {hasError && !hasError.REVIEWING ? (
                  <CheckBox />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
                다음 항목들을 확인해주세요.
              </ChecklistHeader>
              <CheckList>
                {Object.entries(reviewingErrors).map(
                  ([field, error], index) => (
                    <ChecklistItemContainer
                      key={index}
                      complete={error === null}
                    >
                      <CheckListItem complete={error === null}>
                        <CheckListItemDot complete={error === null} />
                        {ReviewingRule.name[field]}
                      </CheckListItem>
                      {!!error && (
                        <ErrorList>
                          {error.map((e, i) => (
                            <ErrorListItem key={i}>{e.message}</ErrorListItem>
                          ))}
                        </ErrorList>
                      )}
                    </ChecklistItemContainer>
                  )
                )}
              </CheckList>
            </ChecklistContainer>
            <Divider style={{ margin: "24px 0px" }} />
            <ButtonContainer>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onAction("createNewAdPayment")}
              >
                신규 차수 생성
              </Button>
            </ButtonContainer>
          </>
        );
      case AdsProgressStepEnum.PENDING_PAYMENT:
        const pendingPaymentErrors = errors.PENDING_PAYMENT;
        console.log("@@ pendingPaymentErrors", pendingPaymentErrors);
        // TODO: !!!!!
        return (
          <>
            <ChecklistContainer>
              <ChecklistHeader complete={hasError && !hasError.PENDING_PAYMENT}>
                {hasError && !hasError.PENDING_PAYMENT ? (
                  <CheckBox />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
                다음 항목들을 확인해주세요.
              </ChecklistHeader>
              <CheckList>
                {Object.entries(pendingPaymentErrors).map(
                  ([field, error], index) => (
                    <ChecklistItemContainer
                      key={index}
                      complete={error === null}
                    >
                      <CheckListItem complete={error === null}>
                        <CheckListItemDot complete={error === null} />
                        {ReviewingRule.name[field]}
                      </CheckListItem>
                      {!!error && (
                        <ErrorList>
                          {error.map((e, i) => (
                            <ErrorListItem key={i}>{e.message}</ErrorListItem>
                          ))}
                        </ErrorList>
                      )}
                    </ChecklistItemContainer>
                  )
                )}
              </CheckList>
            </ChecklistContainer>
            <Divider style={{ margin: "24px 0px" }} />
            <ButtonContainer>
              <Button
                variant="outlined"
                onClick={() => onAction("changePaymentInfo")}
              >
                결제정보 변경
              </Button>
              <Button
                variant="outlined"
                onClick={() => onAction("changeRecruitmentInfo")}
              >
                모집인원 변경
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => executePaymentDialogRef.current.open()}
              >
                결제하기
              </Button>
            </ButtonContainer>
            <ChangePaymentInfoDialog
              ref={executePaymentDialogRef}
              paymentCards={null}
              onExecute={null}
            />
          </>
        );
      case AdsProgressStepEnum.PENDING_ACTIVE:
      case AdsProgressStepEnum.ACTIVE:
      case AdsProgressStepEnum.PENDING_TERMINATION:
      case AdsProgressStepEnum.TERMINATED:
      default:
        return null;
    }
  };

  return (
    <ThemeProvider theme={localTheme}>
      <StyledPaper>
        <h2 style={{ margin: 0 }}>
          <span style={{ fontWeight: "bold" }}>{ad.name}</span> - 광고 진행 상황
          <span style={{ fontSize: "0.7em", margin: "0px 0px 0px 12px" }}>
            (~2024-11-28 15:00 변경사항까지 적용)
          </span>
        </h2>

        <Stepper
          alternativeLabel
          activeStep={activeStep}
          style={{ marginTop: "20px" }}
        >
          {Object.values(AdsProgressStepLabelEnum).map((label, step) => {
            const labelProps = {};
            const labelStyles = {};
            if (step < activeStep) {
              labelProps.completed = true;
              labelStyles.color = COLOR.STEP_COMPLETED;
            } else if (step === activeStep) {
              labelProps.active = true;
              labelStyles.color = COLOR.STEP_ACTIVE;
            }
            return (
              <Step key={step}>
                <StepLabel {...labelProps}>
                  <span style={{ ...labelStyles }}>{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <StepContent>{renderStepContent(activeStep)}</StepContent>
      </StyledPaper>
    </ThemeProvider>
  );
};

export default PaymentStepper;
