import * as api from '../api';

export function filterCampaigns(query) {
  return api.fetchData('get', '/campaign/console', query).then((datas) => {
    return Promise.resolve(datas);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function getSchema() {
  return api
    .fetchData('get', '/campaign/schema')
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCampaign(id) {
  if (id) {
    return api
      .fetchData('get', '/campaign', { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function deleteCampaign(id) {
  return api
    .fetchData('delete', '/campaign/console', { id: id })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function updateCampaign(id, json) {
  return api
    .fetchData('put', '/campaign', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
export function getQuestions() {
  return api
    .fetchData('get', '/question')
    .then((qs) => {
      return Promise.resolve(qs);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
export async function getAnswers(questionId, campaignId) {
  return await Promise.all([
    api.fetchData('get', '/question?id='+questionId),
    api.fetchData('get', `/question/answer?questionId=${questionId}&campaign=${campaignId}`),
  ])
}

export async function deleteAnswer(questionId, answerId) {
  return api.fetchData('delete', 'question/answer', {questionId: questionId, answerId: answerId}).then(result => {
    return Promise.resolve(result);
  }).catch((err) => {
    return Promise.reject(err)
  });
}

export async function getOptions(query) {
  const url = '/company/option?' + query;
  return await api.fetchData('get', url);
}

export function getHistory(id) {
  if (id) {
    return api
      .fetchData('get', '/campaign/history', { campaignId: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateHistory(comId, id, json) {
  return api
    .fetchData('put', '/campaign/history', { campaignId: comId, id: id}, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteHistory(comId, id) {
  return api
    .fetchData('delete', '/campaign/history', { campaignId: comId, id: id})
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getPush(id) {
  if (id) {
    return api
      .fetchData('get', '/push', { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updatePush(id, json) {
  return api
    .fetchData('put', '/push', { id: id}, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deletePush(id) {
  return api
    .fetchData('delete', '/push', { id: id})
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function sendSms(json) {
  return api
    .fetchData("post", "/utils/sendsms", null, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getCompanyPoint(companyId) {
  if (companyId) {
    return api
      .fetchData('get', '/company/user/point', { companyId: companyId })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getLog(id) {
  let query = {
    bool: {
      must: [
        {
          terms: {
            baseUrl: ['campaign']
          }
        },
        {
          multi_match: {
            query: id,
            fields: ["mergeParams.campaignId", "mergeParams.id"]
          }
        }
      ]
    }
  }
  let params = new URLSearchParams({
    query: JSON.stringify(query).replace(/\\n/g, " ")
  });
  return api.fetchData("get", "/log/auto?" + params.toString())
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}