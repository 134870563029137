import React from 'react';
import { LOGO_IMAGE } from '../pages/constants';
import TagWithPrice from '../pages/ad/components/Preview/TagWithPrice';

const CompanyCard = (props) => {
  //   const width = imageSize;
  const width = 162;

  const generatedAdsItem = (adsWithDetails) => {
    
    const { ad, channel } = adsWithDetails;
    
    const tempLevel = props.userLevel || 'EGG';
    const point = channel.rewardPoint && channel.rewardPoint[tempLevel];
    const cashback =
      ((channel.totalPrice || {})[tempLevel] || 0) - ((channel.finalPrice || {})[tempLevel] || 0);

    const isLocked = !props.userLevel
      ? false
      : !ad.availableLevels.includes(props.userLevel);

    return {
      id: ad.id,
      title: ad.title,
      description: ad.abstract,
      image: ad.thumbnail,
      salePrice: `$${((channel.finalPrice || {})[tempLevel] || 0)}`,
      ogPrice: `$${((channel.totalPrice || {})[tempLevel] || 0)}`,
      logoImage:
        ad.channel === 'TIKTOK' ? LOGO_IMAGE.TIKTOK : LOGO_IMAGE.TIKTOK,
      ...(point > 0 && {
        pointAmount: `${point}`,
      }),
      ...(cashback > 0 &&
        ad.deliveryMethod === 'REIMBURSEMENT' && {
          cashbackAmount: `${cashback}`,
        }),
      isLocked,
    };
  };

  const data = generatedAdsItem(props.adsWithDetails);

  return (
    <div
      style={{ width: `${width}px`, cursor: 'pointer', margin: '16px' }}
      onClick={props.onClickHandler}
    >
      {/* Image */}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '12px',
          width: `${width}px`,
          height: `${width}px`,
        }}
      >
        {data.isLocked && (
          <div
            style={{
              position: 'absolute',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <i
              className='material-icons'
              style={{ fontSize: '44px', color: 'white' }}
            >
              lock
            </i>
            <p
              style={{
                fontWeight: 500,
                color: 'white',
                marginTop: '8px',
              }}
            >
              LOCKED
            </p>
          </div>
        )}
        <img
          src={data.image}
          alt={data.title}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        {data.announcement && (
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '24px',
            }}
          >
            {!data.announcementIconHide && (
              <i className='material-icons' style={{ marginRight: '4px' }}>
                schedule
              </i>
            )}
            <p style={{ color: 'white', fontSize: '12px' }}>
              {data.announcement}
            </p>
          </div>
        )}
        <div
          style={{
            position: 'absolute',
            left: '12px',
            top: '12px',
          }}
        >
          <img
            src={data.logoImage}
            alt='logo'
            style={{ height: '24px', width: '24px' }}
          />
        </div>
      </div>
      <div style={{ marginTop: '16px', opacity: data.isLocked ? 0.6 : 1 }}>
        <h3
          style={{
            fontSize: '16px',
            fontWeight: 500,
            margin: 0,
            color: '#333',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {data.title}
        </h3>
        {data.description && (
          <p style={{ fontSize: '14px', color: '#777' }}>{data.description}</p>
        )}
        {(data.salePrice || data.ogPrice) && (
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}
          >
            <span style={{ fontSize: '14px', fontWeight: 500, color: '#333' }}>
              {data.salePrice}
            </span>
            <span
              style={{
                fontSize: '14px',
                textDecoration: 'line-through',
                color: '#999',
                marginLeft: '8px',
              }}
            >
              {data.ogPrice}
            </span>
          </div>
        )}
        {(data.cashbackAmount !== null || data.pointAmount !== null) && (
          <div style={{ marginTop: '8px' }}>
            {data.cashbackAmount && (
              <TagWithPrice type='cashback' price={data.cashbackAmount} />
            )}
            {data.pointAmount && (
              <TagWithPrice type='point' price={data.pointAmount} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyCard;
