import React, { useState, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import { updateUser, resetPostLevel } from "../../app/users/api"; 
import "./index.css"; 

const today = new Date();

const PostLevelCard = ({ user, activateLoading, deactivateLoading, openSnack, setUserState}) => {
  const [level, setLevel] = useState("None");
  const [createdAt, setCreatedAt] = useState(today);
  const [updateAt, setUpdateAt] = useState(today);
 

  function refreshFormField(level){
    const now = new Date();
    setCreatedAt(now);
    setUpdateAt(now);
    setLevel(level);
  }  
  
  return (
    <div style={{ marginBottom: 20 }}>
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>등급 재심사</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="post-level">
            <div style={{ width: "70%" }}>
              <label>변경 등급</label>
              <Select
                value={level}
                onChange={evt => setLevel(evt.target.value)}
              >
                <MenuItem value="None">
                  <em>등급 선택</em>
                </MenuItem>
                <MenuItem value="diamond">diamond</MenuItem>
                <MenuItem value="black">black</MenuItem>
                <MenuItem value="red">red</MenuItem>
                <MenuItem value="yellow">yellow</MenuItem>
                <MenuItem value="심사거절">심사대기</MenuItem>
                <MenuItem value="심사거절">심사거절</MenuItem>
                <MenuItem value="등급중지">등급중지</MenuItem>
                <MenuItem value="레벨 재심사중">레벨 재심사중</MenuItem>
              </Select>
              <label>재심사 일자</label>
              <TextField
                disabled={level === "None"}
                fullWidth
                value={
                  createdAt && createdAt !== today
                    ? moment(createdAt)
                        .tz("Asia/Seoul")
                        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    : ""
                }
                InputProps={{
                  readOnly: true
                }}
              />
              <label>등급변경 일자</label>
              <TextField
                disabled={level === "None"}
                fullWidth
                value={
                  updateAt && updateAt !== today
                    ? moment(updateAt)
                        .tz("Asia/Seoul")
                        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    : ""
                }
                InputProps={{
                  readOnly: true
                }}
              />
            </div>
            <div className="button-box">
              <Button
                variant="contained"
                onClick={() => {
                  if (
                    window.confirm(
                      "재심사 내역을 즉시 반영하고 푸시를 전송하시겠습니까?"
                    )
                  ) {
                    activateLoading();
                    updateUser(user.uid, { level }).then(() => {
                      deactivateLoading();
                      openSnack();
                      resetPostLevel(user.uid).then(async ()=>{
                        refreshFormField(level);
                        await setUserState()
                      }).catch(err=>{
                        console.error(err); 
                      })
                    }).catch(err=>{
                      console.error(err);
                      alert('변경 중 오류가 발생했습니다.')
                    }).finally(()=>{
                      deactivateLoading();
                    })
                  }
                }}
              >
                즉시 반영
              </Button>  
              
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default PostLevelCard;
