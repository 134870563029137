import * as api from '../api';

export function getSchema() {
    return api.fetchData('get', '/bug/schema')
    .then((datas) => {
        return Promise.resolve(datas)
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function filterBugs(query) {
    return api.fetchData('get', '/bug', query)
    .then((datas) => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function getBug(id) {
    return api.fetchData('get','/bug', {rKey:id})
    .then(datas => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export function updateBug(id, json) {
    return api.fetchData('put', '/bug', {id:id}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}