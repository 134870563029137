import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Chip,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  withStyles,
} from '@material-ui/core';

import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import CustomTextField from '../../../../../components/CustomTextField';
import ActionCreators from '../../../../action';
import { useState } from 'react';
import CustomSelect from '../../../../../components/CustomSelect';
import { FileCopy } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

const AdInfo = (props) => {
  const { adsWithDetails, setAdsWithDetails, classes } = props;

  const [newRequiredKeyword, setNewRequiredKeyword] = useState('');
  const [newOptionalKeyword, setNewOptionalKeyword] = useState('');

  const deliveryMethods = [
    { value: 'DIRECT_SHIPPING', name: 'DIRECT_SHIPPING' },
    { value: 'REIMBURSEMENT', name: 'REIMBURSEMENT' },
  ];

  const requiredKeywords = _.isEmpty(adsWithDetails.ad.requiredKeywords)
    ? ''
    : adsWithDetails.ad.requiredKeywords.map((item, idx) => {
        return (
          <Chip
            key={item}
            label={item}
            className={classes.chip}
            onDelete={() => {
              const clone = [...adsWithDetails.ad.requiredKeywords].filter(
                (_, index) => index !== idx
              );

              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  requiredKeywords: clone,
                },
              });
            }}
          />
        );
      });

  const optionalKeywords = _.isEmpty(adsWithDetails.ad.optionalKeywords)
    ? ''
    : adsWithDetails.ad.optionalKeywords.map((item, idx) => {
        return (
          <Chip
            key={item}
            label={item}
            className={classes.chip}
            onDelete={() => {
              const clone = [...adsWithDetails.ad.optionalKeywords].filter(
                (_, index) => index !== idx
              );

              setAdsWithDetails({
                ...adsWithDetails,
                ad: {
                  ...adsWithDetails.ad,
                  optionalKeywords: clone,
                },
              });
            }}
          />
        );
      });

  const InfoDetails = (
    <>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'abstract'}
          value={adsWithDetails.ad.abstract}
          layoutStyle={{ flex: 1 }}
          multiline={true}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                abstract: event.target.value,
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'introduction'}
          value={adsWithDetails.ad.introduction}
          layoutStyle={{ flex: 1 }}
          multiline={true}
          rows={5}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                introduction: event.target.value,
              },
            });
          }}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'instaOfficial'}
          value={adsWithDetails.ad.instaOfficial}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                instaOfficial: event.target.value,
              },
            });
          }}
        />
        <CustomTextField
          label={'tiktokOfficial'}
          value={adsWithDetails.ad.tiktokOfficial}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                tiktokOfficial: event.target.value,
              },
            });
          }}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomSelect
          menus={deliveryMethods}
          label={'deliveryMethods'}
          value={adsWithDetails.ad.deliveryMethod}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                deliveryMethod: event.target.value,
              },
            });
          }}
        />
      </div>
      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          showModifiedStatus={false}
          label={'requiredKeywords'}
          value={newRequiredKeyword}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => setNewRequiredKeyword(event.target.value)}
          rightIcon={<AddIcon />}
          rightIconButtonHandler={() => {
            const clone = [...adsWithDetails.ad.requiredKeywords];
            clone.push(newRequiredKeyword);
            setNewRequiredKeyword('');

            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                requiredKeywords: clone,
              },
            });
          }}
          fullWidth
        />
      </div>
      <div className={classes.root}>{requiredKeywords}</div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          showModifiedStatus={false}
          label={'optionalKeywords'}
          value={newOptionalKeyword}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => setNewOptionalKeyword(event.target.value)}
          rightIcon={<AddIcon />}
          rightIconButtonHandler={() => {
            const clone = [...adsWithDetails.ad.optionalKeywords];
            clone.push(newOptionalKeyword);
            setNewOptionalKeyword('');

            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                optionalKeywords: clone,
              },
            });
          }}
          fullWidth
        />
      </div>
      <div className={classes.root}>{optionalKeywords}</div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'purchaseLink'}
          value={adsWithDetails.ad.purchaseLink}
          layoutStyle={{ flex: 1 }}
          rightIcon={<FileCopy />}
          rightIconButtonHandler={() => {
            // NOTE: 기존에 비활성화 된 이유를 찾지 못했는데, 문제가 생길 시 비활성화
            window.navigator.clipboard.writeText(
              adsWithDetails.ad.purchaseLink
            );
          }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                purchaseLink: event.target.value,
              },
            });
          }}
        />
      </div>
    </>
  );

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>광고정보</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        {InfoDetails}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdInfo));
