import React from 'react';
import Typo from '../../../components/Typo';

const NumberStepper = ({ value, setValue, min, max, disabled }) => {
  const handleOnDecrease = () => {
    setValue(Math.max(value - 1, min));
  };

  const handleOnIncrease = () => {
    setValue(Math.min(value + 1, max));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8, width: 60 }}>
      <button
        style={disabled ? styles.disabled : styles.button}
        onClick={handleOnDecrease}
        disabled={disabled}
      >
        -
      </button>
      <Typo>{value}</Typo>
      <button
        style={disabled ? styles.disabled : styles.button}
        onClick={handleOnIncrease}
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};

const styles = {
  button: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    color: '#757575',
    cursor: 'pointer',
  },
  disabled: {
    width: 20,
    height: 20,
    borderRadius: 4,
    backgroundColor: '#f6f6f6',
    border: '1px solid #e0e0e0',
    color: '#757575',
  },
};

export default NumberStepper;
