import React from 'react';
import { Button } from '@material-ui/core';

const MergeModal = (props) => {
  const { diffList, setDiffList, adsWithDetails, setAdsWithDetails } = props;

  const updateValueAtPath = (obj, path, newValue) => {
    const keys = path.split('.');

    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = newValue; // 마지막 키에 새로운 값을 할당
      } else {
        if (!acc[key]) acc[key] = {}; // 중간 객체가 없으면 생성
      }
      return acc[key];
    }, obj);

    return obj;
  };

  const onButtonHandler = (path, value, index) => {
    const result = updateValueAtPath({ ...adsWithDetails }, path, value);

    const clone = [...diffList].filter((_, i) => i !== index);
    setDiffList(clone);
    setAdsWithDetails(result);
  };

  return (
    <>
      {!!diffList.length && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: 24,
              overflowY: 'scroll',
              borderRadius: 16,
            }}
          >
            <h3>병합 목록</h3>
            {diffList.map((diff, index) => (
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <p>필드명 : {diff.path}</p>
                <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <p>현재 값 : {diff.value1}</p>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        onButtonHandler(diff.path, diff.value1, index);
                      }}
                    >
                      사용하기
                    </Button>
                  </div>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                  >
                    <p>DB 값 : {diff.value2}</p>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        onButtonHandler(diff.path, diff.value2, index);
                      }}
                    >
                      사용하기
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MergeModal;
