import * as types from './types';

const initialAppState = {
    loading:false
}

export default function app(state = initialAppState, action) {
    switch(action.type) {
        case types.ACTIVATE_LOADING:
            return {...state, loading:true}
        case types.DEACTIVATE_LOADING:
            return {...state, loading:false}
        default:
            return {...state}
    }
}