import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';

export default class ArrayEditor extends React.Component {
    state = {
        arr:this.props.field.value === null ? [] : this.props.field.value
    }
    handleChange = (index, value) => {
        let arr = this.state.arr;
        arr[index] = value;
        this.setState({arr:arr})
        this.props.handleChange(arr)
    }
    addChild = () => {
        let arr = this.state.arr || [];
        arr.push('')
        this.setState({arr:arr})
    }
    removeChild = (index) => () => {
        let arr = this.state.arr;
        arr.splice(index, 1);
        this.setState({arr:arr})
    }
    render() {
        const {field} = this.props;
        const {arr} = this.state;
        let fields = (arr || []).map((item, index) => {
            return (
                <div key={index} className="flex-row">
                    <TextField
                        multiline={field.items.multiple ? true : false}
                        rows={3}
                        style={{flex:1}}
                        value={item}
                        onChange={(evt)=>this.handleChange(index, evt.target.value)} />
                    <i 
                        onClick={this.removeChild(index)}
                        className="material-icons">remove</i>
                </div>
            )
        })
        return (
            <div className="row array">
                <FormLabel>{field.name}</FormLabel>
                <i 
                    className="material-icons"
                    onClick={this.addChild}
                    >
                    add
                </i>
                <br/>
                {fields}
            </div>
        )
    }
}