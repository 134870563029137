import React from 'react';
import DetailedImages from './DetailedImages';

export default function IntroductionContainer({ introduction, images }) {
  const exp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
  const regex = new RegExp(exp);

  const iLinks = introduction.match(regex) ? introduction.match(regex) : [];
  let iText = introduction;

  iLinks.forEach((link) => (iText = iText.replace(link, `;${link};`)));
  iText.replace(/;;/gi, ';');
  const introductionText = iText.split(';').map((item, index) => {
    if (item.includes('http')) {
      return <span key={`detailText${index}`}>{item}</span>;
    } else {
      return <span key={`detailText${index}`}>{item}</span>;
    }
  });

  return (
    <>
      <div
        style={{
          margin: '20px 16px',
        }}
      >
        <h3>Product Detail</h3>
        <div>{introductionText}</div>
      </div>
      {images ? (
        <>
          <div style={{ marginBottom: 20 }} />
          <DetailedImages images={images} />
        </>
      ) : null}
    </>
  );
}
