import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import DateField from '../../../components/editor/date';
import {Button} from "@material-ui/core";

const Editor = ({ proposition, handleChange, handleCreatePurchaseLink }) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div className='row'>
            <div style={{ flex: 1 }}>
              <label>status</label>
              <Select
                fullWidth
                value={proposition.status}
                onChange={(evt) => handleChange('status', Number(evt.target.value))}
              >
                <MenuItem value={undefined}>목록</MenuItem>
                <MenuItem value={0}>목록</MenuItem>
                <MenuItem value={1}>제안</MenuItem>
                <MenuItem value={2}> 수락</MenuItem>
                <MenuItem value={3}> 거절</MenuItem>
                <MenuItem value={4}> 무응답</MenuItem>
                <MenuItem value={10}> 제품: 발송대기</MenuItem>
                <MenuItem value={12}> 매장: 미방문</MenuItem>
                <MenuItem value={20}> 이용완료 / 원고대기(매장: 방문완료 / 제품: 발송완료)</MenuItem>
                <MenuItem value={22}> 원고수정요청</MenuItem>
                <MenuItem value={24}> 원고확인중</MenuItem>
                <MenuItem value={30}> 리뷰대기중</MenuItem>
                <MenuItem value={32}> 리뷰완료</MenuItem>
                <MenuItem value={40}> 평가완료</MenuItem>
                <MenuItem value={50}> 이용취소</MenuItem>
              </Select>
            </div>
          </div>
          <label>제안금액 ₩</label>
          <TextField
            fullWidth
            value={proposition.point}
            onChange={(evt) => handleChange('point', evt.target.value)}
          />
          <DateField
            field={{ name: 'proposedAt', value: proposition.proposedAt, format: 'date-time' }}
            handleChange={(value) => handleChange('proposedAt', value)}
          />
          <DateField
            field={{ name: 'responsedAt', value: proposition.responsedAt, format: 'date-time' }}
            handleChange={(value) => handleChange('responsedAt', value)}
          />
          <DateField
            field={{ name: 'usedAt', value: proposition.usedAt, format: 'date-time' }}
            handleChange={(value) => handleChange('usedAt', value)}
          />
          <label>최대 수정 횟수</label>
          <TextField
            fullWidth
            value={proposition.maxEditCount}
            onChange={(evt) => handleChange('maxEditCount', evt.target.value)}
          />
          <div className='row'>
            <div style={{ flex: 1 }}>
              <label>이용 기한</label>
              <TextField
                fullWidth
                value={proposition.periodUse}
                onChange={(evt) => handleChange('periodUse', evt.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>첫 원고 기한</label>
              <TextField
                fullWidth
                value={proposition.periodFirstCopy}
                onChange={(evt) => handleChange('periodFirstCopy', evt.target.value)}
              />
            </div>
          </div>
          <DateField
            field={{ name: '원고 확정일시', value: proposition.manuConfirmedAt, format: 'date-time' }}
            handleChange={(value) => handleChange('manuConfirmedAt', value)}
          />
          <DateField
            field={{ name: '리뷰 등록일시', value: proposition.reviewRegisteredAt, format: 'date-time' }}
            handleChange={(value) => handleChange('reviewRegisteredAt', value)}
          />
          <label>포스트링크</label>
          <TextField fullWidth value={proposition.review} onChange={(evt) => handleChange('review', evt.target.value)} />
          <label>구매링크</label>
          <div className='row'>
            <div style={{flex: 1, minWidth: 0}}>
              <TextField fullWidth value={proposition.purchaseLink} />
            </div>
            <div style={{flex: 1, minWidth: 0}}>
              <Button color='default' disabled={proposition.purchaseLink} onClick={() => handleCreatePurchaseLink()}>
                생성
              </Button>
            </div>
          </div>
          <label>한줄평ID</label>
          <TextField
            fullWidth
            value={proposition.feedback}
            onChange={(evt) => handleChange('feedback', evt.target.value)}
          />
          <label>가맹점 평가</label>
          <TextField
            fullWidth
            type='number'
            value={proposition.rating}
            onChange={(evt) => handleChange('rating', Number(evt.target.value))}
          />
          <DateField
            field={{ name: '가맹점 평가 일시', value: proposition.ratedAt, format: 'date-time' }}
            handleChange={(value) => handleChange('ratedAt', value)}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Editor;
