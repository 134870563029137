import React from "react";
import { bindActionCreators } from "redux";
import momentLib from "moment-timezone";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Link } from "react-router";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import * as api from "./api";
import ActionCreators from "../action";
import { SortableTableHead, SearchInput } from "../../components";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const header = [
  { sortable: false, id: "createdAt", label: "등록일" },
  { sortable: true, id: "additional", label: "추가내용" },
  { sortable: true, id: "company", label: "광고" },
  { sortable: true, id: "processed", label: "처리여부" },
  { sortable: true, id: "feedback", label: "피드백" },
  { sortable: true, id: "user", label: "블로거" },
  { sortable: false, id: "edit", label: "상세보기" },
];

class ReportInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 100,
      orderBy: "createdAt",
      direction: "desc",
      reportinfos: [],
      total: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
  }

  componentDidMount() {
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {
      [selectItem]: findText,
    });
  };

  handleChangePage(event) {
    this.fetchDatas(event.selected, null, null, null);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();
    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      ...options,
    };
    api.filterReportInfos(filter).then((results) => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, reportinfos, total } = this.state;
    const pageCount =
      parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = reportinfos.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            {momentLib(item.createdAt)
              .tz("Asia/Seoul")
              .format("YY-MM-DD HH:mm")}
          </TableCell>
          <TableCell>{item.additional}</TableCell>
          <TableCell>{item.company}</TableCell>
          <TableCell>
            <Checkbox
              checked={item.completed}
              onChange={(event) => {
                api.updateReportInfo(item.id, {
                  completed: event.target.checked,
                });
                reportinfos[index].completed = event.target.checked;
                this.setState({ reportinfos: reportinfos });
              }}
            />
          </TableCell>
          <TableCell>{item.feedback}</TableCell>
          <TableCell>{item.user}</TableCell>
          <TableCell>
            <Link to={`/reportinfo?id=${item.id}`} target="_blank">
              보기
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, order) =>
              this.fetchDatas(null, orderBy, order, null)
            }
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate
            previousLabel={"< 이전"}
            nextLabel={"다음 >"}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportInfos);
