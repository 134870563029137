import * as api from '../api';

// export function getSchema() {
//   return api.fetchData('get', '/user/schema').then((datas) => {
//     return Promise.resolve(datas)
//   }).catch((err) => {
//     return Promise.reject(err)
//   })
// }

export function filterCompanyUsers(query) {
  return api.fetchData('get', '/company/user/console', query).then((datas) => {
    return Promise.resolve(datas);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function getCompanyUser(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user', { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateCompanyUser(id, json) {
  return api
    .fetchData('put', '/company/user', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteCompanyUser(id) {
  return api
    .fetchData('delete', '/company/user', { id: id })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanies3(email) {
  if (email) {
    return api
      .fetchData('get', '/company3', { email: email })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function deleteCompany(id) {
  return api
    .fetchData('delete', '/company3', { id: id })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanyUserToken(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user/token', { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getCompanyUserNotices(email) {
  if (email) {
    return api
      .fetchData('get', '/company/user/notice', { email: email })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getCompanyUserCards(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user/card', { userId: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateCompanyUserCard(userId, card) {
  return api.fetchData('put', '/company/user/card', {userId: userId, ...card}).then(result => {
    return Promise.resolve(result);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function deleteCompanyUserCard(userId, id) {
  return api.fetchData('delete', '/company/user/card', {userId: userId, id: id}).then(result => {
    return Promise.resolve(result);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function getCompanyPayCards(id, channel) {
  if (id) {
    return api
      .fetchData('get', '/company3/paycard', { companyId: id, channel: channel })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getCompanyUserAccounts(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user/account', { userId: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getCompanyUserSettlements(id) {
  return api
    .fetchData('get', '/company/user/settlement', id ? { userId: id } : null)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanyUserPackages(id) {
  return api
    .fetchData('get', '/company/user/package', id ? { companyId: id } : null)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanyUserPremiumPackages(id) {
  return api
    .fetchData('get', '/company/user/premium', id ? { companyId: id } : null)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getCompanyUserSumempoints(id) {
  return api
    .fetchData('get', '/company/user/sumempoint', id ? { companyId: id } : null)
    .then((datas) => {
      return Promise.resolve(datas);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getLog(id) {
  let query = {
    bool: {
      must: [
        {
          terms: {
            baseUrl: ['company']
          }
        },
        {
          multi_match: {
            query: id,
            fields: ["mergeParams.companyId", "mergeParams.id", "mergeParams.userId"]
          }
        }
      ]
    }
  }
  let params = new URLSearchParams({
    query: JSON.stringify(query).replace(/\\n/g, " ")
  });
  return api.fetchData("get", "/log/auto?" + params.toString())
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getUser(id) {
  return api
    .fetchData("get", "/user/console", { uid: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateUser(id, json) {
  return api
    .fetchData("put", "/user/console", { uid: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getMessages(phone) {
  return api.fetchData('get', '/utils/sendsms', {to: phone}).then((datas) => {
    return Promise.resolve(datas);
  }).catch((err) => {
    return Promise.reject(err)
  })
}