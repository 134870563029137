import React from 'react';
import Clipboard from 'clipboard';
import momentLib from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import { Popover } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import MessageIcon from '@material-ui/icons/Message';
import EventNoteIcon from '@material-ui/icons/EventNote';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getUser } from '../../app/api';
import { sendPush, sendEmail, sendSms, getPushs } from '../../app/users/api';
import './user-card.css';
import Typo from '../Typo';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none',
    color: '#fff',
  },
});

class Info extends React.PureComponent {
  componentDidMount() {
    if (this.props.copyable) {
      new Clipboard(this.component);
    }
  }

  render() {
    const { label, value, copyable } = this.props;
    if (copyable === undefined) {
      return (
        <div className='info'>
          <Typo typograph='Body1' fontWeight='600'>
            {label}
          </Typo>
          <Typo typograph='Body1' fontWeight='400'>
            {value}
          </Typo>
        </div>
      );
    } else {
      return (
        <div className='info'>
          <label>{label}</label>
          <Tooltip title='copy' placement='bottom'>
            <span
              style={{ cursor: 'pointer' }}
              data-clipboard-text={value}
              ref={(component) => {
                this.component = component;
              }}
            >
              {value}
            </span>
          </Tooltip>
        </div>
      );
    }
  }
}

class MessageBox extends React.Component {
  static getDerivedStateFromProps(props) {
    return {
      open: props.open,
      pushList: props.pushList,
    };
  }
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      anchorOriginVertical: 'bottom',
      anchorOriginHorizontal: 'center',
      transformOriginVertical: 'top',
      transformOriginHorizontal: 'center',
      positionTop: 200, // Just so the popover can be spotted more easily
      positionLeft: 400, // Same as above
      anchorReference: 'anchorEl',
      title: '',
      content: '',
      tab: 0,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  render() {
    const { classes, anchorEl, onSend } = this.props;

    const {
      open,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
      title,
      content,
      tab,
      pushList,
    } = this.state;

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorReference={anchorReference}
        anchorPosition={{ top: positionTop, left: positionLeft }}
        onClose={() => {
          this.setState({
            open: false,
          });
          this.props.onClose();
        }}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
      >
        {this.props.tab ? (
          <AppBar className='tab' position='static' color='default'>
            <Tabs
              value={tab}
              onChange={this.handleTabChange}
              indicatorColor='primary'
            >
              <Tab value={0} label='작성' icon={<MessageIcon />} />
              <Tab value={1} label='목록' icon={<ListIcon />} />
            </Tabs>
          </AppBar>
        ) : (
          ''
        )}
        {tab === 0 && (
          <form className={classes.container} noValidate autoComplete='off'>
            <FormControl className={classes.formControl}>
              <TextField
                label='Title'
                placeholder={'Title'}
                className={classes.textField}
                margin='normal'
                value={title}
                onChange={this.handleChange('title')}
              />
              <TextField
                label='Content'
                placeholder={'Content'}
                multiline
                rows='10'
                className={classes.textField}
                margin='normal'
                value={content}
                onChange={this.handleChange('content')}
              />
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  onSend(title, content);
                  this.props.onClose();
                }}
              >
                보내기
              </Button>
            </FormControl>
          </form>
        )}
        {tab === 1 && (
          <List component='nav'>
            {pushList
              ? pushList.map((push, idx) => {
                  return (
                    <ListItem key={idx} button>
                      <ListItemIcon>
                        <EventNoteIcon />
                      </ListItemIcon>
                      <ListItemText primary={push.notification.body} />
                    </ListItem>
                  );
                })
              : ''}
          </List>
        )}
      </Popover>
    );
  }
}

class UserCard extends React.Component {
  static getDerivedStateFromProps(props) {
    const { user } = props;
    return {
      ...user,
    };
  }
  state = {
    resigned: false,
    openPush: false,
    anchorPush: null,
    openSMS: false,
    anchorSMS: null,
    openEmail: false,
    anchorEmail: null,
    pushList: [],
  };

  componentDidMount() {
    const { user, uid } = this.props;

    if (uid) {
      getUser(uid)
        .then((user) => {
          this.setState({ ...user, ...this.state });
        })
        .catch((err) => {
          console.log('@err', err);
          if (err.code === 'auth/user-not-found')
            this.setState({ resigned: true });
        });
    }

    if (uid || (user || {}).uid) {
      getPushs(uid || user.uid).then((results) => {
        this.setState({
          pushList: results,
        });
      });
    }
  }

  render() {
    const { classes } = this.props;
    if (Object.keys(this.state).length > 0) {
      let {
        level,
        displayName,
        phone,
        email,
        signupAt,
        type,
        uid,
        token,
        resigned,
        outerId,
      } = this.state;
      email = email && email.indexOf('@') > 0 ? email.split('@')[0] : email;
      return (
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3 className='info'>
              <span className={level}>
                <Typo typograph='Headline3' fontWeight='600'>
                  {(type || '').toUpperCase()} {(level || '').toUpperCase()}{' '}
                  {displayName} {resigned ? '[탈퇴회원]' : ''}
                </Typo>
              </span>
            </h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={resigned ? 'user-card resigned' : 'user-card'}>
              <div className='profile'>
                <div className='section'>
                  <Info label='UID' value={uid} copyable />
                  {type === 'insta' ? (
                    <Info label='인스타' value={outerId} copyable />
                  ) : (
                    <Info label='이메일' value={email} copyable />
                  )}
                  <Info label='연락처' value={phone} copyable />
                  <div className='info'>
                    <label>가입일</label>
                    <span>
                      {momentLib(
                        signupAt
                          ? signupAt.toDate
                            ? signupAt.toDate()
                            : new Date(signupAt)
                          : 0
                      )
                        .tz('Asia/Seoul')
                        .format('YY-MM-DD')}
                    </span>
                  </div>
                </div>
                <div className='section'>
                  <div className='info'>
                    <button
                      onClick={() => {
                        window.open(`/user?uid=${uid}`);
                      }}
                    >
                      <i className='material-icons'>edit</i>
                      <span>편집</span>
                    </button>
                    {outerId ? (
                      <button
                        onClick={() => {
                          if (type === 'insta') {
                            window.open(`https://instagram.com/${outerId}`);
                          } else {
                            window.open(
                              `https://blog.naver.com/${outerId || email}`
                            );
                          }
                        }}
                      >
                        <i className='material-icons'>open_in_new</i>
                        <span>{type === 'insta' ? '인스타' : '블로그'}</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {email ? (
                      <div>
                        <button
                          onClick={(e) => {
                            this.setState({
                              openEmail: true,
                              anchorEmail: e.currentTarget,
                            });
                          }}
                        >
                          <i className='material-icons'>email</i>
                          <span>메일</span>
                        </button>
                        <MessageBox
                          classes={classes}
                          tab={false}
                          open={this.state.openEmail}
                          anchorEl={this.state.anchorEmail}
                          onClose={() => {
                            this.setState({
                              openEmail: false,
                            });
                          }}
                          onSend={(title, body) => {
                            sendEmail({
                              from: 'cs@supermembers.co.kr',
                              to: email,
                              subject: title,
                              text: body,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {phone ? (
                      <div>
                        <button
                          onClick={(e) => {
                            this.setState({
                              openSMS: true,
                              anchorSMS: e.currentTarget,
                            });
                          }}
                        >
                          <i className='material-icons'>textsms</i>
                          <span>문자</span>
                        </button>
                        <MessageBox
                          classes={classes}
                          tab={false}
                          open={this.state.openSMS}
                          anchorEl={this.state.anchorSMS}
                          onClose={() => {
                            this.setState({
                              openSMS: false,
                            });
                          }}
                          onSend={(title, body) => {
                            sendSms({
                              phoneNumber: phone,
                              contents: body,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {token ? (
                      <div>
                        <button
                          onClick={(e) => {
                            this.setState({
                              openPush: true,
                              anchorPush: e.currentTarget,
                            });
                          }}
                        >
                          <i className='material-icons'>notifications</i>
                          <span>노티</span>
                        </button>
                        <MessageBox
                          classes={classes}
                          tab={true}
                          open={this.state.openPush}
                          anchorEl={this.state.anchorPush}
                          onClose={() => {
                            this.setState({
                              openPush: false,
                            });
                          }}
                          pushList={this.state.pushList}
                          onSend={(title, body) => {
                            sendPush({
                              uid: uid,
                              title: title,
                              body: body,
                              sound: 'default',
                            });
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return (
        <div
          className='user-card'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: 250,
          }}
        >
          <CircularProgress size={40} style={{ position: 'relative' }} />
        </div>
      );
    }
  }
}

export default withStyles(styles)(UserCard);
