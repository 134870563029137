import { useEffect, useState } from 'react';
import { getAdsGroupByAdId } from '../api/ads';

const useAdsGroup = (adId) => {
  const [adsGroupInfo, setAdsGroupInfo] = useState({});
  const [adsGroup, setAdsGroup] = useState([]);

  const getAdsGroupList = async () => {
    const response = await getAdsGroupByAdId(adId);

    if (response.data.success) {
      setAdsGroupInfo(response.data.data.adsGroup);
      setAdsGroup([...response.data.data.adsGroupMappings]);
    }
  };

  useEffect(() => {
    getAdsGroupList();
  }, [adId]);

  return { adsGroup, adsGroupInfo };
};

export default useAdsGroup;
