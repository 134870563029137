import * as api from '../api';

export function filterAccounts(query) {
  return api.fetchData('get', '/account', query).then((datas) => {
    return Promise.resolve(datas);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function getAccount(uid) {
  if (uid) {
    return api
      .fetchData('get', '/account', { search: uid })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function deleteAccount(uid) {
  return api
    .fetchData('delete', '/account', { uid: uid })
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function updateAccount(id, json) {
  return api
    .fetchData('put', '/account', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function createAccount(json) {
  return api
    .fetchData('post', '/account', json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}