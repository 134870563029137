import React, {Component} from 'react';

import { bindActionCreators } from 'redux'
import ActionCreators from '../action'
import { connect } from 'react-redux'

import Redash from './redash';

class DashboardIndex extends Component {
    render() {
        return (
            <div className="contents">
                <Redash />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user:state.auth.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardIndex);