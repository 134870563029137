import React from 'react'
import { bindActionCreators } from 'redux'
import ActionCreators from '../action'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import {Editor, UserCard} from '../../components'
import * as api from './api'

class Report extends React.Component {
    state = {
        id:this.props.location.query.id,
        schema:{},
        report:{},
        snackOpen:false
    }
    componentDidMount() {
        this.props.activateLoading()
        api.getSchema().then(schema => {
            api.getReport(this.state.id).then(result => {
                schema = schema.properties;
                let report = result.reports[0];
                let keys = Object.keys(schema);
                for(let i = 0 ; i < keys.length ; i++ ) {
                    schema[keys[i]].name = keys[i];
                    schema[keys[i]].value = (report[keys[i]]===undefined)?null:report[keys[i]];
                }
                this.setState({schema:schema, report:report})
            })
            this.props.deactivateLoading()
        })
    }
    handleCancel = () => {
        this.props.push('/reports');
    }
    handleSave = (json) => {
        this.props.activateLoading();
        const {id} = this.state;
        const keys = Object.keys(json);
        let requestBody = {};
        for(let i = 0 ; i < keys.length ; i ++) {
            requestBody[keys[i]] = json[keys[i]].value; 
        }
        api.updateReport(id, requestBody).then(result => {
            this.setState({schema:json, snackOpen:true})
            this.props.deactivateLoading()
        }).catch(err => {
            console.log(err)
            this.props.deactivateLoading()
            alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        })
    }
    render() {
        const {schema, report} = this.state;
        if(Object.keys(schema).length > 0) {
            return (
                <div className="contents">
                    <div className="editor-container">
                        <Editor 
                            schema={schema}
                            handleCancel={this.handleCancel}
                            handleSave={this.handleSave}
                            />
                    </div>
                    <div className="additional-container">
                        <UserCard uid={report.user}/>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.snackOpen}
                        autoHideDuration={4000}
                        onClose={()=>this.setState({snackOpen:false})}
                        message={<span>업데이트 성공</span>}
                        action={[
                            <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={()=>this.setState({snackOpen:false})}
                            >
                                <Close />
                            </IconButton>,
                        ]}
                    />
                </div>
            )
        } else {
            return <div></div>
        }
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ActionCreators, dispatch)
}
export default connect(null, mapDispatchToProps) (Report)