import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Switch from "@material-ui/core/Switch/Switch";
import momentLib from "moment-timezone";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/AddCircleOutline";
import Remove from "@material-ui/icons/RemoveCircleOutline";

class InfoLimit extends React.Component {
  state = {
    newMission: ''
  }
  render() {
    const { company } = this.props;
    const { newMission } = this.state;

    if (Object.keys(company).length > 0) {
      let details = ['naver', 'insta'].map((channel) => {
        if(company[channel]) {
          let missionElements = '';
          let mission = (company[channel] || {}).mission || [];
          if (!Array.isArray(mission)) {
            mission = [mission];
          }
          missionElements = mission.map((item, idx) => {
            return (
              <div className="row" key={idx}>
                <TextField value={item} onChange={(evt) => {
                  const clone = [...mission];
                  clone[idx] = evt.target.value;

                  this.props.handlePropChange(channel, 'mission', clone);
                }}
                           fullWidth/>
                <IconButton onClick={() => this.handleDeleteMission(channel ,idx)}>
                  <Remove/>
                </IconButton>
              </div>
            );
          });

          return (
            <div style={{width: '100%'}}>
              <h4>{channel === 'naver' ? '네이버' : '인스타'}</h4>
              <section>
                {(company.category.firstName === '제품') ?
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>다이아몬드 할인 전 금액 (원)</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).diamondFullPrice}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'diamondFullPrice', Number(evt.target.value));
                          if ((company[channel] || {}).diamondFullPrice !== undefined && (company[channel] || {}).diamondPayment !== undefined) {
                            this.props.handlePropChange(channel, 'diamondPrice', Number(evt.target.value) - (company[channel] || {}).diamondPayment);
                          }
                        }}

                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>블랙 할인 전 금액 (원)</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).blackFullPrice}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'blackFullPrice', Number(evt.target.value));
                          if ((company[channel] || {}).blackFullPrice !== undefined && (company[channel] || {}).blackPayment !== undefined) {
                            this.props.handlePropChange(channel, 'blackPrice', Number(evt.target.value) - (company[channel] || {}).blackPayment);
                          }
                        }}

                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>레드 할인 전 금액 (원)</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).redFullPrice}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'redFullPrice', Number(evt.target.value));
                          if ((company[channel] || {}).redFullPrice !== undefined && (company[channel] || {}).redPayment !== undefined) {
                            this.props.handlePropChange(channel, 'redPrice', Number(evt.target.value) - (company[channel] || {}).redPayment);
                          }
                        }}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>옐로우 할인 전 금액 (원)</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).yellowFullPrice}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'yellowFullPrice', Number(evt.target.value));
                          if ((company[channel] || {}).yellowFullPrice !== undefined && (company[channel] || {}).yellowPayment !== undefined) {
                            this.props.handlePropChange(channel, 'yellowPrice', Number(evt.target.value) - (company[channel] || {}).yellowPayment);
                          }
                        }}
                      />
                    </div>
                  </div> : ''
                }
                {(company.category.firstName === '제품') ?
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>{company.deliveryMethod !== 'PR' ? '다이아몬드 할인 후 금액 (원)' : '다이아몬드 구매 후 환급금 (원)'}</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).diamondPayment}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'diamondPayment', Number(evt.target.value));
                          if ((company[channel] || {}).diamondFullPrice !== undefined && (company[channel] || {}).diamondPayment !== undefined) {
                            this.props.handlePropChange(channel, 'diamondPrice', (company[channel] || {}).diamondFullPrice - Number(evt.target.value));
                          }
                        }}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>{company.deliveryMethod !== 'PR' ? '블랙 할인 후 금액 (원)' : '블랙 구매 후 환급금 (원)'}</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).blackPayment}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'blackPayment', Number(evt.target.value));
                          if ((company[channel] || {}).blackFullPrice !== undefined && (company[channel] || {}).blackPayment !== undefined) {
                            this.props.handlePropChange(channel, 'blackPrice', (company[channel] || {}).blackFullPrice - Number(evt.target.value));
                          }
                        }}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>{company.deliveryMethod !== 'PR' ? '레드 할인 후 금액 (원)' : '레드 구매 후 환급금 (원)'}</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).redPayment}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'redPayment', Number(evt.target.value));
                          if ((company[channel] || {}).redFullPrice !== undefined && (company[channel] || {}).redPayment !== undefined) {
                            this.props.handlePropChange(channel, 'redPrice', (company[channel] || {}).redFullPrice - Number(evt.target.value));
                          }
                        }}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>{company.deliveryMethod !== 'PR' ? '옐로우 할인 후 금액 (원)' : '옐로우 구매 후 환급금 (원)'}</label>
                      <TextField
                        fullWidth
                        value={(company[channel] || {}).yellowPayment}
                        onChange={(evt) => {
                          this.props.handlePropChange(channel, 'yellowPayment', Number(evt.target.value));
                          if ((company[channel] || {}).yellowFullPrice !== undefined && (company[channel] || {}).yellowPayment !== undefined) {
                            this.props.handlePropChange(channel, 'yellowPrice', (company[channel] || {}).yellowFullPrice - Number(evt.target.value));
                          }
                        }}
                      />
                    </div>
                  </div> : ''
                }
                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '다이아몬드 제공액(원)' : (company.deliveryMethod !== 'PR' ? '다이아몬드 환산금액(원)' : '다이아몬드 할인 후 금액(원)')}</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).diamondPrice}
                      disabled={company.category.firstName === '제품' ? true : false}
                      onChange={(evt) => this.props.handlePropChange(channel, 'diamondPrice', Number(evt.target.value))}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '블랙 제공액(원)' : (company.deliveryMethod !== 'PR' ? '블랙 환산금액(원)' : '블랙 할인 후 금액(원)')}</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).blackPrice}
                      disabled={company.category.firstName === '제품' ? true : false}
                      onChange={(evt) => this.props.handlePropChange(channel, 'blackPrice', Number(evt.target.value))}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '레드 제공액(원)' : (company.deliveryMethod !== 'PR' ? '레드 환산금액(원)' : '레드 할인 후 금액(원)')}</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).redPrice}
                      disabled={company.category.firstName === '제품' ? true : false}
                      onChange={(evt) => this.props.handlePropChange(channel, 'redPrice', Number(evt.target.value))}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '옐로우 제공액(원)' : (company.deliveryMethod !== 'PR' ? '옐로우 환산금액(원)' : '옐로우 할인 후 금액(원)')}</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).yellowPrice}
                      disabled={company.category.firstName === '제품' ? true : false}
                      onChange={(evt) => this.props.handlePropChange(channel, 'yellowPrice', Number(evt.target.value))}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '화이트 제공액(원)' : (company.deliveryMethod !== 'PR' ? '화이트 환산금액(원)' : '화이트 할인 후 금액(원)')}</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).whitePrice}
                      disabled={company.category.firstName === '제품' ? true : false}
                      onChange={(evt) => this.props.handlePropChange(channel, 'whitePrice', Number(evt.target.value))}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '다이아몬드 할인율(%)' : '다이아몬드 제공혜택'}</label>
                    <TextField
                      fullWidth
                      value={(company[channel] || {}).diamond}
                      onChange={(evt) => this.props.handlePropChange(channel, 'diamond', company.category.firstName === '매장' ? Number(evt.target.value) : evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '블랙 할인율(%)' : '블랙 제공혜택'}</label>
                    <TextField
                      fullWidth
                      value={(company[channel] || {}).black}
                      onChange={(evt) => this.props.handlePropChange(channel, 'black', company.category.firstName === '매장' ? Number(evt.target.value) : evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '레드 할인율(%)' : '레드 제공혜택'}</label>
                    <TextField
                      fullWidth
                      value={(company[channel] || {}).red}
                      onChange={(evt) => this.props.handlePropChange(channel, 'red', company.category.firstName === '매장' ? Number(evt.target.value) : evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '옐로우 할인율(%)' : '옐로우 제공혜택'}</label>
                    <TextField
                      fullWidth
                      value={(company[channel] || {}).yellow}
                      onChange={(evt) => this.props.handlePropChange(channel, 'yellow', company.category.firstName === '매장' ? Number(evt.target.value) : evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>{company.category.firstName === '매장' ? '화이트 할인율(%)' : '화이트 제공혜택'}</label>
                    <TextField
                      fullWidth
                      value={(company[channel] || {}).white}
                      onChange={(evt) => this.props.handlePropChange(channel, 'white', company.category.firstName === '매장' ? Number(evt.target.value) : evt.target.value)}
                    />
                  </div>
                </div>

                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>재이용 기한 (일)</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={(company[channel] || {}).revisitPeriod}
                      onChange={(evt) => this.props.handlePropChange(channel, 'revisitPeriod', evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>포스팅 등록 기한 (일)</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={company.deadline}
                      onChange={(evt) => this.props.handleChange('deadline', evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>화이트 등급</label>
                    <Switch
                      checked={(company[channel] || {}).enableWhite}
                      onChange={(evt, checked) => this.props.handlePropChange(channel, 'enableWhite', checked)}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>프리미엄 신청 기간</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={company.periodApply}
                      onChange={(evt) => this.props.handleChange('periodApply', evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    <label>프리미엄 선정 기간</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={company.periodAccept}
                      onChange={(evt) => this.props.handleChange('periodAccept', evt.target.value)}
                    />
                  </div>
                  <div style={{flex: 1, minWidth: 0}}>
                    {/* <label>프리미엄 리뷰 기간</label>
                    <TextField
                      fullWidth
                      type='number'
                      value={company.periodReview}
                      onChange={(evt) => this.props.handleChange('periodReview', evt.target.value)}
                    /> */}
                  </div>
                </div>
                <div>
                  <label>필수 미션</label>
                  {missionElements}
                  <div className="row">
                    <TextField value={newMission} onChange={(evt) => this.setState({ newMission: evt.target.value })} fullWidth />
                    <IconButton onClick={() => this.handleAddMission(channel)}>
                      <Add />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <label>{company.category.firstName === '매장' ? '이용제한 내용' : '품목제한 내용'}</label>
                  <TextField
                    fullWidth
                    value={(company[channel] || {}).limitation}
                    onChange={(evt) => this.props.handlePropChange(channel, 'limitation', evt.target.value)}
                  />
                </div>
                {company.type === 'A' && <>
                  <label>원고료</label>
                  <TextField
                    fullWidth
                    type='number'
                    value={company.diamondPoint}
                    onChange={(evt) => {
                      this.props.updateCompanyData({
                        diamondPoint: Number(evt.target.value),
                        blackPoint: Number(evt.target.value),
                        redPoint: Number(evt.target.value),
                      })
                    }}
                  />
                </>}
              </section>
            </div>
          )
        }
        else {
          return(<div/>)
        }
      });

      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>
              제공혜택 및 이용제한 <span style={{fontWeight: 300}}>정보</span>
            </h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{display: 'block'}}>
            {details}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  handleDeleteMission = (channel, idx) => {
    const { company, handlePropChange } = this.props;
    const mission = (company[channel] || {}).mission || [];

    mission.splice(idx, 1);

    handlePropChange(channel, 'mission', mission);
  };

  handleAddMission = (channel) => {
    const { company } = this.props;
    const { newMission } = this.state;

    const mission = (company[channel] || {}).mission || [];

    if (newMission.length === 0) {
      return;
    }
    mission.push(newMission);
    this.setState({ newMission: ''});

    this.props.handlePropChange(channel, 'mission', mission);
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoLimit);
