import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import _ from 'lodash';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AWS from "aws-sdk";

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

class AdsInfo extends React.PureComponent {
  state = {
    newRequiredKeywords: '',
    newKeyword: '',
    newHashtag: '',
    limitation: (this.props.company.unavailable || '').length > 0,
    file: null
  };

  render() {
    const { company, classes } = this.props;

    const requiredKeywords = _.isEmpty(company.requiredKeywords)
      ? ''
      : company.requiredKeywords.map((item, idx) => {
          return (
            <Chip
              key={item}
              label={item}
              className={classes.chip}
              onDelete={() => this.handleDeleteRequiredKeyword(idx)}
            />
          );
        });

    const keywords = _.isEmpty(company.keywords)
      ? ''
      : company.keywords.map((item, idx) => {
          return (
            <Chip
              key={item}
              label={item}
              className={classes.chip}
              onDelete={() => this.handleDeleteKeyword(idx)}
            />
          );
        });
    const hashtags = _.isEmpty(company.hashtags)
      ? ''
      : company.hashtags.map((item, idx) => {
          return (
            <Chip
              key={item}
              label={item}
              className={classes.chip}
              onDelete={() => this.handleDeleteHashtag(idx)}
            />
          );
        });
    if (Object.keys(company).length > 0) {
      const requirementLabels = {
        text: '텍스트 리뷰',
        photo: '포토 리뷰',
        video: '동영상 리뷰',
        zzimStore: '스토어 찜(스마트스토어)',
        zzimProduct: '상품찜(스마트스토어)',
      };
      company.requirement = company.requirement === undefined ? [] : company.requirement;
      const requirementMenus = Object.keys(requirementLabels).map((key) => {
        return (
          <MenuItem key={key} value={key}>
            <Checkbox checked={(company.requirement || []).includes(key)} />
            <ListItemText primary={requirementLabels[key]} />
          </MenuItem>
        );
      });
      const deliveryLabels = {
        address: '주소',
        name: '실명',
        id: '자사몰 아이디(일반 아이디 형식)',
        email: '자사몰 아이디(이메일 형식)',
        contact: '연락처',
      };
      company.delivery = company.delivery === undefined ? [] : company.delivery;
      const deliveryMenus = Object.keys(deliveryLabels).map((key) => {
        return (
          <MenuItem key={key} value={key}>
            <Checkbox checked={(company.delivery || []).includes(key)} />
            <ListItemText primary={deliveryLabels[key]} />
          </MenuItem>
        );
      });
      const bottomContents =
        company.category.firstName === '제품' ? (
          <div>
            {/*제품*/}
            <label>제품 배송 방식</label>
            <Select
              fullWidth
              value={company.deliveryMethod ? company.deliveryMethod : ''}
              onChange={(evt) =>
                this.props.handleChange('deliveryMethod', evt.target.value)
              }
            >
              <MenuItem value='C'>자사몰에서 쿠폰/적립금 지급</MenuItem>
              <MenuItem value='A'>회원 주소로 직접 배송</MenuItem>
              <MenuItem value='PR'>구매 후 환급금</MenuItem>
            </Select>
            <label>필수 요구사항</label>
            <Select
              multiple
              fullWidth
              value={company.requirement ? company.requirement : []}
              renderValue={(selected) => {
                if (!Array.isArray(selected)) {
                  selected = [selected];
                }
                return selected.map((key) => requirementLabels[key]).join(', ')
              }}
              onChange={(evt) => this.handleRequirementChange(evt.target.value)}
            >
              {requirementMenus}
            </Select>
            <label>쇼핑몰리뷰 공정위 문구 필수 삽입 여부</label>
            <Switch
              checked={company.ftcRequired}
              onClick={() =>
                this.props.handleChange(
                  'ftcRequired',
                  !company.ftcRequired
                )
              }
            />
            <label>쇼핑몰리뷰 공정위 문구</label>
            <TextField
              fullWidth
              value={company.ftcText}
              onChange={(evt) =>
                this.props.handleChange('ftcText', evt.target.value)
              }
            />
            <div className='row'>
              <div style={{flex: 1}}>
                <label>공정위 배너</label>
                <TextField
                  fullWidth
                  value={company.bannerImg}
                  onChange={(evt) => this.props.handleChange('bannerImg', evt.target.value)}
                />
              </div>
              <div style={{flex: 1}}>
                <label>이미지 업로드</label>
                <div>
                  <input
                    accept="image/*"
                    onChange={this.handleLoad}
                    type="file"
                    style={{display: 'inline'}}
                  />
                  <IconButton component="span" onClick={this.handleUpload}>
                    <CloudUploadIcon/>
                  </IconButton>
                </div>
              </div>
            </div>
            <label>회원 정보</label>
            <Select
              multiple
              fullWidth
              value={company.delivery ? company.delivery : []}
              renderValue={(selected) =>
                selected.map((key) => deliveryLabels[key]).join(', ')
              }
              onChange={(evt) => this.handleDeliveryChange(evt.target.value)}
            >
              {deliveryMenus}
            </Select>
            <label>추가 질문 텍스트</label>
            <TextField
              fullWidth
              value={company.additionalInfo}
              onChange={(evt) =>
                this.props.handleChange('additionalInfo', evt.target.value)
              }
            />
            <label>제품 발송 요일</label>
            <TextField
              fullWidth
              value={company.shipping}
              onChange={(evt) =>
                this.props.handleChange('shipping', evt.target.value)
              }
            />
            <label>쇼핑몰 링크</label>
            <div className='label-description'>회원이 제품을 구매할 쇼핑몰 페이지 주소를 입력해주세요.</div>
            <TextField
              fullWidth
              value={company.registerLink}
              onChange={(evt) =>
                this.props.handleChange('registerLink', evt.target.value)
              }
            />
            <label>가입시 주의사항</label>
            <TextField
              fullWidth
              value={company.registerAlert}
              onChange={(evt) =>
                this.props.handleChange('registerAlert', evt.target.value)
              }
            />
            <label>모바일앱 이용안내 페이지</label>
            <Button
              variant='contained'
              onClick={() => window.open('company3-manual?id=' + company.id)}
            >
              수정
            </Button>
            {company.insta ? <label>인스타 필수미션</label> : ''}
            {company.insta ? (
              <TextField
                multiline
                fullWidth
                value={company.insta.mission}
                onChange={(evt) =>
                  this.props.handlePropChange(
                    'insta',
                    'mission',
                    evt.target.value
                  )
                }
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          <div>
            {/*매장*/}
            <label>예약 필수 여부</label>
            <Switch
              checked={company.isReservationRequired}
              onClick={() =>
                this.props.handleChange(
                  'isReservationRequired',
                  !company.isReservationRequired
                )
              }
            />
            <label>예약 안내</label>
            <TextField
              fullWidth
              value={company.reservationComment}
              onChange={(evt) =>
                this.props.handleChange('reservationComment', evt.target.value)
              }
            />
            <label>시간 제한 여부</label>
            <Switch
              checked={this.state.limitation}
              onChange={(event, checked) => {
                this.setState({limitation: checked});
                if (!checked) {
                  this.props.handleChange('unavailable', '');
                }
              }}
            />
            {this.state.limitation ? (
              <label>시간 제한(방문 불가 요일/시간)</label>
            ) : (
              ''
            )}
            {this.state.limitation ? (
              <TextField
                fullWidth
                value={company.unavailable}
                onChange={(evt) =>
                  this.props.handleChange('unavailable', evt.target.value)
                }
              />
            ) : (
              ''
            )}
            {company.insta ? <label>인스타 필수미션</label> : ''}
            {company.insta ? (
              <TextField
                multiline
                fullWidth
                value={company.insta.mission}
                onChange={(evt) =>
                  this.props.handlePropChange(
                    'insta',
                    'mission',
                    evt.target.value
                  )
                }
              />
            ) : (
              ''
            )}
            <label>영수증 리뷰 필수 여부</label>
            <Switch
              checked={company.receiptReviewRequired}
              onClick={() =>
                this.props.handleChange(
                  'receiptReviewRequired',
                  !company.receiptReviewRequired
                )
              }
            />
            <label>공정위 문구 필수 여부</label>
            <Switch
              checked={company.ftcRequired}
              onClick={() =>
                this.props.handleChange('ftcRequired', !company.ftcRequired)
              }
            />
            <label>공정위 문구</label>
            <TextField
              fullWidth
              value={company.ftcText}
              onChange={(evt) =>
                this.props.handleChange('ftcText', evt.target.value)
              }
            />
            <div className='row'>
              <div style={{flex: 1}}>
                <label>공정위 배너</label>
                <TextField
                  fullWidth
                  value={company.bannerImg}
                  onChange={(evt) => this.props.handleChange('bannerImg', evt.target.value)}
                />
              </div>
              <div style={{flex: 1}}>
                <label>이미지 업로드</label>
                <div>
                  <input
                    accept="image/*"
                    onChange={this.handleLoad}
                    type="file"
                    style={{display: 'inline'}}
                  />
                  <IconButton component="span" onClick={this.handleUpload}>
                    <CloudUploadIcon/>
                  </IconButton>
                </div>
              </div>
            </div>
            <label>네이버 플레이스 링크</label>
            <TextField
              fullWidth
              value={company.naverPlaceUrl}
              onChange={(evt) =>
                this.props.handleChange('naverPlaceUrl', evt.target.value)
              }
            />
          </div>
        );
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <h3>광고정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
              <label>한줄소개</label>
              <TextField
                fullWidth
                value={company.abstraction}
                onChange={(evt) =>
                  this.props.handleChange('abstraction', evt.target.value)
                }
              />
              <label>상세소개</label>
              <TextField
                multiline
                fullWidth
                value={company.introduction}
                onChange={(evt) =>
                  this.props.handleChange('introduction', evt.target.value)
                }
              />
              <div>
                <label>필수 키워드</label>
                <div className='row'>
                  <TextField
                    fullWidth
                    value={this.state.newRequiredKeyword}
                    onChange={(evt) =>
                      this.setState({ newRequiredKeyword: evt.target.value })
                    }
                  />
                  <IconButton onClick={this.handleAddRequiredKeyword}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div className={classes.root}>{requiredKeywords}</div>
              </div>
              <div>
                <label>추천 키워드</label>
                <div className='row'>
                  <TextField
                    fullWidth
                    value={this.state.newKeyword}
                    onChange={(evt) =>
                      this.setState({ newKeyword: evt.target.value })
                    }
                  />
                  <IconButton onClick={this.handleAddKeyword}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div className={classes.root}>{keywords}</div>
              </div>
              <div>
                <label>해시태그</label>
                <div className='row'>
                  <TextField
                    fullWidth
                    value={this.state.newHashtag}
                    onChange={(evt) =>
                      this.setState({ newHashtag: evt.target.value })
                    }
                  />
                  <IconButton onClick={this.handleAddHashtag}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div className={classes.root}>{hashtags}</div>
              </div>
              <label>인스타그램 계정</label>
              <TextField
                fullWidth
                value={company.instaOfficial}
                onChange={(evt) =>
                  this.props.handleChange('instaOfficial', evt.target.value)
                }
              />
              {bottomContents}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  handleLoad = (event) => {
    this.setState({ file: event.target.files[0]});
  };
  handleUpload = async () => {
    if (this.state.file) {
      AWS.config.update({
        region: "ap-northeast-2",
        accessKeyId: "AKIAJOTDRH27VFET7VIA",
        secretAccessKey: "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"
      });

      const s3 = new AWS.S3();
      const params = {
        Bucket: "supermembers.app",
        Key: 'frontend/ads/' + this.props.company.id + '/' + this.state.file.name,
        Body: this.state.file,
        ACL: "public-read"
      };

      try {
        await s3.upload(params).promise();
        this.props.handleChange('bannerImg', this.state.file.name);
        alert("업로드 성공!");
      } catch (error) {
        alert("업로드 중 오류 발생:", error.message);
      }
    }
  }

  handleAddRequiredKeyword = async () => {
    const { newRequiredKeyword } = this.state;
    console.log(newRequiredKeyword);
    if (newRequiredKeyword.length > 0) {
      let newArr = this.props.company.requiredKeywords
        ? [...this.props.company.requiredKeywords]
        : [];
      if (newArr.length < 3) {
        const difference = 3 - newArr.length;
        const temp = newRequiredKeyword
          .split(',')
          .map((k) => k.trim())
          .slice(0, difference);

        newArr = [...newArr, ...temp];

        console.log(newArr);

        await this.props.handleChange('requiredKeywords', newArr);
        // this.setState({ newKeyword: '' });
        return new Promise((resolve) => {
          this.setState({ newRequiredKeyword: '' }, () => resolve());
        });
      }
    }
  };

  handleDeleteRequiredKeyword = (idx) => {
    const newArr = this.props.company.requiredKeywords
      ? [...this.props.company.requiredKeywords]
      : [];
    if (newArr.length > 0) {
      newArr.splice(idx, 1);
      this.props.handleChange('requiredKeywords', newArr);
    }
  };

  handleAddKeyword = async () => {
    const { newKeyword } = this.state;
    if (newKeyword.length > 0) {
      const newArr = this.props.company.keywords
        ? [...this.props.company.keywords]
        : [];
      newKeyword.split(',').forEach((keyword) => {
        newArr.push(keyword.trim());
      });
      await this.props.handleChange('keywords', newArr);
      // this.setState({ newKeyword: '' });
      return new Promise((resolve) => {
        this.setState({ newKeyword: '' }, () => resolve());
      });
    }
  };
  handleDeleteKeyword = (idx) => {
    const newArr = this.props.company.keywords
      ? [...this.props.company.keywords]
      : [];
    if (newArr.length > 0) {
      newArr.splice(idx, 1);
      this.props.handleChange('keywords', newArr);
    }
  };
  handleAddHashtag = async () => {
    const { newHashtag } = this.state;
    if (newHashtag.length > 0) {
      const newArr = this.props.company.hashtags
        ? [...this.props.company.hashtags]
        : [];
      newHashtag.split(',').forEach((hashtag) => {
        newArr.push(hashtag.trim());
      });
      await this.props.handleChange('hashtags', newArr);
      // this.setState({ newHashtag: '' });
      return new Promise((resolve) => {
        this.setState({ newHashtag: '' }, () => resolve());
      });
    }
  };
  handleDeleteHashtag = (idx) => {
    const newArr = this.props.company.hashtags
      ? [...this.props.company.hashtags]
      : [];
    console.log(newArr);
    if (newArr.length > 0) {
      newArr.splice(idx, 1);
      this.props.handleChange('hashtags', newArr);
    }
  };

  handleRequirementChange = (keyArr) => {
    this.props.handleChange('requirement', keyArr);
  };

  handleDeliveryChange = (keyArr) => {
    this.props.handleChange('delivery', keyArr);
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(AdsInfo));
