import axios from 'axios';

/**
 * companies 목록 요청
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @param {number} query.uid ?uid
 * @param {string} query.companyName ?companyName
 * @param {string} query.contact ?contact
 * @param {string} query.email ?email
 * @param {string} query.registrationNumber ?registrationNumber
 * @returns {companies[]} companies[]
 */

export const getCompanies = (query = {}) => {
  const payloadString = Object.entries(query)
    .map((e) => e.join('='))
    .join('&');

  let baseURL =
    `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/companies`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const getCompany = (id) => {
  return axios.get(
    `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/company/${id}`
  );
};
