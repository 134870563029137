import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import CustomTextField from '../../../../../components/CustomTextField';

const HandbookTable = (props) => {
  const [inputs, setInputs] = useState({
    title: '',
    content: '',
  });

  return (
    <ExpansionPanel expanded={true} style={{ flex: 1 }}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <h2>{props.title}</h2>
          <div
            style={{
              maxHeight: 400,
              overflow: 'scroll',
            }}
          >
            <Table>
              <TableHead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                }}
              >
                <TableRow>
                  <TableCell>title</TableCell>
                  <TableCell>content</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.list.map((handbook, index) => (
                  <TableRow>
                    <TableCell>{handbook.title}</TableCell>
                    <TableCell>{handbook.content}</TableCell>
                    <TableCell>
                      <button
                        onClick={() => {
                          props.onRemoveHandler(handbook.id);
                        }}
                      >
                        X
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          <div style={{ display: 'flex', gap: 16, alignItems: 'flex-end' }}>
            <CustomTextField
              label='title'
              multiline
              layoutStyle={{ flex: 1 }}
              value={inputs.title}
              showModifiedStatus={false}
              onChange={(event) => {
                setInputs({ ...inputs, title: event.target.value });
              }}
            />
            <CustomTextField
              label='content'
              multiline
              layoutStyle={{ flex: 1 }}
              value={inputs.content}
              showModifiedStatus={false}
              onChange={(event) => {
                setInputs({ ...inputs, content: event.target.value });
              }}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                props.onAddHandler(inputs);
                setInputs({
                  title: '',
                  content: '',
                });
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default HandbookTable;
