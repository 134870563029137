import * as api from '../api';

export function filterUserPay(query) {
  return api.fetchData('get', '/company/user/settlement', query).then((datas) => {
    return Promise.resolve(datas);
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export function updateSettlement(id, json) {
  return api
    .fetchData('put', '/company/user/settlement', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function deleteSettlement(id) {
  return api.fetchData('delete', '/company/user/settlement', {id:id})
    .then(result => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export function getCompanies3(id) {
  if (id) {
    return api
      .fetchData('get', '/company3', { id: id })
      .then((datas) => {
        return Promise.resolve(datas);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateCompany(id, json) {
  return api
    .fetchData('put', '/company3', { id: id }, json)
    .then((result) => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getContract(id) {
  return api.fetchData('get','/contract/console', {rKey:id})
    .then(datas => {
      return Promise.resolve(datas);
    }).catch((err) => {
      return Promise.reject(err)
    })
}

export function updateContract(id, json) {
  return api.fetchData('put', '/contract/console', {id:id}, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch((err) => {
      return Promise.reject(err)
    })
}

export function deleteInicis(id, tid, mid) {
  if (id) {
    return api.fetchData('delete', '/inicis', {id: id})
  }
  else {
    return api.fetchData('delete', '/inicis', {tid: tid, mid: mid, msg: '', cancelreason: ''})
  }
}