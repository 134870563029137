import React, { useState, useEffect, useMemo } from "react";

import Select from "@material-ui/core/Select/Select";
import Button from "@material-ui/core/Button/Button";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import _ from "lodash";
import momentLib from "moment-timezone";

import * as apiV2 from "../../api-v2";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/AddCircleOutline";
import Remove from "@material-ui/icons/RemoveCircleOutline";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  StyledTextField,
  StyledPaper,
  FormSection,
  FormLabel,
  FormLabelWithTooltip,
  DetailPaper,
} from "./StyledComponents";
import PaymentReceipt from "./Receipt";

const PaymentAdsOrderDetails = ({
  ad,
  channel,
  paymentCards,
  adPayments,
  adCategory,
  onUpdate,
  onExpose,
  setLoading,
  setOpenSnackbar,
  setSnackbarMessage,
}) => {
  const [newMemo, setNewMemo] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null); // 새로운 상태 추가
  const [adPaymentForm, setAdPaymentForm] = useState({});
  const [formInitialValues, setFormInitialValues] = useState({});
  const [formChanges, setFormChanges] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [openFormSaveDialog, setOpenFormSaveDialog] = useState(false);

  const isValidAdsStart = useMemo(() => {
    return !!(
      adPaymentForm.adsStart &&
      /\d{4}-\d{2}-\d{2}/.test(adPaymentForm.adsStart) &&
      momentLib(adPaymentForm.adsStart).isValid()
    );
  }, [adPaymentForm.adsStart]);

  const isValidNextPaidAt = useMemo(() => {
    return !!(
      adPaymentForm.isValidNextPaidAt &&
      /\d{4}-\d{2}-\d{2}/.test(adPaymentForm.isValidNextPaidAt) &&
      momentLib(adPaymentForm.isValidNextPaidAt).isValid()
    );
  }, [adPaymentForm.nextPaidAt]);

  // NOTE: 광고 진행일수
  const adsDuration = useMemo(() => {
    if (isValidAdsStart) {
      let diff;
      const adsStart = momentLib(adPaymentForm.adsStart).tz("Asia/Seoul");
      if (isValidNextPaidAt) {
        const nextPaidAt = momentLib(adPaymentForm.nextPaidAt).tz("Asia/Seoul");
        diff = nextPaidAt.diff(adsStart, "days") + 1;
      } else {
        const now = momentLib().tz("Asia/Seoul").startOf("day");
        diff = now.diff(adsStart, "days") + 1;
      }
      if (diff <= 0) return "-";
      else return diff;
    } else {
      return "-";
    }
  }, [isValidAdsStart, isValidNextPaidAt]);

  const getChangedFieldLabels = () => {
    const labels = [];

    for (const fieldName in formChanges) {
      let labelName = fieldName;
      if (fieldName === "memo") labelName = "메모";

      const original =
        fieldName === "memo"
          ? JSON.stringify(formInitialValues[fieldName])
          : formInitialValues[fieldName];
      const changed =
        fieldName === "memo"
          ? JSON.stringify(formChanges[fieldName])
          : formChanges[fieldName];

      if (labelName) {
        labels.push(
          `<span style="font-weight:bold">${labelName}:</span> <span style="color: #9B111E">${original}</span> → <span style="color: #006400">${changed}</span>`
        );
      }
    }

    return labels.join("\n");
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 메서드들을 부모에게 전달
    if (onExpose) {
      onExpose((prev) => ({
        ...prev,
        setSelectedOrder,
      }));
    }
  }, [setSelectedOrder, onExpose]);

  useEffect(() => {
    if (!isLoaded && ad && channel && adPayments && paymentCards) {
      // NOTE: props 초기 로딩 완료시
      const initialOrder =
        adPayments.length === 0
          ? null
          : !channel.currentAdsOrder
          ? adPayments[0].order
          : adPayments.some(
              (adPayment) => adPayment.order === channel.currentAdsOrder
            )
          ? channel.currentAdsOrder
          : adPayments[0].order;
      setSelectedOrder(initialOrder);
      setIsLoaded(true);
    }
  }, [ad, channel, adPayments, paymentCards, isLoaded]);

  useEffect(() => {
    if (selectedOrder) {
      // NOTE: 차수 선택시
      const selectedAdPayment = adPayments.find(
        (payment) => payment.order === selectedOrder
      );
      if (!!selectedAdPayment) {
        setFormInitialValues(JSON.parse(JSON.stringify(selectedAdPayment)));
        setAdPaymentForm(JSON.parse(JSON.stringify(selectedAdPayment)));
        setFormChanges({});
      }
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (adPayments && isLoaded) {
      // NOTE: 로딩된 이후 adPayments가 바뀔경우 (업데이트 성공시)
      if (selectedOrder) {
        const selectedAdPayment = adPayments.find(
          (payment) => payment.order === selectedOrder
        );
        setFormInitialValues(JSON.parse(JSON.stringify(selectedAdPayment)));
        setAdPaymentForm(JSON.parse(JSON.stringify(selectedAdPayment)));
        setFormChanges({});
      }
    }
  }, [adPayments]);

  const handleFormSave = () => {
    if (Object.keys(formChanges).length === 0) return;
    setOpenFormSaveDialog(true);
  };

  const handleConfirmFormSave = async () => {
    setOpenFormSaveDialog(false);
    setLoading(true);

    try {
      const adId = ad.id;
      const baseUpdatedAt = momentLib(
        formInitialValues.updatedAt
      ).toISOString();
      const result = await apiV2.updateAdsPaymentMemo({
        adId,
        adsOrder: selectedOrder,
        baseUpdatedAt,
        changes: formChanges,
      });
      if (result.success) {
        console.log("Successfully updated adPayment information");
        onUpdate({
          ...(!!result.data.adPayment && {
            adPayment: result.data.adPayment,
          }),
        });
        setSnackbarMessage("차수 정보가 성공적으로 저장되었습니다.");
        setOpenSnackbar(true);
      } else {
        console.log("@@ success false", result);
        alert("업데이트 중 오류가 발생했습니다. 개발팀에 문의해주세요.");
      }
    } catch (err) {
      console.log("@@ error", err);
      alert("업데이트 중 오류가 발생했습니다. 개발팀에 문의해주세요.");
    } finally {
      setLoading(false);
    }
  };

  const handleFormReset = async () => {
    if (Object.keys(formChanges).length === 0) return;

    if (window.confirm("변경사항을 모두 초기화하시겠습니까?")) {
      setAdPaymentForm(JSON.parse(JSON.stringify(formInitialValues)));
      setFormChanges({});
    }
  };

  const handleChange = (fieldName, fieldValue, idx) => {
    setAdPaymentForm((prev) => {
      const updated = { ...prev };

      if (idx !== undefined) {
        const arr = [...(updated[fieldName] || [])];
        arr[idx] = fieldValue;
        updated[fieldName] = arr;
      } else {
        updated[fieldName] = fieldValue;
      }

      const initial = formInitialValues[fieldName];
      if (idx !== undefined) {
        const initialArr = initial || [];
        if (!_.isEqual(updated[fieldName], initialArr)) {
          setFormChanges((prev) => ({
            ...prev,
            [fieldName]: updated[fieldName],
          }));
        } else {
          setFormChanges((prev) => {
            const newChanges = {
              ...prev,
            };
            delete newChanges[fieldName];
            return newChanges;
          });
        }
      } else {
        if (!_.isEqual(fieldValue, initial)) {
          setFormChanges((prev) => ({
            ...prev,
            [fieldName]: fieldValue,
          }));
        } else {
          setFormChanges((prev) => {
            const newChanges = {
              ...prev,
            };
            delete newChanges[fieldName];
            return newChanges;
          });
        }
      }

      return updated;
    });
  };

  const handleMemoAdd = () => {
    if (newMemo.length === 0) return;

    setAdPaymentForm((prev) => {
      const prevMemo = prev.memo || [];
      const memo = [...prevMemo, newMemo];
      if (_.isEqual(memo, formInitialValues.memo)) {
        setFormChanges((prev) => {
          const newChanges = {
            ...prev,
          };
          delete newChanges.memo;
          return newChanges;
        });
      } else {
        setFormChanges((prev) => {
          return {
            ...prev,
            memo,
          };
        });
      }
      setNewMemo("");
      return {
        ...prev,
        memo,
      };
    });
  };

  const handleMemoDelete = (idx) => {
    setAdPaymentForm((prev) => {
      const prevMemo = prev.memo || [];
      const memo = [...prevMemo];
      memo.splice(idx, 1);
      if (
        _.isEqual(memo, formInitialValues.memo) ||
        (formInitialValues.memo === null && memo.length === 0)
      ) {
        setFormChanges((prev) => {
          const newChanges = {
            ...prev,
          };
          delete newChanges.memo;
          return newChanges;
        });
      } else {
        setFormChanges((prev) => {
          return {
            ...prev,
            memo,
          };
        });
      }
      return {
        ...prev,
        memo,
      };
    });
  };

  const memoElements = !_.isEmpty(adPaymentForm)
    ? (adPaymentForm.memo || []).map((item, idx) => (
        <Grid item xs={12} key={idx}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <StyledTextField
              variant="outlined"
              value={item}
              onChange={(evt) => handleChange("memo", evt.target.value, idx)}
              fullWidth
            />
            <IconButton onClick={() => handleMemoDelete(idx)}>
              <Remove />
            </IconButton>
          </div>
        </Grid>
      ))
    : "";

  let item = (
    <>
      <StyledPaper>
        <Grid
          container
          spacing={3}
          alignItems="center"
          style={{ marginBottom: "12px" }}
        >
          <Grid item xs={12} md={8}>
            <h2 style={{ margin: 0 }}>차수 정보</h2>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabelWithTooltip title="표시할 차수 선택">
              조회할 차수
            </FormLabelWithTooltip>
            <Select
              variant="outlined"
              fullWidth
              value={selectedOrder || ""}
              onChange={(evt) => setSelectedOrder(parseInt(evt.target.value))}
            >
              {!!adPayments &&
                adPayments.map((adPayment) => (
                  <MenuItem key={adPayment.order} value={adPayment.order}>
                    {adPayment.order}차
                    {adPayment.payState === 0
                      ? "(결제 예정)"
                      : adPayment.payState === 1
                      ? "(결제 완료)"
                      : adPayment.payState === 2
                      ? "(결제 요청)"
                      : adPayment.payState === 3
                      ? "(결제 중지)"
                      : adPayment.payState === 4
                      ? "(광고 재시작)"
                      : ""}
                    {!!channel &&
                    channel.currentAdsOrder === adPayment.order ? (
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "green",
                          fontSize: "0.85em",
                        }}
                      >
                        {" "}
                        - 현재 차수
                      </span>
                    ) : (
                      ""
                    )}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>

        {!_.isEmpty(adPaymentForm) && (
          <DetailPaper>
            <h2 style={{ margin: "0 0 16px 0" }}>{adPaymentForm.order}차</h2>
            <FormSection>
              <h3>결제 관리</h3>
              <Divider style={{ margin: "8px 0" }} />
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <FormLabelWithTooltip title="결제 플랜 기간">
                    결제 플랜
                  </FormLabelWithTooltip>

                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={
                      !!adPaymentForm.plan
                        ? `${adPaymentForm.plan}개월`
                        : adPaymentForm.plan
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormLabelWithTooltip title="결제 수단 선택">
                    결제 수단
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={
                      adPaymentForm.payMethod === "card"
                        ? "카드"
                        : adPaymentForm.payMethod === "cash"
                        ? "현금"
                        : adPaymentForm.payMethod === "free"
                        ? "무료 제공"
                        : adPaymentForm.payMethod
                    }
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormLabelWithTooltip title="결제 금액">
                    결제 금액
                  </FormLabelWithTooltip>
                  <StyledTextField
                    type={"number"}
                    variant="outlined"
                    fullWidth
                    value={
                      adPaymentForm.amount === null ? "" : adPaymentForm.amount
                    }
                    disabled
                  />
                </Grid>
                {/* <Grid item xs={12} md={3}>
                  <FormLabelWithTooltip title="결제 완료된 날짜">
                    결제 완료일
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={
                      !!adPaymentForm.paidAt &&
                      momentLib(adPaymentForm.paidAt).isValid()
                        ? momentLib(adPaymentForm.paidAt)
                            .tz("Asia/Seoul")
                            .format("YYYY-MM-DD")
                        : ""
                    }
                    disabled
                  />
                </Grid> */}

                <Grid item xs={12} md={3}>
                  <FormLabelWithTooltip title="현재 결제 상태">
                    결제 상태
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={
                      adPaymentForm.payState === undefined ||
                      adPaymentForm.payState === null
                        ? "!! 오류 !!"
                        : adPaymentForm.payState === 0
                        ? "결제 예정"
                        : adPaymentForm.payState === 1
                        ? "결제 완료"
                        : adPaymentForm.payState === 2
                        ? "결제 요청"
                        : adPaymentForm.payState === 3
                        ? "결제 중지"
                        : adPaymentForm.payState === 4
                        ? "광고 재시작"
                        : "!! 오류 !!"
                    }
                    disabled
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection>
              <h3>결제 영수증</h3>
              <Divider style={{ margin: "8px 0 16px 0" }} />
              <PaymentReceipt paymentData={adPaymentForm} />
            </FormSection>

            <FormSection>
              <h3>이용 관리</h3>
              <Divider style={{ margin: "8px 0" }} />

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="현재 이용자 수">
                    이용자 수
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={adPaymentForm.contractCount}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="최소 보장인원 설정">
                    최소 보장인원
                  </FormLabelWithTooltip>
                  <StyledTextField
                    type={"number"}
                    variant="outlined"
                    fullWidth
                    value={
                      adPaymentForm.checkBloggerCnt === null
                        ? ""
                        : adPaymentForm.checkBloggerCnt
                    }
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="모집인원 설정">
                    모집인원
                  </FormLabelWithTooltip>
                  <StyledTextField
                    type={"number"}
                    variant="outlined"
                    fullWidth
                    value={
                      adPaymentForm.number === null ? "" : adPaymentForm.number
                    }
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="광고 시작 날짜">
                    광고 시작일
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={adPaymentForm.adsStart}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title={"광고 종료일"}>
                    {"광고 종료일"}
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={adPaymentForm.nextPaidAt}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="광고 진행일수">
                    광고 진행일수
                  </FormLabelWithTooltip>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={adsDuration}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="광고 연장 여부">
                    연장 여부
                  </FormLabelWithTooltip>
                  <Checkbox
                    color="secondary"
                    checked={
                      adPaymentForm.extended || false
                      // ||
                      // momentLib(adPaymentForm.adsStart)
                      //   .add(30, "day")
                      //   .isBefore(momentLib(adPaymentForm.nextPaidAt))
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormLabelWithTooltip title="60일 제한 해제 여부">
                    60일 제한 해제 여부
                  </FormLabelWithTooltip>
                  <Checkbox
                    color="secondary"
                    checked={adPaymentForm.checkPeriod || false}
                    disabled
                  />
                </Grid>
              </Grid>
            </FormSection>
            {ad.type === "A" && (
              <FormSection>
                <h3>프리미엄 체험단 관리</h3>
                <Divider style={{ margin: "8px 0" }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단 신청 마감 기한">
                      체험단 신청 마감
                    </FormLabelWithTooltip>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormLabel>마감기한(일)</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={adPaymentForm.periodApply || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel>마감 예정일</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={
                            adPaymentForm.periodApply && isValidAdsStart
                              ? momentLib(adPaymentForm.adsStart)
                                  .tz("Asia/Seoul")
                                  .add(adPaymentForm.periodApply, "day")
                                  .format("YYYY-MM-DD")
                              : "-"
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단 선정 마감 기한">
                      체험단 선정 마감
                    </FormLabelWithTooltip>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormLabel>마감기한(일)</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={adPaymentForm.periodAccept || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel>마감 예정일</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={
                            adPaymentForm.periodApply &&
                            adPaymentForm.periodAccept &&
                            isValidAdsStart
                              ? momentLib(adPaymentForm.adsStart)
                                  .tz("Asia/Seoul")
                                  .add(
                                    adPaymentForm.periodApply +
                                      adPaymentForm.periodAccept,
                                    "day"
                                  )
                                  .format("YYYY-MM-DD")
                              : "-"
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단 리뷰등록 마감 기한">
                      체험단 리뷰등록 마감
                    </FormLabelWithTooltip>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormLabel>마감기한(일)</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={adPaymentForm.periodReview || ""}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel>마감 예정일</FormLabel>
                        <StyledTextField
                          variant="outlined"
                          fullWidth
                          value={
                            adPaymentForm.periodApply &&
                            adPaymentForm.periodAccept &&
                            adPaymentForm.periodReview &&
                            isValidAdsStart
                              ? momentLib(adPaymentForm.adsStart)
                                  .tz("Asia/Seoul")
                                  .add(
                                    adPaymentForm.periodApply +
                                      adPaymentForm.periodAccept +
                                      adPaymentForm.periodReview,
                                    "day"
                                  )
                                  .format("YYYY-MM-DD")
                              : "-"
                          }
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormLabelWithTooltip title="자동선정 진행 여부 설정">
                      자동선정 진행 여부
                    </FormLabelWithTooltip>
                    <Checkbox
                      color="secondary"
                      checked={adPaymentForm.disableAutoSelection || false}
                      disabled
                    />
                  </Grid>
                </Grid>
              </FormSection>
            )}
            <FormSection>
              <h3>메모</h3>
              <Divider style={{ margin: "8px 0" }} />
              <Grid container spacing={2}>
                {memoElements}
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      value={newMemo}
                      onChange={(evt) => setNewMemo(evt.target.value)}
                      placeholder="새 메모 추가"
                    />
                    <Tooltip
                      title="+버튼을 눌러야 메모가 추가됩니다"
                      open={newMemo.length > 0}
                      arrow
                      placement="top"
                    >
                      <IconButton color="secondary" onClick={handleMemoAdd}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
            </FormSection>

            <div
              className="row"
              style={{
                justifyContent: "flex-end",
                gap: "12px",
                marginTop: "24px",
              }}
            >
              <Button
                variant="outlined"
                color="default"
                onClick={handleFormReset}
                size="large"
                disabled={Object.keys(formChanges).length === 0}
              >
                변경사항 초기화
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleFormSave}
                disabled={Object.keys(formChanges).length === 0}
              >
                저장
              </Button>
            </div>
          </DetailPaper>
        )}
      </StyledPaper>
      <Dialog
        open={openFormSaveDialog}
        onClose={() => setOpenFormSaveDialog(false)}
      >
        <DialogTitle>{"확인"}</DialogTitle>
        <DialogContent>
          <div
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{
              __html: `다음 필드가 변경되었습니다:\n\n${getChangedFieldLabels()}\n\n정말 변경하시겠습니까?`,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFormSaveDialog(false)}>취소</Button>
          <Button
            onClick={handleConfirmFormSave}
            color="secondary"
            variant="contained"
          >
            변경하기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  if (isLoaded) {
    return item;
  } else {
    return <div />;
  }
};

export default PaymentAdsOrderDetails;
