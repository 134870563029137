import React, { useState } from 'react';

const CarouselImage = ({ thumbnails }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === thumbnails.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? thumbnails.length - 1 : prevIndex - 1
    );
  };

  return (
    <div style={styles.container}>
      <button
        onClick={handlePrev}
        style={{
          ...styles.button,
          position: 'absolute',
          left: 16,
        }}
      >
        &lt;
      </button>
      <div
        style={{
          ...styles.slider,
          transform: `translateX(-${currentIndex * 375}px)`,
        }}
      >
        {thumbnails.map((item, index) => (
          <div
            key={index}
            style={{
              ...styles.slide,
            }}
          >
            <img src={item} style={{ width: '100%', height: '327px' }} />
          </div>
        ))}
      </div>
      <button
        onClick={handleNext}
        style={{
          ...styles.button,
          position: 'absolute',
          right: 16,
        }}
      >
        &gt;
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '375px',
    overflow: 'hidden',
    position: 'relative',
    height: '327px',
  },
  slider: {
    display: 'flex',
    // transition: 'transform 0.5s ease-in-out',
  },
  slide: {
    width: '375px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  button: {
    width: '20px',
    height: '20px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    zIndex: 1,
  },
};

export default CarouselImage;
