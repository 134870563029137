import * as api from '../api';

export function getSchema() {
    return api.fetchData('get', '/drawn/schema')
    .then((datas) => {
        return Promise.resolve(datas)
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function filterDrawns(query) {
    return api.fetchData('get', '/drawn', query)
    .then((datas) => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function getDrawn(id) {
    return api.fetchData('get','/drawn', {id:id})
    .then(datas => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export function updateDrawn(id, json) {
    return api.fetchData('put', '/drawn', {id:id}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}