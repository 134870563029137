import React, { PureComponent } from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import momentLib from "moment-timezone";
import {Link} from "react-router";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Receipt from "@material-ui/icons/Receipt";
import {Button, Popover} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as api from '../../package/api';
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  selector: {
    margin: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none',
    color: '#fff',
  },
});

class InfoPackage extends PureComponent {
  state = {
    openPackage: false,
    anchorPackage: null,
  };
  render() {
    const { companyUser, companies, packages, classes } = this.props;

    const items = packages.map((pkg, idx) => {
      if (packages.length > 0) {
        let company = companies.filter((company) => company.id == pkg.adId)[0];
        return (
          <div key={pkg.id} style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 12,
            borderBottom: '1px solid #dbdbdb'
          }}>
            <div>
              <span>{momentLib(pkg.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</span>
            </div>
            <div>
              <span>{pkg.adId ? -pkg.number : pkg.number}명</span>
            </div>
            <div>
              <span>{pkg.balance}명</span>
            </div>
            <div>
              <span>{pkg.name}</span>
            </div>
            <div>
              {!pkg.adId ? <span>{pkg.period}개월</span> : ''}
            </div>
            <div>
              {!pkg.adId ? <span>{pkg.price}원</span> : ''}
            </div>
            <div>
              <Link to={`/package?id=${pkg.id}`} target="_blank">
                <IconButton style={{padding: 0}}>
                  <Receipt/>
                </IconButton>
              </Link>
            </div>
          </div>
        );
      }
    });

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
          <h3>{`${companyUser.name}님의 패키지 내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{width: '100%'}}>
            {items}
          </div>
        </ExpansionPanelDetails>
        <div className='row' style={{justifyContent: 'flex-end'}}>
          <Button color='secondary' onClick={(e) => {
            this.setState({
              openPackage: true,
              anchorPackage: e.currentTarget,
            });
          }}>추가</Button>
          <NewPackageBox
            classes={classes}
            companies={companies}
            open={this.state.openPackage}
            anchorEl={this.state.anchorPackage}
            onClose={() => {
              this.setState({
                openPackage: false,
              });
            }}
            onCreate={(number, period, name, price, adId, notes, chargeType) => {
              let ad = companies.filter((company) => company.id == adId)[0];
              let charge = {
                companyId: companyUser.id,
                amount: ad ? null : Number(price),
                method: chargeType === 0 ? 'cash' : 'free',
                order: companyUser.id + '_package_' + new Date().getTime(),
                number: Number(number) ? Number(number) : (ad ? ad.naver.number : Number(number)),
                period: ad ? null : Number(period),
                name: ad ? ad.name : name,
                adId: adId,
                notes: notes
              }
              api.createPackage(charge).then((result) => {
                window.location.reload();
                console.log(result);
              });
            }}
          />
        </div>
      </ExpansionPanel>
    );
  }
}

class NewPackageBox extends React.Component {
  static getDerivedStateFromProps(props) {
    return {
      open: props.open,
      pushList: props.pushList
    };
  }
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      anchorOriginVertical: 'bottom',
      anchorOriginHorizontal: 'center',
      transformOriginVertical: 'top',
      transformOriginHorizontal: 'center',
      positionTop: 200, // Just so the popover can be spotted more easily
      positionLeft: 400, // Same as above
      anchorReference: 'anchorEl',
      chargeType: 0,
      packageType: 15,
      number: 10,
      period: 1,
      name: '체험형 패키지',
      price: 150000,
      adId: null,
      notes: ''
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, anchorEl, companies, onCreate } = this.props;

    const {
      open, anchorOriginVertical, anchorOriginHorizontal, transformOriginVertical, transformOriginHorizontal,
      positionTop, positionLeft, anchorReference, chargeType, packageType, adId, number, period, name, price, notes
    } = this.state;

    return (
      <Popover open={open} anchorEl={anchorEl} anchorReference={anchorReference}
        anchorPosition={{ top: positionTop, left: positionLeft }}
        onClose={() => {
          this.setState({
            open: false,
          });
          this.props.onClose();
        }}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}>
        {(
          <form className={classes.container} noValidate autoComplete='off'>
            <FormControl className={classes.formControl}>
              <Select className={classes.selector} fullWidth value={chargeType}
                onChange={(evt) => {
                  this.setState({chargeType: evt.target.value});
                  if (evt.target.value === 0) {
                    this.setState({adId: null});
                  }
                }}>
                <MenuItem value={0}>현금충전</MenuItem>
                <MenuItem value={1}>무료충전</MenuItem>
                <MenuItem value={2}>소진</MenuItem>
              </Select>
              {chargeType !== 2 ? (
                <Select className={classes.selector} fullWidth value={packageType}
                      onChange={(e)=> {
                        this.setState({number: {15:10, 100: 100, 500: 670, 1000: 2000}[e.target.value]});
                        this.setState({period: {15: 1, 100: 6, 500: 12, 1000: 12}[e.target.value]});
                        this.setState({name: {15: '체험형 패키지', 100: '100만원 패키지', 500: '500만원 패키지', 1000: '1000만원 패키지'}[e.target.value]});
                        this.setState({price: e.target.value * 10000});
                      }}>
                  <MenuItem value={15}>체험형 패키지</MenuItem>
                  <MenuItem value={100}>100만원 패키지</MenuItem>
                  <MenuItem value={500}>500만원 패키지</MenuItem>
                  <MenuItem value={1000}>1000만원 패키지</MenuItem>
                </Select>
              ) : (
                <Select className={classes.selector} fullWidth value={adId}
                        onChange={this.handleChange('adId')}>
                  {companies.map((company)=> {
                    return (<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>)
                  })}
                </Select>
              )}
              <TextField label='Number' placeholder={'number'} className={classes.textField}
                         margin='normal' value={number} onChange={this.handleChange('number')}/>
              <TextField label='Notes' placeholder={'Notes'} className={classes.textField}
                margin='normal' value={notes} onChange={this.handleChange('notes')}/>
              <Button variant='contained'
                color='primary'
                onClick={() => {
                  onCreate(number, period, name, price, adId, notes, chargeType);
                  this.props.onClose();
                }}>
                추가
              </Button>
            </FormControl>
          </form>
        )}
      </Popover>
    );
  }
}

export default withStyles(styles)(InfoPackage);