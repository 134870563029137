import React from 'react';
import moment from 'moment';
import Tag from '../pages/user/components/Tag';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imageContainer: {
    borderRadius: '16px',
    overflow: 'hidden',
    height: '112px',
    width: '112px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  rightContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px',
  },
  text: {
    fontSize: '14px',
  },
  primaryText: {
    color: '#333',
    fontWeight: '600',
  },
  secondaryText: {
    color: '#808080',
  },
  buttonContainer: {
    marginTop: '12px',
  },
};

const ContractCard = (props) => {
  const ACCENT_COLD = '#0080FF';
  const GRAYSCALE5 = '#DBDBDB';
  const GRAYSCALE3 = '#808080';

  const findSelectedHistoryEntry = (statusHistory) => {
    const find = statusHistory.find((history) => history.status === 'SELECTED');
    return find;
  };

  const generatedContractItem = (contract) => {
    const point = contract.rewardAmount;
    const discountAmount = contract.totalPrice - contract.finalPrice;
    const statusText = (() => {
      if (contract.currentContractStatus === 'REVIEW_WRITTEN') {
        return 'Post Completed';
      } else if (contract.currentContractStatus === 'WAITING_FOR_SELECTION') {
        return 'Pending';
      } else if (contract.currentContractStatus === 'CANCELLED') {
        return 'Cancelled';
      } else if (contract.currentContractStatus === 'NOT_SELECTED') {
        return 'Declined';
      } else if (contract.currentContractStatus === 'SELECTED') {
        const today = moment();

        let dateToCalculate;
        if (contract.adsType === 'EXPRESS') {
          dateToCalculate = contract.createdAt;
        } else if (!!findSelectedHistoryEntry(contract.statusHistory)) {
          dateToCalculate = findSelectedHistoryEntry(
            contract.statusHistory
          ).updatedAt;
        }

        if (dateToCalculate) {
          const someDate = moment(dateToCalculate).add(
            contract.deadline,
            'day'
          );
          const diffDays = someDate.diff(today, 'day');
          return `${diffDays} days left`;
        }

        return 'Not Found';
      } else {
        console.log(contract.currentContractStatus);
        return 'Not Found State';
      }
    })();

    const tags = [];

    //NOTE : Point, cashback 지급 여부 Tag 생성 (정산 관련 Tag는 SELECTED, REVIEW_WRITTEN 2개의 상태인 경우에만 보이도록 설정)
    if (
      contract.currentContractStatus === 'SELECTED' ||
      contract.currentContractStatus === 'REVIEW_WRITTEN'
    ) {
      //포인트
      if (point > 0) {
        if (contract.currentRewardStatus === 'PENDING') {
          tags.push({
            label: 'Point Pending',
            color: ACCENT_COLD,
            mode: 'OUTLINE',
          });
        } else if (contract.currentRewardStatus === 'COMPLETED') {
          tags.push({
            label: 'Point Earned',
            color: GRAYSCALE5,
            mode: 'OUTLINE',
          });
        }
      }

      //cashback
      if (contract.deliveryMethod === 'REIMBURSEMENT') {
        if (contract.currentCashbackStatus === 'PENDING') {
          tags.push({
            label: 'Cashback Pending',
            color: ACCENT_COLD,
            mode: 'OUTLINE',
          });
        } else if (
          contract.currentCashbackStatus === 'TAX_REPORTED' ||
          contract.currentCashbackStatus === 'COMPLETED'
        ) {
          tags.push({
            label: 'Cashback Earned',
            color: GRAYSCALE5,
            mode: 'OUTLINE',
          });
        }
      }
    }
    return {
      image: contract.thumbnail,
      statusTextColor:
        contract.currentContractStatus === 'REVIEW_WRITTEN' ||
        contract.currentContractStatus === 'CANCELLED' ||
        contract.currentContractStatus === 'NOT_SELECTED'
          ? GRAYSCALE3
          : ACCENT_COLD,
      joinedDate: moment(contract.createdAt).format('MMM DD, YYYY'),

      [contract.deliveryMethod === 'DIRECT_SHIPPING'
        ? 'benefitAmount'
        : 'cashbackAmount']: `$${discountAmount}`,
      ...(point > 0 && { pointAmount: `$${point}` }),
      title: contract.productName,
      statusText,
      tags: tags,
    };
  };

  const cardData = generatedContractItem(props.contract);

  return (
    <div>
      <div
        style={styles.container}
        onClick={() => {
          window.open(`/cosduck/contract?id=${props.contract.id}`);
        }}
      >
        {/* Left */}
        <div style={styles.imageContainer}>
          <img src={cardData.image} alt={cardData.title} style={styles.image} />
        </div>
        <div style={{ width: '12px' }} /> {/* HorizontalSpace */}
        {/* Right */}
        <div style={styles.rightContainer}>
          {/* Right-Top */}
          <div style={{ ...styles.row, justifyContent: 'space-between' }}>
            <span style={{ ...styles.text, color: cardData.statusTextColor }}>
              {cardData.statusText} {`[${props.contract.adsType}]`}
            </span>
            <span style={{ ...styles.text, ...styles.primaryText }}>
              {cardData.joinedDate}
            </span>
          </div>
          {/* Right-Middle */}
          <div>
            <span
              style={{ ...styles.text, ...styles.primaryText }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {cardData.title}
            </span>
            {cardData.cashbackAmount && (
              <div style={{ ...styles.row, gap: '2px' }}>
                <span style={{ ...styles.text, color: '#0080FF' }}>
                  Cashback
                </span>
                <span style={{ ...styles.text, ...styles.secondaryText }}>
                  {cardData.cashbackAmount}
                </span>
              </div>
            )}
            {cardData.benefitAmount && (
              <div style={{ ...styles.row, gap: '2px' }}>
                <span style={{ ...styles.text, color: '#FF9500' }}>
                  Benefit
                </span>
                <span style={{ ...styles.text, ...styles.secondaryText }}>
                  {cardData.benefitAmount}
                </span>
              </div>
            )}
            {cardData.pointAmount && (
              <div style={{ ...styles.row, gap: '2px' }}>
                <span style={{ ...styles.text, color: '#00AAFF' }}>Point</span>
                <span style={{ ...styles.text, ...styles.secondaryText }}>
                  {cardData.pointAmount}
                </span>
              </div>
            )}
          </div>
          {!!cardData.tags && (
            <div style={styles.tagContainer}>
              {cardData.tags.map((t) => (
                <Tag {...t} />
              ))}
            </div>
          )}
        </div>
      </div>

      {!!cardData.bottomButton && (
        <div style={styles.buttonContainer}>
          <button
            style={{
              width: '100%',
              height: '36px',
              backgroundColor: '#000',
              color: '#FFF',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            구매 인증 필요
          </button>
        </div>
      )}
    </div>
  );
};

export default ContractCard;
