import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ActionCreators from '../../../../action';
import AdsGroupList from '../../../features/AdsGroupList';
import AdsGroupEditor from '../../../features/AdsGroupEditor';

const AdsGroup = (props) => {
  const { adsWithDetails } = props;
  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>광고그룹</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        <AdsGroupList
          adId={adsWithDetails.ad.id}
          companyId={adsWithDetails.ad.companyId}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <AdsGroupEditor
            adId={adsWithDetails.ad.id}
            companyId={adsWithDetails.ad.companyId}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsGroup);
