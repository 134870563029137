import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const QuestionAnswersTable = (props) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            questionAnswers
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>question</TableCell>
                <TableCell>answer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.questionAnswers.map((qa) => (
                <TableRow>
                  <TableCell>{qa.question}</TableCell>
                  <TableCell>{qa.answer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default QuestionAnswersTable;
