import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import CreateIcon from '@material-ui/icons/Create';

import { fetchData } from '../../app/api';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

String.prototype.hashCode = function () {
  var hash = 0, i = 0, len = this.length;
  while (i < len) {
    hash = ((hash << 5) - hash + this.charCodeAt(i++)) << 0;
  }
  return (hash + 2147483647) + 1;
};

class DestinationCard extends React.PureComponent {
  state = {
    destinations: [],
    selected: {}
  };
  render() {
    const { destinations, selected } = this.state;
    const elements = (destinations && destinations.length)
      ? destinations.map((item) => {
        return (
          <TableRow key={item.id}>
            <TableCell>{item.title}</TableCell>
            <TableCell>{item.name || '-'}</TableCell>
            <TableCell>{item.contact}</TableCell>
            <TableCell>{item.address}</TableCell>
            <TableCell><a href="#" onClick={() => {
              this.setState({
                selected: item
              })
            }}>
              <CreateIcon />
            </a></TableCell>
          </TableRow>
        );
      })
      : null;
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>배송지 목록</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <section>
              <div className='table-container'>
                <Table style={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>배송지</TableCell>
                      <TableCell>이름</TableCell>
                      <TableCell>연락처</TableCell>
                      <TableCell>주소</TableCell>
                      <TableCell>편집</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{elements}</TableBody>
                </Table>
              </div>
              {Object.keys(selected).length ? <div style={{ width: '100%' }} className='editor-container contents-editor'>
                <div className='row'>
                  <div>
                    <label>배송지 명</label>
                    <TextField
                      fullWidth
                      value={selected.title}
                      onChange={this.handleChange('title')}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div>
                    <label>수령자 명</label>
                    <TextField
                      fullWidth
                      value={selected.name}
                      onChange={this.handleChange('name')}
                    />
                  </div>
                  <div>
                    <label>연락처</label>
                    <TextField
                      fullWidth
                      value={selected.contact}
                      onChange={this.handleChange('contact')}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div>
                    <label>우편번호</label>
                    <TextField
                      fullWidth
                      value={selected.zip}
                      onChange={this.handleChange('zip')}
                    />
                  </div>
                </div>
                <div>
                  <label>주소</label>
                  <TextField
                    fullWidth
                    value={selected.address}
                    onChange={this.handleChange('address')}
                  />
                </div>
                <div>
                  <label>상세주소</label>
                  <TextField
                    fullWidth
                    value={selected.detail}
                    onChange={this.handleChange('detail')}
                  />
                </div>
              </div> : ''}
              {Object.keys(selected).length ? <div className='row button'>
                <Button
                  key={0}
                  style={{ marginLeft: 12 }}
                  variant='contained'
                  onClick={() => {
                    this.setState({
                      selected: {}
                    })
                  }}
                >
                  취소
                </Button>
                <Button
                  key={1}
                  style={{ marginLeft: 12 }}
                  variant='contained'
                  color='secondary'
                  onClick={() => this.handleUpdate()}
                >
                  저장
                </Button>
              </div> : ''}
            </section>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  componentDidMount() {
    fetchData('get', '/destination', { user: this.props.uid }).then((datas) => {
      this.setState({
        destinations: datas.destinations
      });
    });
  }
  handleChange = name => event => {
    let { selected } = this.state;
    console.log(name)
    console.log(event.target.value)
    selected[name] = event.target.value;
    this.setState({
      selected
    });
    this.forceUpdate();
  };
  handleUpdate = () => {
    const { selected } = this.state;
    fetchData('put', '/destination', selected).then(() => {
      fetchData('get', '/destination', { uid: this.props.uid }).then((datas) => {
        if (datas.users) {
          this.setState({
            destinations: datas.destinations
          });
          this.forceUpdate();
        }
      });
    });
  };
}

export default DestinationCard;
