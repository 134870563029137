import React, { useState } from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ActionCreators from '../../../../action';
import CustomTextField from '../../../../../components/CustomTextField';
import Preview from './Preview';

const Memo = (props) => {
  const { adsWithDetails, setAdsWithDetails, onSaveHandler } = props;

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>메모</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        <CustomTextField
          label='memo'
          multiline
          value={adsWithDetails.ad.memo}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              ad: {
                ...adsWithDetails.ad,
                memo: event.target.value,
              },
            });
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <Preview adsWithDetails={adsWithDetails} />
          <Button
            style={{ marginLeft: 12 }}
            variant='contained'
            color='secondary'
            onClick={() => {
              onSaveHandler();
            }}
          >
            저장
          </Button>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Memo);
