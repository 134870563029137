import React, { forwardRef } from 'react';

const CustomInput = forwardRef((props, ref) => {
  const { inputStyle, ...rest } = props;

  return (
    <div
      style={{
        ...rest.containerStyle,
      }}
    >
      {rest.label && (
        <p
          style={{
            color: '#273144',
            marginBottom: '8px',
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          {rest.label}
          {rest.description && (
            <span
              style={{
                color: '#2a5bd7',
                fontSize: '12px',
                marginLeft: '4px',
              }}
            >
              *{rest.description}
            </span>
          )}
        </p>
      )}
      <input
        {...rest}
        ref={ref}
        style={{
          height: '44px',
          backgroundColor: '#fff',
          border: '1px solid #dbe0eb',
          boxSizing: 'border-box',
          borderRadius: 4,
          ...(!!inputStyle && {
            ...inputStyle,
          }),
        }}
      />
    </div>
  );
});

export default CustomInput;
