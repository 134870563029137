import React from 'react';
import { Color } from '../constants/color';
import Typo from './Typo';

const IconButton = ({
  icon = '',
  onClick,
  label,
  style,
  typography = 'Body2',
  fontWeight = 400,
  color = Color.statustext_complete,
  children,
  ...props
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: color,
        ...style,
      }}
      onClick={onClick}
    >
      <Typo typography={'Body1'} fontWeight={500}>
        {label}
      </Typo>
      {children}
    </div>
  );
};

export default IconButton;
