import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import CompanyEditor from './company';
import StringEditor from './string';
import DateEditor from './date';
import ImageEditor from './image';
import FileEditor from './file';
import NumberEditor from './number';
import BooleanEditor from './boolean';
import ArrayEditor from './array';
import EnumEditor from './enum';
import ObjectEditor from './object';
import './editor.css';

export default class Editor extends React.Component {
  state = {
    ...this.props.schema,
  };
  handleChange = (name) => (value) => {
    if (value !== undefined) {
      let newState = this.state;
      newState[name] = Object.assign(newState[name], { value: value });
      this.setState(newState);
    } else {
      console.log(this.state);
      this.props.handleAddToChart();
    }
  };
  render() {
    let fields = Object.keys(this.state).map((key) => {
      let state = this.state;
      let field = state[key];
      let childProps = {
        key,
        field,
        handleChange: this.handleChange(key),
      };
      switch (field.type) {
        case 'string':
          if (field.format && field.format.indexOf('date') >= 0) {
            return <DateEditor {...childProps} />;
          } else if (field.format && field.format.indexOf('uri') >= 0) {
            if (field.filename !== undefined) {
              field.filename = field.filename.replace(/\${/g, '${state.');
              field.filename = field.filename.replace(/}/g, '.value}');
              field.filename = '`' + field.filename + '`';
              field.filename = eval(field.filename);
            }
            if (field.filetype.indexOf('image') >= 0)
              return <ImageEditor {...childProps} />;
            else return <FileEditor {...childProps} />;
          } else if (key === 'company') {
            return <CompanyEditor {...childProps} />;
          } else if (field.enum) {
            return <EnumEditor {...childProps} />;
            // } else if (key === 'email') {
            // childProps.disabled = true;
            // return <StringEditor {...childProps} />;
          } else {
            return <StringEditor {...childProps} />;
          }
        case 'integer':
          if (field.enum) {
            return <EnumEditor {...childProps} />;
          } else {
            return <NumberEditor {...childProps} />;
          }
        case 'boolean':
          return <BooleanEditor {...childProps} />;
        case 'array':
          if (field.items.type === 'string')
            return <ArrayEditor {...childProps} />;
          else if (field.items.type === 'object') {
            let url = document.location.href.split('/');
            url = url[url.length - 1].split('?');
            url = url[0] + '-contents?' + url[1];
            return (
              <Button
                key={key}
                variant='contained'
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={() => window.open(url)}
              >
                내용 편집
              </Button>
            );
          }
          return;
        case 'object':
          return <ObjectEditor {...childProps} />;
        default:
          return (
            <div key={key}>
              <label>{field.name}</label>
              <div>{JSON.stringify(field.value)}</div>
            </div>
          );
      }
    });
    let buttons = [];
    if (this.props.handleDelete) {
      let buttonText = this.props.handleDeleteText
        ? this.props.handleDeleteText
        : '삭제';
      buttons.push(
        <Button
          key={0}
          style={{ marginLeft: 12 }}
          variant='contained'
          onClick={() => this.props.handleDelete(this.state)}
        >
          {buttonText}
        </Button>
      );
    }
    if (this.props.handleCancel) {
      let buttonText = this.props.handleCancelText
        ? this.props.handleCancelText
        : '취소';
      buttons.push(
        <Button
          key={1}
          style={{ marginLeft: 12 }}
          variant='contained'
          onClick={() => this.props.handleCancel(this.state)}
        >
          {buttonText}
        </Button>
      );
    }
    if (this.props.handleSave) {
      let buttonText = this.props.handleSaveText
        ? this.props.handleSaveText
        : '저장';
      buttons.push(
        <Button
          key={2}
          style={{ marginLeft: 12 }}
          variant='contained'
          color='secondary'
          onClick={() => this.props.handleSave(this.state)}
        >
          {buttonText}
        </Button>
      );
    }
    return (
      <div className='editor'>
        {fields}
        <div className='row button'>{buttons}</div>
      </div>
    );
  }
}

Editor.propTypes = {
  handleSave: PropTypes.func,
  schema: PropTypes.object,
};
