import React, { useState, useRef } from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/CloudUpload';
import { uploadFileToS3 } from '../../app/api';

const styles = (theme) => ({
  card: {
    width: '100%',
  },
  media: {
    objectFit: 'cover',
  },
  actions: {
    justifyContent: 'flex-end',
  },
});

const ImageNewCard = ({ classes, handleAdd }) => {
  const fileElement = useRef(null);
  const fileRef = useRef([]);
  const [urls, setUrls] = useState([]);

  const handleOpen = () => {
    fileElement.current.click();
  };

  const imageFileRenderPromise = (file) => {
    return new Promise((r) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        r(e.target.result);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleChange = async (event) => {
    const promise = [];

    Object.values(event.target.files).forEach((file) => {
      if (file) {
        fileRef.current = [...fileRef.current, file];
        promise.push(imageFileRenderPromise(file));
      }
    });

    const result = await Promise.all(promise);
    setUrls([...urls, ...result]);
  };

  const handleUpload = async (index) => {

    const file = fileRef.current[index];


    if (file) {
      const root =
        document.location.href.split('/')[
          document.location.href.split('/').length - 1
        ];
      let path = root.split('?')[0];
      path = path.indexOf('company') > -1 ? 'ads' : 'cam';
      const id = root.split('=')[1];
      const fileName = `org/${path}/${id}/thumb_${new Date().getTime()}${Math.random()
        .toString(36)
        .substr(2, 5)}.${file.name.split('.').pop()}`;
      try {
        const downloadUrl = await uploadFileToS3(fileName, file);

        handleAdd(downloadUrl);

        const cloneFileRef = [...fileRef.current];
        cloneFileRef.splice(index,1);

        fileRef.current = [...cloneFileRef];

        const clone = [...urls];
        clone.splice(index, 1);
        setUrls(clone);

      } catch (error) {
        console.error('Upload failed', error);
      }
    }
  };

  const newCard = (
    <div className='card-item'>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component='img'
            className={classes.media}
            height='100'
            image={'http://via.placeholder.com/350x150'}
          />
          <input
            multiple
            type='file'
            accept='image/*'
            style={{ display: 'none' }}
            ref={fileElement}
            onChange={handleChange}
          />
        </CardActionArea>
        <CardActions className={classes.actions}>
          {/* {hasFile && (
            <IconButton onClick={handleUpload}>
              <UploadIcon />
            </IconButton>
          )} */}
          <IconButton onClick={handleOpen}>
            <AddIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );

  const imageCards = urls.map((url, index) => (
    <div className='card-item'>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            component='img'
            className={classes.media}
            height='100'
            image={url}
          />
        </CardActionArea>
        <CardActions className={classes.actions}>
          <IconButton
            onClick={() => {
              handleUpload(index);
            }}
          >
            <UploadIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  ));

  return (
    <>
      {imageCards}
      {newCard}
    </>
  );
};

export default withStyles(styles)(ImageNewCard);
