import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const colors = [
  { code: '#bd1328', name: 'PRIMARY LIGHT' },
  { code: '#b00016', name: 'PRIMARY' },
  { code: '#7d0010', name: 'PRIMARY DARK' },
  { code: '#4770bd', name: 'SECONDARY LIGHT' },
  { code: '#395a97', name: 'SECONDARY' },
  { code: '#263c64', name: 'SECONDARY DARK' },
  { code: '#484848', name: 'DEFAULT LIGHT' },
  { code: '#000000', name: 'DEFAULT' },
  { code: '#ffffff', name: 'INVERSED(WHITE)' },
  { code: 'transparent', name: 'TRANSPARENT' },
];
class ColorSelector extends Component {
  constructor(props) {
    super(props);
    const colorCodes = [
      '#bd1328',
      '#b00016',
      '#7d0010',
      '#4770bd',
      '#395a97',
      '#263c64',
      '#484848',
      '#000000',
      '#ffffff',
      'transparent',
    ];
    this.state = {
      open: false,
      color: props.initialColor ? props.initialColor : '',
      custom: props.initialColor && colorCodes.indexOf(props.initialColor) < 0 ? true : false,
    };
  }
  render() {
    const { open, color, custom } = this.state;
    const selects = colors.map((item) => {
      const className =
        'color-chip ' +
        (item.code === '#ffffff' || item.code === 'transparent' ? 'inversed ' : '') +
        (color === item.code ? 'selected' : '');
      return (
        <ListItem key={item.code} button onClick={() => this.handleChange(item.code)}>
          <ListItemText primary={item.name} />
          <div className={className} style={{ backgroundColor: item.code }}>
            {item.code}
          </div>
        </ListItem>
      );
    });
    return (
      <div>
        <Button
          style={{ color: color }}
          variant={color === '#ffffff' || color === 'transparent' ? 'outlined' : 'flat'}
          onClick={() => this.setState({ open: true })}
        >
          {this.props.label}
        </Button>
        <Dialog open={open}>
          <DialogTitle>컬러 선택</DialogTitle>

          {custom ? (
            <List>
              <ListItem>
                <TextField
                  label='커스텀 컬러 입력'
                  placeholder='#b00016'
                  onKeyPress={this.handleKeyPress}
                  defaultValue={color}
                  fullWidth
                />
              </ListItem>
            </List>
          ) : (
            <div />
          )}
          <div>
            <List>
              {selects}
              <ListItem button onClick={() => this.setState({ custom: true })}>
                <ListItemText primary='CUSTOM' />
                {custom ? (
                  <div className='color-chip selected' style={{ backgroundColor: color }}>
                    {this.state.color}
                  </div>
                ) : (
                  <div />
                )}
              </ListItem>
            </List>
            <Button fullWidth={true} onClick={() => this.setState({ open: false })}>
              닫기
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
  handleChange = (value) => {
    this.setState({ color: value, open: false, custom: false });
    this.props.handleChange(value);
  };
  handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      const code = evt.target.value;
      if (code.length === 7) {
        this.setState({ color: code, open: false });
        this.props.handleChange(code);
      }
    }
  };
}

export default ColorSelector;
