import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import * as api from "../api";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button/Button";
import momentLib from "moment-timezone";

class EditorWithdrawal extends React.Component {
  state = {
    proposition: {},
    user: {},
    point: {}
  };
  render() {
    const { proposition } = this.state;

    if (Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <h3>인출 정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
              <section>
                <label>포인트 상태</label>
                <Select
                  fullWidth
                  value={proposition.pointStatus}
                  onChange={(evt) => this.props.handleChange('pointStatus', Number(evt.target.value))}>
                  <MenuItem value={0}>캠페인진행중</MenuItem>
                  <MenuItem value={1}>적립대기</MenuItem>
                  <MenuItem value={2}>적립보류</MenuItem>
                  <MenuItem value={5}>취소</MenuItem>
                  <MenuItem value={10}>적립완료</MenuItem>
                  <MenuItem value={101}>인출대기</MenuItem>
                  <MenuItem value={110}>인출완료</MenuItem>
                </Select>
                <label>예금주</label>
                <TextField
                  fullWidth
                  value={proposition.accountHolder}
                  onChange={(evt) => this.props.handleChange('accountHolder', evt.target.value)}
                />
                <label>은행명</label>
                <TextField
                  fullWidth
                  value={proposition.bankName}
                  onChange={(evt) => this.props.handleChange('bankName', evt.target.value)}
                />
                <label>계좌번호</label>
                <TextField
                  fullWidth
                  value={proposition.accountNumber}
                  onChange={(evt) => this.props.handleChange('accountNumber', evt.target.value)}
                />
                <label>원천징수 수수료</label>
                <TextField
                  fullWidth
                  value={proposition.authBusiness ? 0 : proposition.settlementAmount * 0.033}
                />
                <label>입금액</label>
                <TextField
                  fullWidth
                  value={proposition.settlementAmount - (proposition.authBusiness ? 0 : proposition.settlementAmount * 0.033)}
                />
                <label>인출 신청일</label>
                <TextField
                  fullWidth
                  value={proposition.withdrawalRequestAt ? momentLib(proposition.withdrawalRequestAt).format('YYYY-MM-DD hh:mm:ss') : ''}
                />
                <div className='row'>
                  <div style={{flex: 4}}>
                    <label>인출 완료일</label>
                    <TextField
                      fullWidth
                      value={proposition.withdrawalAt ? momentLib(proposition.withdrawalAt).format('YYYY-MM-DD hh:mm:ss') : ''}
                    />
                  </div>
                  <div style={{flex: 1, marginTop: 40}}>
                    <Button color='secondary' onClick={() => this.props.handleChange('withdrawalAt', momentLib(new Date()))}>Now</Button>
                  </div>
                </div>
              </section>
              <div className='row' style={{justifyContent:'flex-end'}}>
                <Button color='secondary' onClick={() => {
                  if (window.confirm('인출완료 처리를 진행하시겠습니까')) {
                    this.props.handleChange('pointStatus', 110);
                    window.setTimeout(() => {
                      this.props.handleChange('withdrawalAt', momentLib(new Date()));
                      window.setTimeout(() => {
                        this.props.handleSave();
                      }, 1000);
                    }, 1000);
                  }
                }}>인출완료처리</Button>
                <Button color='secondary' onClick={this.props.handleSave}>저장하기</Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    else {
      return <div/>;
    }
  }
  async componentDidMount() {
    this.setState({proposition: this.props.proposition});
  }

  componentWillReceiveProps(newProps) {
    this.setState({proposition: newProps.proposition});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorWithdrawal);
