import React, { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const Redash = () => {
  const height = window.innerHeight - 100;
  const dashboardUrls = {
    chart:
      "http://redash.supermembers.co.kr/public/dashboards/LiPIw4KTLD0uHdeKWu5yK7IZgN4CFds9KGajiKwK?org_slug=default",
    members:
      "http://redash.supermembers.co.kr/public/dashboards/LmPdLjFPTqniI4rdkbns6l8C4iyz1eFsYlEOlSIm?org_slug=default",
    archivement: "http://redash.supermembers.co.kr/public/dashboards/ZgEH4cA8EFI3NHb3Tv15VbO1QMowizIEQoMLcHzY?org_slug=default",
  };
  const [mode, setMode] = useState("chart");
  return (
    <div
      style={{
        width: "100%",
        height,
        textAlign: "right",
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <ToggleButtonGroup
        value={mode}
        exclusive
        size="small"
        style={{ marginBottom: 20 }}
        onChange={(event, value) => setMode(value)}
      >
        <ToggleButton value="chart">슈퍼차트</ToggleButton>
        <ToggleButton value="members">슈퍼멤버스</ToggleButton>
        <ToggleButton value="archivement">성과보드</ToggleButton>
      </ToggleButtonGroup>
      <iframe
        id="redash-frame"
        width="100%"
        height={height - 60}
        style={{ border: 0 }}
        src={dashboardUrls[mode]}
      />
    </div>
  );
};

export default Redash;
