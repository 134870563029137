import * as AppActions from './app/action'
import * as AuthActions from './auth/action'
import { push, goBack, goForward, go, replace } from 'react-router-redux';

const ActionCreators = Object.assign(
    {
        push,
        goBack,
        goForward, 
        go, 
        replace
    },
    AppActions,
    AuthActions
);

export default ActionCreators;