import React from "react";
import * as api from "../../company3/api";
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Switch from "@material-ui/core/Switch";
import momentLib from "moment-timezone";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router";
import IconButton from "@material-ui/core/IconButton/IconButton";
import OpenInNew from "@material-ui/icons/OpenInNew";

class DetailProposition extends React.Component {
  state = {
    companyPoint: {},
    routes: {}
  };

  render() {
    let {companyPoint, routes} = this.state;

    if (Object.keys(companyPoint).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>충전금액 소진</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>포인트 분류</label>
                  <TextField
                    fullWidth
                    value={'사용'}
                  />
                  <label>propositionId</label>
                  <TextField
                    fullWidth
                    value={companyPoint.taggableId}
                  />
                  <label>캠페인명</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.cname}
                  />
                  <label>캠페인 혜택</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.creward}
                  />
                  <label>혜택 금액</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.cprice}
                  />
                  <label>회원명</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.user.displayName}
                  />
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>채널</label>
                      <TextField
                        fullWidth
                        value={companyPoint.proposition.taggableType}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>등급</label>
                      <TextField
                        fullWidth
                        value={companyPoint.proposition.user.level}
                      />
                    </div>
                  </div>
                  <label>매칭 제안 금액</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.point}
                  />
                  <label>responsedAt</label>
                  <TextField
                    fullWidth
                    value={companyPoint.proposition.responsedAt}
                  />
                  <label>status</label>
                  <TextField
                    fullWidth
                    value={{0: '목록', 1: '제안', 2: '수락', 3: '거절', 4: '무응답', 10: '제품: 발송대기', 12: '매장: 미방문',
                      20: '이용완료 / 원고대기(매장: 방문완료 / 제품: 발송완료)', 22: '원고수정요청', 24: '원고확인중', 30: '리뷰대기중',
                      32: '리뷰완료', 40: '평가완료', 50: '이용취소'}[companyPoint.proposition.status]}
                  />
                </section>
                <div className='row'>
                  <div>
                    <label>매칭 내역</label>
                    <Link to={ '/proposition?id=' + companyPoint.taggableId } target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                  <div>
                    <label>캠페인</label>
                    <Link to={ '/campaign?id=' +  companyPoint.proposition.campaignId} target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div/>;
    }
  }

  async componentDidMount() {
    const routes = await api.getOptions('type=root');
    this.setState({routes});
    this.setState({companyPoint: this.props.companyPoint});
  }

  componentWillReceiveProps(newProps) {
    this.setState({companyPoint: newProps.companyPoint});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(DetailProposition);