import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import CampaignBasic from './campaign-basic';
import CampaignChannel from './campaign-channel';
import CampaignInfo from './campaign-info';
import AdsInfo from './ads-info';
import CampaignEtc from './campaign-etc';

import * as api from '../api';
import {CampaignKeywordCard, PropositionCards, DeliveryCards, InfoLog} from "../../../components";
import InfoMemo from "./info-memo";

class Campaign extends React.Component {
  state = {
    id: this.props.location.query.id,
    campaign: {},
    snackOpen: false,
  };
  render() {
    const { campaign } = this.state;

    if (campaign && Object.keys(campaign).length > 0) {
      return (
        <div className='contents'>
          <div className='editor-container contents-editor'>
            <CampaignBasic campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange}></CampaignBasic>
            <CampaignChannel campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange} onRef={ref => (this.adsInfo = ref)}></CampaignChannel>
            <CampaignInfo campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange}></CampaignInfo>
            <AdsInfo campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange}></AdsInfo>
            <CampaignEtc campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange}></CampaignEtc>
            <InfoMemo campaign={campaign} handleChange={this.handleChange} handlePropChange={this.handlePropChange}
                      handleDelete={this.handleDelete} handleSave={this.handleSave} />
            <CampaignKeywordCard campaign={campaign.id}/>
            <InfoLog type={'campaigns'} id={campaign.id}/>
          </div>
          <div className='additional-container'>
            <PropositionCards campaign={campaign.id} campaignName={campaign.name} />
            <DeliveryCards campaign={campaign.id} campaignName={campaign.name} />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.props.activateLoading();
    api.getCampaign(this.state.id).then((result) => {
      const campaign = result.campaigns[0];
      this.setState({ campaign: campaign });
      this.props.deactivateLoading();
    });
  }
  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({campaign : { ...this.state.campaign, [name]: value }});
  };
  handlePropChange = (name, key, value) => {
    const campaign = {
      ...this.state.campaign,
    };
    campaign[name][key] = value;
    // this.setState({ campaign });
    return new Promise(resolve => {
      this.setState({ campaign }, () => resolve());
    });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const {id, campaign} = this.state;
      api
        .deleteCampaign(id)
        .then(() => {
          this.setState({snackOpen: true});
          this.props.deactivateLoading();
          this.props.push('/campaigns');
        })
        .catch((err) => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
  handleSave = async () => {
    this.props.activateLoading();

    const { id, campaign } = this.state;

    api.updateCampaign(id, campaign)
      .then(() => {
        this.setState({ snackOpen: true });
        this.props.deactivateLoading();
      })
      .catch((err) => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        }
      });
  };
  handleCancel = () => {
    this.props.push('/campaigns');
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Campaign);
