import React from 'react';

const ApplicationStatus = ({ maxCapacity, enrolled }) => {
  return (
    <div style={{ display: 'flex', gap: 12, margin: '20px 16px' }}>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          gap: 4,
        }}
      >
        <span>Applied</span>
        <span>{enrolled} applied</span>
      </div>
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          gap: 4,
        }}
      >
        <span>Total Seats</span>
        <span>{maxCapacity} total</span>
      </div>
    </div>
  );
};

export default ApplicationStatus;
