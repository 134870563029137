import * as types from './types'
import {getToken, isSignedIn, signOut} from '../api';

export function loginSuccessed(user) {
    return {
        type:types.SIGN_IN,
        user:user
    }
}
export function logoutSuccessed() {
    return {
        type:types.SIGN_OUT
    }
}
export function authChanged(callback) {
    return (dispatch, getState) => {
        return isSignedIn().then(() => {
            getToken().then(user => {
                dispatch(loginSuccessed(user))
                callback(user)
            }).catch((err) => {
                return err;
            })
        }).catch((err) => {
            callback(err)
        })
    }
}
export function login() {
    return (dispatch, getState) => {
        return getToken().then(user => {
            dispatch(loginSuccessed(user))
        }).catch((err) => {
            return err;
        })
    }
}

export function logout() {
    return (dispatch, getState) => {
        signOut().then(() => {
            dispatch(logoutSuccessed())
        }).catch((err) => {
            console.log(err)
        })
    }
}