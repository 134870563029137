import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/AddCircleOutline';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Remove from "@material-ui/core/SvgIcon/SvgIcon";

class InfoBasic extends React.Component {
  state = {
    settlement: {},
    contract: {},
    showRefund: false
  };
  render() {
    let { settlement, contract, showRefund, newMemo } = this.state;
    const memo = settlement.memo === null || settlement.memo === undefined ? [] : settlement.memo;
    const memoElements = memo.map((item, idx) => {
      return (
        <div className="row" key={idx}>
          <TextField value={item} onChange={(evt) => this.handleChange(evt.target.value, idx)} fullWidth />
          <IconButton onClick={() => this.handleMemoDelete(idx)}>
            <Remove />
          </IconButton>
        </div>
      );
    });

    if (Object.keys(settlement).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>결제정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>가맹점</label>
                  <TextField
                    fullWidth
                    value={settlement.cid + ': ' + settlement.P_MNAME}
                  />
                  <label>주문상태</label>
                  <TextField
                    fullWidth
                    value={{0: '결제중', 1: '실결제완료', 3: '결제완료', 5: '정산예정', 7: '정산완료', 10: '결제취소', 20: '이용내역생성실패취소'}[settlement.status]}
                  />
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>사용자명</label>
                      <TextField
                        fullWidth
                        value={settlement.username}
                        onChange={(evt) => this.props.handleChange('username', evt.target.value)}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>채널</label>
                      <TextField
                        fullWidth
                        value={settlement.platform}
                        onChange={(evt) => this.props.handleChange('platform', evt.target.value)}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{marginTop: 0, marginBottom: 0}}>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제상태</label>
                      <TextField
                        fullWidth
                        value={settlement.P_STATUS === '00' ? '결재완료' : '' }
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                    </div>
                  </div>
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제금액</label>
                      <TextField
                        type={'number'}
                        fullWidth
                        value={settlement.price}
                        onChange={(evt) => this.props.handleChange('price', Number(evt.target.value))}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제수단</label>
                      <TextField
                        fullWidth
                        value={settlement.P_TYPE}
                        onChange={(evt) => this.props.handleChange('P_TYPE', evt.target.value)}
                      />
                    </div>
                  </div>
                  <label>결제일시</label>
                  <TextField
                    fullWidth
                    value={settlement.paidAt}
                  />
                  <label>PG승인번호(카드사승인번호)</label>
                  <TextField
                    fullWidth
                    value={settlement.P_AUTH_NO}
                    onChange={(evt) => this.props.handleChange('P_AUTH_NO', evt.target.value)}
                  />
                  <label>취소금액</label>
                  <TextField
                    fullWidth
                    value={settlement.canceled}
                    onChange={(evt) => this.props.handleChange('canceled', Number(evt.target.value))}
                  />
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>취소일시</label>
                      <TextField
                        fullWidth
                        value={settlement.canceledAt}
                      />
                    </div>
                    <Button color='secondary' onClick={() => {
                      showRefund=true;
                      this.setState({showRefund: showRefund});
                    }}>환불</Button>
                    <Dialog
                      open={showRefund}
                      onClose={() => {
                        showRefund=false;
                        this.setState({showRefund: showRefund});
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle>환불</DialogTitle>
                      <DialogContent>환불처리하시겠습니까?</DialogContent>
                      <DialogActions>
                        <Button onClick={() => {
                          showRefund=false;
                          this.setState({showRefund: showRefund});
                          this.handleRefund();
                        }} color="secondary" autoFocus>
                          환불
                        </Button>
                        <Button onClick={() => {
                          showRefund=false;
                          this.setState({showRefund: showRefund});
                        }} color="secondary" autoFocus>
                          닫기
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {contract ?
            <ExpansionPanel defaultExpanded style={{marginTop: 0, marginBottom: 0}}>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>배송지명</label>
                  <TextField
                    fullWidth
                    value={(contract.delivery || {}).name || ''}
                  />
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>수령자명</label>
                      <TextField
                        fullWidth
                        value={(contract.delivery || {}).addressName || ''}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>연락처</label>
                      <TextField
                        fullWidth
                        value={(contract.delivery || {}).contact || ''}
                      />
                    </div>
                  </div>
                  <label>우편번호</label>
                  <TextField
                    fullWidth
                    value={(contract.delivery || {}).zip || ''}
                  />
                  <label>주소</label>
                  <TextField
                    fullWidth
                    value={(contract.delivery || {}).address || ''}
                  />
                  <label>배송 요청사항</label>
                  <TextField
                    fullWidth
                    value={(contract.delivery || {}).additionalInfo || ''}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
            </ExpansionPanel>
            : ''}
          <ExpansionPanel defaultExpanded style={{marginTop: 0, marginBottom: 0}}>
            <ExpansionPanelSummary >
              <h3>메모</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  {memoElements}
                  <div className="row">
                    <TextField value={newMemo} onChange={(evt) => this.setState({ newMemo: evt.target.value })} fullWidth />
                    <IconButton onClick={() => this.handleAdd()}>
                      <Add />
                    </IconButton>
                  </div>
                </section>
                <div className='row' style={{justifyContent:'flex-end'}}>
                  <Button color='secondary' onClick={this.props.handleDelete}>결제내역삭제</Button>
                  <Button color='secondary' onClick={this.props.handleSave}>저장</Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }
  componentDidMount() {
    this.setState({settlement: this.props.settlement, contract: this.props.contract});
  }

  componentWillReceiveProps(newProps) {
    this.setState({settlement: newProps.settlement, contract: newProps.contract});
  }

  handleChange = (value, idx) => {
    console.log(value, idx)
    const { settlement } = this.state;
    const memo = settlement.memo === null ? [] : [...settlement.memo];

    memo[idx] = value;

    this.props.handleChange('memo', memo);
  }

  handleMemoDelete = (idx) => {
    const { settlement } = this.state;

    const memo = (settlement || {}).memo || [];

    memo.splice(idx, 1);

    this.props.handleChange('memo', memo);
  };

  handleAdd = () => {
    const { settlement, newMemo } = this.state;

    const memo = (settlement || {}).memo || [];

    if (newMemo.length === 0) {
      return;
    }
    memo.push(newMemo);
    this.setState({ newMemo: ''});

    this.props.handleChange('memo', memo);
  };

  handleRefund = () => {
    this.props.handleRefund();
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoBasic);
