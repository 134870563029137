import React, {PureComponent} from "react";
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ImageEditor from "../../../components/image-editor";
import AdsMenu from "../../company3/editor/ads-etc-menu";
import AdsQuestion from "../../company3/editor/ads-etc-question";

class CampaignEtc extends PureComponent {
  render() {
    const { campaign } = this.props;
    if (Object.keys(campaign).length > 0) {
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>기타 상세정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <div>
                <ImageEditor
                  label='광고 이미지'
                  images={campaign.thumbnails ? campaign.thumbnails : []}
                  main={campaign.thumbnail}
                  handleChange={(arr) => this.props.handleChange('thumbnails', arr)}
                  handleMainChange={(url) => this.props.handleChange('thumbnail', url)}
                />
              </div>
              <div>
                <AdsMenu
                  menu={campaign.menu}
                  handleChange={this.props.handleChange}
                  handlePropChange={this.props.handlePropChange}
                />
                <AdsQuestion
                  question={campaign.question}
                  handleChange={this.props.handleChange}
                  handlePropChange={this.props.handlePropChange}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CampaignEtc);