import React from 'react';

const TabButton = ({ isSelected, onClickHandler, label, iconName }) => {
  return (
    <span
      onClick={onClickHandler}
      style={{
        padding: '8px 24px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '24px',
          backgroundColor: '#ea2a3a',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '4px',
          opacity: `${isSelected ? '1' : '0.6'}`,
        }}
      >
        <span style={{ color: 'white', lineHeight: '12px' }}>{iconName}</span>
      </span>
      <span
        style={{
          color: `${isSelected ? '#4a4a4a' : '#7a7a7a'}`,
          fontWeight: `${isSelected ? '600' : '400'}`,
        }}
      >
        {label}
      </span>
    </span>
  );
};

export default TabButton;
