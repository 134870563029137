import * as api from '../../app/api';

const IS_NEW_SERVER_API = true;

export const getInquiries = (filter) => {
  return api.fetchData('get', '/inquiry', filter, null, IS_NEW_SERVER_API);
};

export const getInquiry = (id) => {
  return api.fetchData('get', `/inquiry/${id}`, null, null, IS_NEW_SERVER_API);
};

export const updateInquiry = (id, body) => {
  delete body.id;
  delete body.updatedAt;
  delete body.App_Inquiry_Answer;
  return api.fetchData(
    'patch',
    `/inquiry/${id}/`,
    null,
    body,
    IS_NEW_SERVER_API
  );
};

export const createAnswer = (id, body) => {
  return api.fetchData(
    'post',
    `/inquiry/${id}/answers`,
    null,
    body,
    IS_NEW_SERVER_API
  );
};

export const updateAnswer = (id, answerId, body) => {
  return api.fetchData(
    'patch',
    `/inquiry/${id}/answers/${answerId}`,
    null,
    body,
    IS_NEW_SERVER_API
  );
};
