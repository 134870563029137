import * as api from '../api';

export function filterPremium(query) {
    return api.fetchData('get', '/company/user/premium', query)
    .then((datas) => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}
export function getPremium(id) {
    return api.fetchData('get','/company/user/premium', {id:id})
    .then(datas => {
        return Promise.resolve(datas);
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export function createPremium(json) {
    return api.fetchData('post', '/company/user/premium', json)
      .then(result => {
          return Promise.resolve(result);
      })
      .catch((err) => {
          return Promise.reject(err)
      })
}

export function updatePremium(id, json) {
    return api.fetchData('put', '/company/user/premium', {id:id}, json)
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}
export function deletePremium(id) {
    return api.fetchData('delete', '/company/user/premium', {id:id})
    .then(result => {
        return Promise.resolve(result);
    })
    .catch((err) => {
        return Promise.reject(err)
    })
}
export function getCompanyUser(id) {
    if (id) {
        return api
          .fetchData('get', '/company/user', { id: id })
          .then((datas) => {
              return Promise.resolve(datas);
          })
          .catch((err) => {
              return Promise.reject(err);
          });
    } else {
        return Promise.reject('');
    }
}
export function filterCompanyCharge(query) {
    console.log(query)
    return api.fetchData('get', '/company/user/charge', query)
      .then((datas) => {
          return Promise.resolve(datas);
      }).catch((err) => {
          return Promise.reject(err)
      })
}