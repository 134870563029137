import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const Editor = ({ contract, handleChange }) => {
  if (contract) {
    const deliveryInfo = ['address', 'name', 'id', 'email', 'contact', 'additionalInfo', 'request'].map((key) => {
      if (contract.delivery) {
        return (
          <div key={key}>
            <label>{key}</label>
            <TextField value={contract.delivery[key] || ''} onChange={(evt) => {
              contract.delivery[key] = evt.target.value;
              handleChange('delivery', contract.delivery);
            }}/>
          </div>
        );
      }
      else {
        return ('');
      }
    });
    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <h3>배송정보</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{width: '100%'}}>
            {/* row */}
            <div className='row'>
              <div style={{flex: 2}}>
                <label>배송방법</label>
                <Select
                  fullWidth
                  value={contract.deliveryMethod}
                  onChange={(evt) => handleChange('deliveryMethod', evt.target.value)}
                >
                  <MenuItem value={undefined}>미지정</MenuItem>
                  <MenuItem value={'A'}>회원 주소로 직접 배송</MenuItem>
                  <MenuItem value={'C'}>자사몰에서 쿠폰 / 적립금 지급</MenuItem>
                </Select>
              </div>
              <div style={{flex: 1}}/>
            </div>
            {deliveryInfo}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  else {
    return ('');
  }
};

export default Editor;
