import React from 'react'
import { bindActionCreators } from 'redux'
import ActionCreators from '../action'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { ContentsEditor } from '../../components'
import * as api from './api'

class NoticeContents extends React.Component {
  state = {
    id:this.props.location.query.id,
    name: '',
    contents:[],
    modalOpened:false,
    snackOpen:false,
    loaded: false,
  }
  componentDidMount() {
    this.props.activateLoading()
    api.getNotice(this.state.id).then(result => {
      this.setState({contents: result.notices[0].contents, name: result.notices[0].title, loaded:true});
      this.props.deactivateLoading();
    })
  }
  handleSave = (json) => {
    this.props.activateLoading();
    
    api.updateNotice(this.state.id, { contents: json }).then(result => {
      this.setState({contents:json, snackOpen:true})
      this.props.deactivateLoading()
    }).catch(err => {
      console.log(err)
      this.props.deactivateLoading()
      alert('업데이트에 실패했습니다. 다시 확인해주세요.');
    })
  }
  handleCancel = () => {
    this.props.push('/notice?id='+this.state.id);
  }
  render() {
    const { contents, name, loaded } = this.state;
    if (!loaded) {
      return null;
    }
    return (
      <div>
        <ContentsEditor
          initialValue={contents}
          title={name}
          handleSave={this.handleSave}
          handleCancel={this.handleCancel}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={4000}
          onClose={()=>this.setState({snackOpen:false})}
          message={<span>업데이트 성공</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={()=>this.setState({snackOpen:false})}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}
export default connect(null, mapDispatchToProps) (NoticeContents)