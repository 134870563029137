import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import _ from 'lodash';

import * as api from "../api";
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "../../../components/contract-cards/card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CreateIcon from "@material-ui/icons/Create";
import momentLib from "moment-timezone";
import {Link} from "react-router";
import {deleteSumemPoint} from "../api";

class EditorChargeHistory extends React.Component {
  state = {
    sumemPoint: {},
    sumemPoints: [],
    filter: 0
  };

  render() {
    let {sumemPoint, sumemPoints} = this.state;
    // let cards = charges.map(charge => {
    //   return (
    //     <Card
    //       key={contract.id || contract.rKey}
    //       contract={contract}
    //       forWho={forWho}
    //       companies={companies}
    //     />
    //   );
    // });

    if (Object.keys(sumemPoints).length > 0 && Object.keys(sumemPoint).length > 0) {
      const elements = (sumemPoints && sumemPoints.length)
        ? sumemPoints.map((item) => {
          return (
            <TableRow key={item.id}>
              <TableCell>{momentLib(item.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
              <TableCell>{item.contractId ? '사용' : '충전'}</TableCell>
              <TableCell>{(!item.contractId && item.amount > 0) ? item.amount : '-'}</TableCell>
              <TableCell>{(item.contractId) ? item.amount : '-'}</TableCell>
              <TableCell>{(!item.contractId && item.amount < 0) ? item.amount : '-'}</TableCell>
              <TableCell>{item.balance}</TableCell>
              <TableCell>
                <Link to={'/sumempoint?id=' + (item.id || item.rKey)} target="_blank">
                  <CreateIcon />
                </Link>
              </TableCell>
            </TableRow>
          );
        })
        : null;
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{sumemPoint.company.name} 충전금액 내역</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div>
                  <Tabs value={0} onChange={this.handleFilterChange}>
                    <Tab label={"전체(" + sumemPoints.length + ")"} />
                  </Tabs>
                </div>
                <div className='count'>
                  <div className='table-container'>
                    <Table style={{ minWidth: 500 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>일자</TableCell>
                          <TableCell>유형</TableCell>
                          <TableCell>충전액</TableCell>
                          <TableCell>소진액</TableCell>
                          <TableCell>환불</TableCell>
                          <TableCell>잔액</TableCell>
                          <TableCell>상세</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{elements}</TableBody>
                    </Table>
                  </div>
                </div>
                {/*{cards}*/}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div/>;
    }
  }

  async componentDidMount() {
    this.setState({sumemPoint: this.props.sumemPoint});
  }

  async componentWillReceiveProps(newProps) {
    this.setState({sumemPoint: newProps.sumemPoint});
    let result = await api.filterSumemPoint({companyId: newProps.sumemPoint.companyId, orderBy: 'createdAt', direction: 'desc'});
    let sumemPoints = result.sumemPoints;
    this.setState({sumemPoints: sumemPoints});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorChargeHistory);