import Joi from "joi";
import {
  AdCategoryEnum,
  PaymentMethodEnum,
  PaymentPlanEnum,
} from "../../paycard.enum";

const rule = {
  name: {
    adCategory: "상품 종류",
    amount: "결제 금액",
    plan: "결제 플랜",
    payMethod: "결제 수단",
    paymentCardIndex: "결제 카드",
    checkBloggerCnt: "최소 보장인원",
    number: "모집 인원",
  },
  schema: Joi.object({
    adCategory: Joi.string()
      .valid(...Object.values(AdCategoryEnum))
      .required()
      .messages({
        "any.required": "상품 종류는 필수 항목입니다",
        "any.only": "올바른 상품 종류를 선택해주세요",
      }),
    amount: Joi.number().min(0).required().messages({
      "number.min": "결제금액은 0 이상이어야 합니다",
      "any.required": "결제금액은 필수 항목입니다",
    }),
    plan: Joi.number()
      .valid(...Object.values(PaymentPlanEnum))
      .required()
      .messages({
        "any.required": "결제플랜은 필수 항목입니다",
      }),
    payMethod: Joi.string()
      .valid(...Object.values(PaymentMethodEnum))
      .required()
      .messages({
        "any.required": "결제수단은 필수 항목입니다",
      }),
    paymentCardIndex: Joi.when("payMethod", {
      is: PaymentMethodEnum.CARD,
      then: Joi.number().min(0).required().messages({
        "number.base": "결제수단이 카드일 경우 결제카드를 지정해야합니다.",
        "number.min": "결제카드는 최소 0 이상이어야 합니다",
        "any.required": "결제카드는 필수 항목입니다",
      }),
      otherwise: Joi.forbidden(),
    }),
    checkBloggerCnt: Joi.when("adCategory", {
      is: AdCategoryEnum.STORE_STANDARD,
      then: Joi.number().min(1).required().messages({
        "any.unknown": "최소 보장인원을 지정할 수 없습니다",
        "number.min": "최소 보장인원은 최소 1명 이상이어야 합니다",
        "any.required": "최소 보장인원은 필수 항목입니다",
      }),
      otherwise: Joi.forbidden(),
    }),
    number: Joi.when("adCategory", {
      is: AdCategoryEnum.STORE_STANDARD,
      then: Joi.valid(null).messages({
        "any.only": "매장-표준형은 모집인원 항목을 지정할 수 없습니다",
      }),
      otherwise: Joi.number().min(1).required().messages({
        "number.base": "모집인원은 숫자여야 합니다",
        "number.min": "모집인원은 최소 1 이상이어야 합니다",
        "any.required": "모집인원은 필수 항목입니다",
      }),
    }),
  }).options({
    abortEarly: false,
  }),
};

// number: Joi.when("adCategory", {
//   is: AdCategoryEnum.STORE_STANDARD,
//   then: Joi.any().valid(null).empty(['', undefined]).messages({
//     "any.only": "매장-표준형은 모집인원 항목을 지정할 수 없습니다",
//   }),
//   otherwise: Joi.number().min(1).required().messages({
//     "number.base": "모집인원은 숫자여야 합니다",
//     "number.min": "모집인원은 최소 1 이상이어야 합니다",
//     "any.required": "모집인원은 필수 항목입니다",
//   }),
// }),

export default rule;
