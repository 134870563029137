import { useEffect, useState } from 'react';

const MAX_KEYWORDS = 10;

const useKeyword = () => {
  const [keywords, setKeywords] = useState([]);

  const generatedUuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  useEffect(() => {
    const origin = window.localStorage.getItem('purchase-link-keyword');
    setKeywords(JSON.parse(origin || '[]'));
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      'purchase-link-keyword',
      JSON.stringify(keywords)
    );
  }, [keywords]);

  const addKeyword = ({ category, subCategory, keyword }) => {
    const newKeyword = {
      category,
      subCategory,
      keyword,
      id: generatedUuidv4(),
    };

    const excludeTargetKeyword = [...keywords].filter(
      (item) =>
        item.keyword !== keyword ||
        item.category !== category ||
        item.subCategory !== subCategory
    );

    if (keywords.length >= MAX_KEYWORDS) {
      setKeywords([
        newKeyword,
        ...excludeTargetKeyword.slice(0, MAX_KEYWORDS - 1),
      ]);
    } else {
      setKeywords([newKeyword, ...excludeTargetKeyword]);
    }
  };

  const removeKeyword = (id) => {
    const clone = [...keywords];
    const excludeTargetId = clone.filter((item) => item.id !== id);
    setKeywords(excludeTargetId);
  };

  return { keywords, addKeyword, removeKeyword };
};

export default useKeyword;
