import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import momentLib from 'moment-timezone'

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Button from "@material-ui/core/Button/Button";
import * as api from "../../company3/api";
import {sendSms} from "../../users/api";

class InfoBasic extends React.Component {
  state = {
    routes: {}
  };
  render() {
    const { companyUser } = this.props;
    const { routes } = this.state;
    
    if (Object.keys(companyUser).length > 0) {
      let categoryText = '';
      let categoryColor = '';

      if(!!companyUser.last_login && !!companyUser.last_login_matching){
          categoryText = '전체';
          categoryColor = '';
      }else if(!!companyUser.last_login && !companyUser.last_login_matching){
          categoryText = '멤버스';
          categoryColor = '#1BB000';
      }else if(!companyUser.last_login && !!companyUser.last_login_matching){
          categoryText = '차트';
          categoryColor = '#FF0055';
      }

      return (
        <div>
          <ExpansionPanel defaultExpanded style={{marginBottom: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>{companyUser.name}님의 정보 <span style={{color: categoryColor}}>({categoryText})</span></h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>담당자명</label>
                      <TextField
                        fullWidth
                        value={companyUser.charger}
                        onChange={(evt) => this.props.handleChange('charger', evt.target.value)}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>매니져</label>
                      <TextField
                        fullWidth
                        value={companyUser.manager}
                        onChange={(evt) => this.props.handleChange('manager', evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>담당자 연락처</label>
                      <TextField
                        fullWidth
                        value={companyUser.contacts}
                        onChange={(evt) => this.props.handleChange('contacts', evt.target.value)}
                      />
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>슈퍼멤버스 카톡 추가 안내</label>
                      <Button onClick={() => {
                        sendSms({
                          phoneNumber: companyUser.contacts.replace(/\D/g, ''),
                          contents: "안녕하세요 슈퍼멤버스 입니다 : )\n\n" +
                            "슈퍼멤버스는 서비스 안내 및 상담을 카카오톡에서 드리고 있습니다.\n\n" +
                            "하단 링크로 “업체명” 남겨주시면 상담 도와드리겠습니다.\n\n" +
                            "감사합니다 ( _ _ )\n\n" +
                            "링크 바로가기: http://pf.kakao.com/_Jgyxid",
                        });
                      }}>보내기</Button>
                    </div>
                    <div style={{flex: 1, minWidth: 0}}>
                      <label>카드 등록 링크 보내기</label>
                      <Button onClick={() => {
                        sendSms({
                          phoneNumber: companyUser.contacts.replace(/\D/g, ''),
                          contents: "안녕하세요, 슈퍼멤버스입니다.\n\n" +
                            "광고 진행을 위해서는 카드 등록을 하셔야 진행이 됩니다.\n\n" +
                            "아래 링크를 클릭하셔서 카드 등록을 부탁드리겠습니다.\n\n" +
                            "https://supermembers.co.kr/cardform/" + companyUser.id,
                        });
                      }}>보내기</Button>
                    </div>
                  </div>
                  <label>본사 업체 ID Number</label>
                  <TextField
                    fullWidth
                    value={companyUser.companyId}
                    placeholder={0}
                    onChange={(evt) => this.props.handleChange('companyId', parseInt(evt.target.value))}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{marginTop: 0}}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <h3>업체 상세정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className='row'>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>업체명</label>
                      <TextField
                        fullWidth
                        value={companyUser.name}
                        onChange={(evt) => this.props.handleChange('name', evt.target.value)}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>사업자 등록번호</label>
                      <TextField
                        fullWidth
                        value={companyUser.registration}
                        onChange={(evt) => this.props.handleChange('registration', evt.target.value)}
                      />
                    </div>
                  </div>
                  <label>기존 아이디</label>
                  <TextField
                    fullWidth
                    disabled
                    value={companyUser.username}
                    onChange={(evt) => this.props.handleChange('username', evt.target.value)}
                  />
                  <label>담당자 이메일(아이디)</label>
                  <TextField
                    fullWidth
                    value={companyUser.email}
                    onChange={(evt) => this.props.handleChange('email', evt.target.value)}
                  />
                  <label>방문경로</label>
                  <Select
                    fullWidth
                    value={companyUser.route}
                    onChange={(evt) => this.props.handleChange('route', evt.target.value)}
                  >
                    {
                      Object.keys(routes).map((key) => {
                        return (
                          <MenuItem value={key}>{routes[key]}</MenuItem>
                        )
                      })
                    }
                  </Select>
                  <label>메일수신 동의여부</label>
                  <Switch
                    checked={companyUser.agree_to_mail}
                    onChange={(evt) => this.props.handleChange('agree_to_mail', evt.target.value)}
                  />
                  <label>메일계정 생성여부</label>
                  <Switch
                    disabled
                    checked={companyUser.accountCreated}
                  />
                  <label>추천인</label>
                  <TextField
                    fullWidth
                    value={companyUser.recommended}
                    onChange={(evt) => this.props.handleChange('recommended', evt.target.value)}
                  />
                  <label>lastLogin</label>
                  <TextField
                    fullWidth
                    value={momentLib(companyUser.lastSignInTime).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}
                  />
                </section>
                <div className='row' style={{justifyContent:'flex-end'}}>
                  <Button color='secondary' onClick={this.props.handleDel}>삭제</Button>
                  <Button color='secondary' onClick={this.props.handleSave}>저장</Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const routes = await api.getOptions('type=root');
    this.setState({routes});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoBasic);
