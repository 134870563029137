import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';

const Card = ({
  id,
  longUrl,
  shortUrl,
  naverUrl,
  views,
  contractId,
  propositionId,
  onEditHandler,
  onDeleteHandler,
  Contracts,
  Propositions,
}) => {
  const isMembers = !!Contracts;
  const iconName = isMembers ? 'M' : 'C';
  const title = isMembers ? Contracts.companyName : Propositions.cname;

  const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // 화면에서 벗어나도록 스타일 적용
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      console.log(
        'Fallback: Copying text command was ' +
          (successful ? 'successful' : 'unsuccessful')
      );
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  };

  return (
    <div
      style={{
        display: 'flex',
        background: '#fff',
        border: '0.1rem solid #fff',
        borderRadius: '0.8rem',
        padding: '2.4rem',
        maxWidth: '824px',
      }}
    >
      {/* LEFT */}
      <span
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '24px',
          backgroundColor: '#ea2a3a',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '16px',
        }}
      >
        <span style={{ color: 'white', lineHeight: '12px' }}>{iconName}</span>
      </span>
      {/* Center */}
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <div
          onClick={() => {
            let URL = '';

            if (isMembers) {
              URL = `/contract?id=${contractId}`;
            } else {
              URL = `/proposition?id=${propositionId}`;
            }

            window.open(URL, '_blank');
          }}
        >
          <p
            style={{
              color: '#273144',
              fontSize: 24,
              lineHeight: '24px',
              marginBottom: 16,
              fontWeight: 500,
            }}
          >
            {title}
          </p>
        </div>
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          <a
            style={{
              color: '#273144',
              textDecorationLine: 'none',
              marginBottom: '4px',
            }}
            href={longUrl}
            target='_blank'
          >
            {longUrl}
          </a>
        </div>
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          <a
            style={{
              color: '#273144',
              textDecorationLine: 'none',
              marginBottom: '4px',
            }}
            href={shortUrl}
            target='_blank'
          >
            {shortUrl}
          </a>
        </div>
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          <a
            style={{
              color: '#2a5bd7',
              textDecorationLine: 'none',
              fontWeight: 600,
              fontSize: 18,
            }}
            href={naverUrl}
            target='_blank'
          >
            {naverUrl}
          </a>
        </div>
        {/* 하단 */}
        <div
          style={{
            marginTop: '24px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <VisibilityIcon
              style={{
                color: '#526281',
              }}
            />
            <span
              style={{
                color: '#526281',
                fontWeight: 700,
                fontSize: 14,
                marginLeft: 4,
              }}
            >
              {(views || 0).toLocaleString()}
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LockIcon
              style={{
                color: '#526281',
              }}
            />
            <span
              style={{
                color: '#526281',
                fontWeight: 700,
                fontSize: 14,
                marginLeft: 4,
              }}
            >
              {id || 'Error'}
            </span>
          </div>
        </div>
      </div>

      {/* Right ETC*/}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 8,
          marginLeft: 16,
        }}
      >
        <div
          onClick={onDeleteHandler}
          style={{
            cursor: 'pointer',
            border: '1px solid #e8ebf2',
            display: 'inline-flex',
            borderRadius: 8,
            padding: 8,
          }}
        >
          <DeleteIcon />
        </div>
        <div
          onClick={() => {
            copyTextToClipboard(naverUrl);
          }}
          style={{
            cursor: 'pointer',
            backgroundColor: '#e8ebf2',
            color: '#273144',
            display: 'inline-flex',
            borderRadius: 8,
            padding: 8,
            fontSize: 14,
          }}
        >
          <FileCopyIcon />
          <span style={{ marginLeft: 4 }}>Copy</span>
        </div>
        <div
          onClick={onEditHandler}
          style={{
            cursor: 'pointer',
            border: '1px solid #e8ebf2',
            display: 'inline-flex',
            borderRadius: 8,
            padding: 8,
          }}
        >
          <CreateIcon />
        </div>
      </div>
    </div>
  );
};

export default Card;
