import React, { Component } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { TextField } from '@material-ui/core';
import VirtualizedSelect from 'react-virtualized-select';

import { fetchData } from '../../app/api';
import Card from './card';
import { withStyles } from '@material-ui/core/styles/index';

const styles = (theme) => ({
  Select: {
    marginTop: '4px',
    width: '100%',
  },
});

class KeywordCard extends Component {
  state = {
    total: 0,
    regions: [],
    combinations: [],
    ranks: [ { category: '', rank: 0 } ],
    lists: [],
    selectedList: {},
    postUrl: '',
  };

  componentDidMount() {
    fetchData('get', '/keyword', { category: '지역' })
      .then((datas) => {
        this.setState({ regions: datas.keywords });
      })
      .catch((err) => {
        console.error(err);
        alert('지역 키워드 조회 실패');
      });

    fetchData('get', '/keyword', { category: '조합' })
      .then((datas) => {
        this.setState({ combinations: datas.keywords });
      })
      .catch((err) => {
        console.error(err);
        alert('조합 키워드 조회 실패');
      });

    fetchData('get', '/user/blog', { uid: this.props.uid })
      .then((datas) => {
        this.setState({ lists: datas });
      })
      .catch((err) => {
        console.error(err);
        alert('블로그 포스트 목록 조회 실패');
      });
  }

  render() {
    const { classes, email } = this.props;

    let cards = this.state.ranks.map((rank, i) => {
      return (
        <Card
          key={i}
          index={i}
          regions={this.state.regions}
          combinations={this.state.combinations}
          ranks={this.state.ranks}
          postUrl={this.state.postUrl}
          handlePlus={(ranks) => this.setState({ ranks })}
          handleMinus={(ranks) => this.setState({ ranks })}
        />
      );
    });

    return (
      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>블로거 심사</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className='keyword-cards'>
            <VirtualizedSelect
              className={classes.Select}
              autoFocus
              clearable={true}
              disabled={false}
              labelKey='title'
              multi={false}
              onChange={(selectedList) => {
                this.setState({ selectedList: selectedList });
                if (email) {
                  let blogId = email && email.indexOf('@') > 0 ? email.split('@')[0] : email;
                  this.setState({ postUrl: 'https://blog.naver.com/' + blogId + '/' + selectedList.logNo });
                }
              }}
              options={this.state.lists}
              searchable={true}
              value={this.state.selectedList || {}}
            />
            <TextField
              fullWidth
              onChange={(e) => this.setState({ postUrl: e.target.value })}
              value={this.state.postUrl}
              helperText='Please input blog post url'
            />
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(KeywordCard);
