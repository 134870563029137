import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Tooltip from '@material-ui/core/Tooltip';  
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash'; 

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '1em',
    lineHeight: "1.5em",
    maxWidth: 1600,
    whiteSpace: 'pre-wrap', // 줄 바꿈 허용
    fontFamily: 'monospace'
  }
})(Tooltip);
 

class InfoAdsGroup extends PureComponent {
  state = {  
    newAdId: ""
  };
  render() {
    const { company, adsGroup } = this.props; 
    const { newAdId } = this.state;
    const adIds = !adsGroup ? [] : adsGroup.adIds;
    const adIdElements = adIds.map((item, idx) => {
      return (
        <div className="row" key={idx}>
          <TextField value={item} onChange={(evt) => this.handleChange(evt.target.value, idx)} fullWidth />
          <IconButton onClick={() => this.handleDelete(idx)}>
            <Remove />
          </IconButton>
        </div>
      );
    });
    if (Object.keys(company).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary >
            <div style={{display:'flex', gap:'8px', alignItems:'end'}}>
              <h3>광고 그룹</h3> 
              <CustomTooltip title={
              <span>
                [기존 광고그룹에 추가] adsGroupID가 undefined인 상태에서 adsGroupID를 넣으면 해당 adsGroup에 현재 광고가 추가됩니다.<br/>
                [새로운 광고그룹 생성] adsGroupID가 undefined인 상태에서 하나 이상의 광고ID를 넣으면 새로운 그룹이 생성됩니다.<br/>
                [광고 그룹 삭제　　　] adsGroupID가 존재하는 상태에서 광고ID를 모두 삭제하면 해당 adsGroup이 삭제되고 광고들에 등록되어있던 adsGroupID가 초기화됩니다.<br/>
                [광고 그룹 업데이트　] adsGroupID가 존재하는 상태에서 광고ID를 변경하면 변경된 광고들에 adsGroupID가 업데이트됩니다.<br/>
                [입력 시 주의사항　　] 광고ID를 추가하실때 입력창에 광고ID를 적고 같은 줄 맨 오른쪽에 있는 +버튼을 눌러주셔야 적용됩니다.
              </span>} placement='right-end'>
                <span style={{color:"gray"}}>어떻게 사용하나요?</span>
            </CustomTooltip>
            </div>
           
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <div><span style={{fontWeight:'bold', color:'royalblue'}}>adsGroupID</span>: {company.adsGroupId || 'undefined'}</div> 
              <section>
                {adIdElements}
                <div className="row">
                  <TextField value={newAdId} onChange={(evt) => this.setState({ newAdId: evt.target.value })} fullWidth />
                  <IconButton onClick={() => this.handleAdd()}>
                    <Add />
                  </IconButton>
                </div>
              </section> 
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  handleChange = (value, idx) => {
    console.log(value, idx)
    const { adsGroup, handleChange } = this.props;
    const adIds = adsGroup ? [...adsGroup.adIds] : [];
    adIds[idx] = value; 
    handleChange('adsGroup', adIds);
  }
  handleDelete = (idx) => {
    const { adsGroup, handleChange } = this.props;
    const adIds = adsGroup ? [...adsGroup.adIds] : [];
    adIds.splice(idx, 1);  
    handleChange('adsGroup', adIds);
  };
  handleAdd = () => {
    const { adsGroup, handleChange } = this.props;
    const { newAdId } = this.state;
    const adIds = adsGroup ? [...adsGroup.adIds] : [];

    if (!newAdId) {
      return;
    }
    adIds.push(newAdId);
    this.setState({ newAdId: '' });   
    handleChange('adsGroup', adIds);
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoAdsGroup);
