import React from 'react'
import {bindActionCreators} from 'redux'
import momentLib from 'moment-timezone'
import {connect} from 'react-redux'
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import * as api from './api'
import ActionCreators from '../action'
import { SortableTableHead, SearchInput } from '../../components'

const header = [
  {sortable: false, id: 'rKey', label: 'ID'},
  {sortable: true, id: 'os', label: 'OS'},
  {sortable: true, id: 'osVersion', label: 'OS버전'},
  {sortable: true, id: 'appVersion', label: '앱버전'},
  {sortable: true, id: 'processed', label: '처리여부'},
  {sortable: true, id: 'registedAt', label: '등록일'},
  {sortable: false, id: 'edit', label: '상세보기'},
]

class Bugs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 100,
      orderBy: 'registedAt',
      direction: 'desc',
      bugs: [],
      total: 0
    }
    this.handleChangePage = this.handleChangePage.bind(this)
    this.fetchDatas = this.fetchDatas.bind(this)
  }

  componentDidMount() {
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.fetchDatas(0, this.state.orderBy, this.state.direction, {[selectItem]: findText})
  };

  handleChangePage(event) {
    this.fetchDatas(event.selected, null, null, null);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading()
    const page = (pageNum >= 0) ? pageNum : this.state.page;
    const filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      ...options
    }
    api.filterBugs(filter).then(results => {
      this.setState(Object.assign(filter, results))
      this.props.deactivateLoading();
    });
  }

  render() {
    const {rowsPerPage, orderBy, direction, bugs, total} = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1)

    let bodys = bugs.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.rKey}</TableCell>
          <TableCell>{item.os}</TableCell>
          <TableCell>{item.osVersion}</TableCell>
          <TableCell>{item.appVersion}</TableCell>
          <TableCell>{item.processed ? 'O' : 'X'}</TableCell>
          <TableCell>{momentLib(item.registedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>{item.os}</TableCell>
          <TableCell><Link to={`/bug?id=${item.rKey}`} target="_blank">보기</Link></TableCell>
        </TableRow>
      )
    })
    return (
      <div className="table-container">
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, order) => this.fetchDatas(null, orderBy, order, null)}
          />
          <TableBody>
            {bodys}
          </TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate previousLabel={"< 이전"}
                         nextLabel={"다음 >"}
                         breakLabel={<span>...</span>}
                         pageCount={pageCount}
                         marginPagesDisplayed={1}
                         pageRangeDisplayed={10}
                         onPageChange={this.handleChangePage}
                         containerClassName={"pagination"}
                         subContainerClassName={"pages pagination"}
                         activeClassName={"active"}
                         initialPage={0}
                         forcePage={this.state.page - 1}/>
          <div></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Bugs)