import React, { PureComponent } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';

import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import './card.css';
import { Icon } from '@material-ui/core';

const styles = {
  card: {
    width: '100%',
  },
  media: {
    // object-fit is not supported by IE11.
    objectFit: 'cover',
  },
  actions: {
    justifyContent: 'flex-end',
  },
};

class ImageCard extends PureComponent {
  render() {
    const { classes, main } = this.props;

    return (
      <div
        className='card-item'
        draggable
        onDragStart={this.props.onDragStart}
        onDragEnter={this.props.onDragEnter}
        onDragOver={this.props.onDragOver}
        onDragEnd={this.props.onDragEnd}
      >
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              component='img'
              className={classes.media}
              height='100'
              image={this.props.image}
              onClick={() => window.open(this.props.image)}
              onError={(e) => {
                e.target.src = this.props.image
                  .replace('resize', 'org')
                  .replace('webp', 'jpg')
                  .replace('?f=jpeg', '');
              }}
            />
          </CardActionArea>
          <CardActions className={classes.actions}>
            <IconButton onClick={this.props.handlePrev}>
              <LeftIcon />
            </IconButton>
            <IconButton onClick={this.props.handleNext}>
              <RightIcon />
            </IconButton>
            {this.props.image.indexOf('cloudfront') > -1 ? (
              <IconButton onClick={this.props.handleRotateRight}>
                <RotateRightIcon />
              </IconButton>
            ) : (
              ''
            )}
            <IconButton
              onClick={this.props.handleMain}
              color={this.props.isMain ? 'secondary' : 'default'}
            >
              <BookmarkIcon />
            </IconButton>
            <IconButton onClick={this.props.handleDelete}>
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(ImageCard);
