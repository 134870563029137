import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {bindActionCreators} from "redux";
import ActionCreators from "../../action";
import {connect} from "react-redux";
import * as api from "../api";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Link} from "react-router";
import IconButton from "@material-ui/core/IconButton";
import OpenInNew from "@material-ui/icons/OpenInNew";
import DateField from "../../../components/editor/date";

class EditorPoint extends React.Component {
  state = {
    proposition: {},
    user: {},
    point: {}
  };
  render() {
    const { proposition, user, point } = this.state;

    if (Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <h3>포인트 정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{width: '100%'}}>
              <section>
                <label>propositionId</label>
                <TextField
                  fullWidth
                  value={proposition.id}
                />
                <label>캠페인명</label>
                <TextField
                  fullWidth
                  value={proposition.cname}
                />
                <label>캠페인 혜택</label>
                <TextField
                  fullWidth
                  value={proposition.creward}
                />
                <label>혜택 금액</label>
                <TextField
                  fullWidth
                  value={proposition.cprice}
                />
                <label>업체명</label>
                <TextField
                  fullWidth
                  value={proposition.Company.name}
                />
                <label>companyId</label>
                <TextField
                  fullWidth
                  value={proposition.Campaign.companyId}
                />
                <div className='row'>
                  <div style={{flex: 1}}>
                    <label>매칭 제안 금액</label>
                    <TextField
                      fullWidth
                      value={proposition.point}
                    />
                  </div>
                  <div style={{flex: 1}}>
                    <label>유저 수수료율 (%)</label>
                    <TextField
                      fullWidth
                      value={proposition.fees}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div style={{flex: 1}}>
                    <label>유저 수락 금액</label>
                    <TextField
                      fullWidth
                      value={proposition.settlementAmount}
                    />
                  </div>
                  <div style={{flex: 1}}>
                    <label>수수료</label>
                    <TextField
                      fullWidth
                      value={proposition.feeAmount}
                    />
                  </div>
                </div>
                <DateField
                  field={{ name: 'responsedAt', value: proposition.responsedAt, format: 'date-time' }}
                />
                <div className='row'>
                  <div>
                    <label>매칭내역</label>
                    <Link to={ '/proposition?id=' +  proposition.id} target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                  <div>
                    <label>캠페인</label>
                    <Link to={ '/campaign?id=' + proposition.campaignId } target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                  <div>
                    <label>업체</label>
                    <Link to={ '/company-user?id=' + proposition.Company.id } target='_blank'>
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    }
    else {
      return <div/>;
    }
  }
  async componentDidMount() {
    console.log(this.props.proposition)
    this.setState({proposition: this.props.proposition});
  }

  componentWillReceiveProps(newProps) {
    this.setState({proposition: newProps.proposition});
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorPoint);
