import React from 'react';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';

const header = [
  // {sortable:false, id:'rKey', label:'ID'},
  { sortable: true, id: 'updatedAt', label: '변동일시' },
  { sortable: true, id: 'campaign', label: '캠페인' },
  { sortable: true, id: 'type', label: '이용채널' },
  { sortable: true, id: 'username', label: '유저' },
  { sortable: true, id: 'status', label: '상태' },
  { sortable: false, id: 'point', label: '포인트' },
  { sortable: false, id: 'edit', label: '상세' }
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  propositions: [],
  total: 0,
};

class Propositions extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  render() {
    const { rowsPerPage, orderBy, direction, propositions, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = propositions.map((item, index) => {
      let pointStatus = '';

      if (item.pointStatus === 0) {
        pointStatus = '캠페인진행중';
      }
      else if (item.pointStatus === 1) {
        pointStatus = '적립대기';
      }
      else if (item.pointStatus === 2) {
        pointStatus = '적립보류';
      }
      else if (item.pointStatus === 5) {
        pointStatus = '취소';
      }
      else if (item.pointStatus === 10) {
        pointStatus = '적립완료';
      }
      else if (item.pointStatus === 101) {
        pointStatus = '인출대기';
      }
      else if (item.pointStatus === 110) {
        pointStatus = '인출완료';
      }

      return (
        <TableRow key={index} style={item.currentState === -1 ? {textDecoration: 'line-through'} : {}}>
          <TableCell>{momentLib(item.updatedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>
            <Link to={'/campaign?id=' + item.campaignId} target="_blank">
              {item.cname}
            </Link>
          </TableCell>
          <TableCell className="level">
            {item.type === 'youtube' ? <span className="youtube">유튜브</span> : item.type === 'insta' ? <span className="insta">인스타</span> : <span className="naver">네이버</span>}
          </TableCell>
          <TableCell>
            <Link to={'/user?uid=' + item.uid} target="_blank">
              {item.Influencer.account}
            </Link>
          </TableCell>
          <TableCell>{pointStatus}</TableCell>
          <TableCell>{item.point}</TableCell>
          <TableCell>
            <Link to={'/user-point?id=' + (item.id || item.rKey)} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} handleUserPointFilter={this.handleUserPointFilter}/>
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => {
              this.setState({orderBy: orderBy, direction: direction});
              this.fetchDatas(null, orderBy, direction, null);
            }}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };

  handleUserPointFilter = (filter) => {
    this.resetState();
    if (filter && filter.length > 0) {
      this.fetchDatas(null, null, null, {pointStatus: filter});
    }
    else {
      this.fetchDatas(null, null, null, null);
    }
  }

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach((key) => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { propositions, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: 'updatedAt',
      direction: 'desc',
      query: 'pointStatus IS NOT NULL'
    };

    api.filterPropositions(filter).then((results) => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Propositions);
