import React, { PureComponent } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import momentLib from "moment-timezone";
import { Link } from "react-router";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Receipt from "@material-ui/icons/Receipt";
import { Button, Popover } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as api from "../../premium/api";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  selector: {
    margin: theme.spacing(1),
    width: 300,
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: "none",
    color: "#fff",
  },
});

class InfoPremiumPackage extends PureComponent {
  state = {
    openPremiumPackage: false,
    anchorPremiumPackage: null,
  };
  render() {
    const { companyUser, companies, premiums, classes } = this.props;

    const items = premiums.map((premium, idx) => {
      if (premiums.length > 0) {
        let company = companies.filter(
          (company) => company.id == premium.adId
        )[0];
        return (
          <div
            key={premium.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 12,
              borderBottom: "1px solid #dbdbdb",
            }}
          >
            <div>
              <span>
                {momentLib(premium.createdAt)
                  .tz("Asia/Seoul")
                  .format("YY/MM/DD HH:mm")}
              </span>
            </div>
            <div>
              <span>{premium.adId ? -premium.number : premium.number}명</span>
            </div>
            <div>
              <span>{premium.balance}명</span>
            </div>
            <div>
              <span>{premium.name}</span>
            </div>
            <div>{!premium.adId ? <span>{premium.period}개월</span> : ""}</div>
            <div>{!premium.adId ? <span>{premium.price}원</span> : ""}</div>
            <div>
              <Link to={`/premium?id=${premium.id}`} target="_blank">
                <IconButton style={{ padding: 0 }}>
                  <Receipt />
                </IconButton>
              </Link>
            </div>
          </div>
        );
      }
    });

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name}님의 프리미엄 패키지 내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: "100%" }}>{items}</div>
        </ExpansionPanelDetails>
        <div className="row" style={{ justifyContent: "flex-end" }}>
          <Button
            color="secondary"
            onClick={(e) => {
              this.setState({
                openPremiumPackage: true,
                anchorPremiumPackage: e.currentTarget,
              });
            }}
          >
            추가
          </Button>
          <NewPremiumBox
            classes={classes}
            companies={companies}
            open={this.state.openPremiumPackage}
            anchorEl={this.state.anchorPremiumPackage}
            onClose={() => {
              this.setState({
                openPremiumPackage: false,
              });
            }}
            onCreate={(
              number,
              period,
              name,
              price,
              adId,
              notes,
              chargeType
            ) => {
              let ad = companies.filter((company) => company.id == adId)[0];
              let charge = {
                companyId: companyUser.id,
                amount: ad ? null : chargeType === 2 ? 0 : Number(price),
                method:
                  chargeType === 0
                    ? "cash"
                    : chargeType === 1
                    ? "free"
                    : chargeType === 2
                    ? "spend"
                    : null, // NOTE: 소진과 관련한 chargeType이 없어서, spend 타입을 새로 만듦.
                order: companyUser.id + "_premium_" + new Date().getTime(),
                number: Number(number)
                  ? Number(number)
                  : ad
                  ? ad.naver.number
                  : Number(number),
                period: ad ? null : Number(period),
                name: ad ? ad.name : chargeType === 2 ? "소진" : name,
                adId: adId,
                notes: notes,
              };
              api.createPremium(charge).then((result) => {
                window.location.reload();
                console.log(result);
              });
            }}
          />
        </div>
      </ExpansionPanel>
    );
  }
}

class NewPremiumBox extends React.Component {
  static getDerivedStateFromProps(props) {
    return {
      open: props.open,
      pushList: props.pushList,
    };
  }
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
      anchorOriginVertical: "bottom",
      anchorOriginHorizontal: "center",
      transformOriginVertical: "top",
      transformOriginHorizontal: "center",
      positionTop: 200, // Just so the popover can be spotted more easily
      positionLeft: 400, // Same as above
      anchorReference: "anchorEl",
      chargeType: 0,
      premiumType: 25,
      number: 5,
      period: 12,
      name: "체험형 패키지",
      price: 250000,
      adId: null,
      notes: "",
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, anchorEl, companies, onCreate } = this.props;

    const {
      open,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
      chargeType,
      premiumType,
      adId,
      number,
      period,
      name,
      price,
      notes,
    } = this.state;

    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorReference={anchorReference}
        anchorPosition={{ top: positionTop, left: positionLeft }}
        onClose={() => {
          this.setState({
            open: false,
          });
          this.props.onClose();
        }}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        transformOrigin={{
          vertical: transformOriginVertical,
          horizontal: transformOriginHorizontal,
        }}
      >
        {
          <form className={classes.container} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                className={classes.selector}
                fullWidth
                value={chargeType}
                onChange={(evt) => {
                  this.setState({ chargeType: evt.target.value });
                  if (evt.target.value === 0) {
                    this.setState({ adId: null });
                  }
                }}
              >
                <MenuItem value={0}>현금충전</MenuItem>
                <MenuItem value={1}>무료충전</MenuItem>
                <MenuItem value={2}>소진</MenuItem>
              </Select>
              {chargeType !== 2 ? (
                <Select
                  className={classes.selector}
                  fullWidth
                  value={premiumType}
                  onChange={(e) => {
                    this.setState({
                      number: { 25: 5, 100: 25, 300: 100 }[e.target.value],
                    });
                    this.setState({
                      period: { 25: 12, 100: 12, 300: 12 }[e.target.value],
                    });
                    this.setState({
                      name: {
                        25: "프리미엄 체험형 패키지",
                        100: "프리미엄 100만원 패키지",
                        300: "프리미엄 300만원 패키지",
                      }[e.target.value],
                    });
                    this.setState({ price: e.target.value * 10000 });
                  }}
                >
                  <MenuItem value={25}>체험형 패키지</MenuItem>
                  <MenuItem value={100}>100만원 패키지</MenuItem>
                  <MenuItem value={300}>300만원 패키지</MenuItem>
                </Select>
              ) : (
                <Select
                  className={classes.selector}
                  fullWidth
                  value={adId}
                  onChange={this.handleChange("adId")}
                >
                  {companies.map((company) => {
                    return (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              <TextField
                label="Number"
                placeholder={"number"}
                className={classes.textField}
                margin="normal"
                value={number}
                onChange={this.handleChange("number")}
              />
              <TextField
                label="Notes"
                placeholder={"Notes"}
                className={classes.textField}
                margin="normal"
                value={notes}
                onChange={this.handleChange("notes")}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onCreate(
                    number,
                    period,
                    name,
                    price,
                    adId,
                    notes,
                    chargeType
                  );
                  this.props.onClose();
                }}
              >
                추가
              </Button>
            </FormControl>
          </form>
        }
      </Popover>
    );
  }
}

export default withStyles(styles)(InfoPremiumPackage);
