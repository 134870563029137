import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomTextField from '../../../../../components/CustomTextField';
import ActionCreators from '../../../../action';

import ModifiableList from '../../../components/ModifiableList';
import CustomSwitch from '../../../../../components/CustomSwitch';
import { LEVEL } from '../../constants';

const InfoBenefitAndLimit = (props) => {
  const { adsWithDetails, setAdsWithDetails } = props;

  function onChangeEventHandler(event) {
    const value = event.target.value;
    let newAvailableLevels = [...adsWithDetails.ad.availableLevels];

    if (event.target.checked && !newAvailableLevels.includes(value)) {
      newAvailableLevels.push(value);
      newAvailableLevels.sort();
    } else if (!event.target.checked) {
      newAvailableLevels = newAvailableLevels.filter((item) => item !== value);
    }

    setAdsWithDetails({
      ...adsWithDetails,
      ad: {
        ...adsWithDetails.ad,
        availableLevels: [...newAvailableLevels],
      },
    });
  }

  console.log(adsWithDetails);

  const InfoDetails = (
    <>
      {LEVEL.map((item, _) => {
        const levelAvailable = adsWithDetails.ad.availableLevels.includes(item);

        return (
          <div>
            <CustomSwitch
              showModifiedStatus={false}
              label={item}
              value={item}
              checked={adsWithDetails.ad.availableLevels.includes(item)}
              onChange={(event) => {
                onChangeEventHandler(event);
              }}
              layoutStyle={{
                display: 'flex',
                justifyContents: 'center',
                alignItems: 'center',
                margin: '25px 0px 2px 0px',
              }}
              labelStyle={{
                margin: 0,
                fontSize: 15,
                fontWeight: 500,
              }}
            />
            <div style={{ display: 'flex', gap: 16 }}>
              <CustomTextField
                label={`totalPrice`}
                value={(adsWithDetails.channel.totalPrice || {})[item] || 0}
                type='number'
                layoutStyle={{ flex: 1 }}
                onChange={(event) => {
                  setAdsWithDetails({
                    ...adsWithDetails,
                    channel: {
                      ...adsWithDetails.channel,
                      totalPrice: {
                        ...adsWithDetails.channel.totalPrice,
                        [item]: Number(event.target.value),
                      },
                    },
                  });
                }}
                disabled={!levelAvailable}
              />
              <CustomTextField
                label={`discountRate`}
                value={(adsWithDetails.channel.discountRate || {})[item] || 0}
                type='number'
                layoutStyle={{ flex: 1 }}
                disabled={!levelAvailable}
                onChange={(event) => {
                  setAdsWithDetails({
                    ...adsWithDetails,
                    channel: {
                      ...adsWithDetails.channel,
                      discountRate: {
                        ...adsWithDetails.channel.discountRate,
                        [item]: Number(event.target.value),
                      },
                    },
                  });
                }}
              />
              <CustomTextField
                label={`cashbackAmount`}
                value={(adsWithDetails.channel.cashbackAmount || {})[item] || 0}
                type='number'
                layoutStyle={{ flex: 1 }}
                disabled={!levelAvailable}
                onChange={(event) => {
                  setAdsWithDetails({
                    ...adsWithDetails,
                    channel: {
                      ...adsWithDetails.channel,
                      cashbackAmount: {
                        ...adsWithDetails.channel.cashbackAmount,
                        [item]: Number(event.target.value),
                      },
                    },
                  });
                }}
              />
              <CustomTextField
                label={`finalPrice`}
                value={(adsWithDetails.channel.finalPrice || {})[item] || 0}
                type='number'
                layoutStyle={{ flex: 1 }}
                disabled={!levelAvailable}
                onChange={(event) => {
                  setAdsWithDetails({
                    ...adsWithDetails,
                    channel: {
                      ...adsWithDetails.channel,
                      finalPrice: {
                        ...adsWithDetails.channel.finalPrice,
                        [item]: Number(event.target.value),
                      },
                    },
                  });
                }}
              />
              <CustomTextField
                label={`rewardPoint`}
                value={(adsWithDetails.channel.rewardPoint || {})[item] || 0}
                type='number'
                layoutStyle={{ flex: 1 }}
                disabled={!levelAvailable}
                onChange={(event) => {
                  setAdsWithDetails({
                    ...adsWithDetails,
                    channel: {
                      ...adsWithDetails.channel,
                      rewardPoint: {
                        ...adsWithDetails.channel.rewardPoint,
                        [item]: Number(event.target.value),
                      },
                    },
                  });
                }}
              />
            </div>
          </div>
        );
      })}

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'deadlineRecruitment'}
          value={adsWithDetails.channel.deadlineRecruitment}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                deadlineRecruitment: Number(event.target.value),
              },
            });
          }}
        />
        <CustomTextField
          label={'deadlineAnnouncement'}
          value={adsWithDetails.channel.deadlineAnnouncement}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                deadlineAnnouncement: Number(event.target.value),
              },
            });
          }}
        />
        <CustomTextField
          label={'deadlineReview'}
          value={adsWithDetails.channel.deadlineReview}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                deadlineReview: Number(event.target.value),
              },
            });
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <CustomTextField
          label={'신청 인원(실제/표기값)'}
          value={`${adsWithDetails.applied} / ${adsWithDetails.modifiedApplied}`}
          layoutStyle={{ flex: 1 }}
          disabled={true}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                maxCapacity: Number(event.target.value),
              },
            });
          }}
        />
        <CustomTextField
          label={'선정 인원(실제/표기값)'}
          value={`${adsWithDetails.enrolled} / ${adsWithDetails.modifiedEnrolled}`}
          layoutStyle={{ flex: 1 }}
          disabled={true}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                maxCapacity: Number(event.target.value),
              },
            });
          }}
        />

        <CustomTextField
          label={'최대 인원'}
          value={adsWithDetails.channel.maxCapacity}
          layoutStyle={{ flex: 1 }}
          onChange={(event) => {
            setAdsWithDetails({
              ...adsWithDetails,
              channel: {
                ...adsWithDetails.channel,
                maxCapacity: Number(event.target.value),
              },
            });
          }}
        />
      </div>
      <ModifiableList
        multiline={true}
        rows={3}
        layoutStyle={{ gap: 16 }}
        list={adsWithDetails.ad.tasks}
        label={'tasks'}
        onAddHandler={(newTask) => {
          const clone = [...adsWithDetails.ad.tasks];
          clone.push(newTask);

          setAdsWithDetails({
            ...adsWithDetails,
            ad: {
              ...adsWithDetails.ad,
              tasks: clone,
            },
          });
        }}
        onRemoveHandler={(removeIndex) => {
          const clone = [...adsWithDetails.ad.tasks].filter(
            (_, index) => index !== removeIndex
          );

          setAdsWithDetails({
            ...adsWithDetails,
            ad: {
              ...adsWithDetails.ad,
              tasks: clone,
            },
          });
        }}
      />
    </>
  );

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>
          제공혜택 및 이용제한 <span style={{ fontWeight: 300 }}>정보</span>
        </h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        {InfoDetails}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoBenefitAndLimit);
