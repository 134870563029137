import React, { useState } from 'react';
import CustomTextField from '../../../components/CustomTextField';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import Typo from '../../../components/Typo';
import { Color } from '../../../constants/color';

/**
 *
 * @param {Array} list 기존에 존재하는 배열
 * @param {Object} layoutStyle 가장 바깥 div의 스타일
 * @param {Boolean} multiline 줄바꿈 허용
 * @param {Number} rows row 수
 * @param {function} onAddHandler 새로운 원소를 추가하는 함수
 * @param {function} onRemoveHandler 기존의 원소를 삭제하는 함수
 */

const ModifiableList = (props) => {
  const [newElement, setNewElement] = useState('');
  const listElements = props.list.map((item, idx) => {
    return (
      <div
        className='row'
        key={idx}
        style={{ display: 'flex', alignItems: 'flex-end' }}
      >
        <CustomTextField
          showModifiedStatus={false}
          value={item}
          fullWidth
          layoutStyle={{ flex: 1 }}
          {...props.textFieldStyle}
          {...(props.multiline && { multiline: props.multiline })}
          {...(props.rows && { rows: props.rows })}
          rightIcon={<RemoveCircleOutline />}
          rightIconButtonHandler={() => {
            props.onRemoveHandler(idx);
          }}
        />
      </div>
    );
  });
  return (
    <div style={props.layoutStyle}>
      <Typo
        style={{ marginTop: '20px', marginBottom: '6px' }}
        typography='Detail_1'
        fontWeight='500'
        color={Color.label_type1}
      >
        {props.label}
      </Typo>
      {listElements}
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomTextField
          showModifiedStatus={false}
          value={newElement}
          onChange={(evt) => {
            setNewElement(evt.target.value);
          }}
          layoutStyle={{ flex: 1 }}
          {...(props.multiline && { multiline: props.multiline })}
          {...(props.rows && { rows: props.rows })}
          {...props.textFieldStyle}
          rightIcon={<AddCircleOutline />}
          rightIconButtonHandler={() => {
            props.onAddHandler(newElement);
            setNewElement('');
          }}
          fullWidth
        />
      </div>
    </div>
  );
};

export default ModifiableList;
