import React, { useState } from 'react';
import { IconButton, makeStyles, Modal } from '@material-ui/core';

const CustomModal = ({ title, content, buttonIcon, ...props }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // NOTE: 이후에 버튼 없이 사용할 경우를 대비해 Custom hook으로 변경할 필요성 있음

  return (
    <>
      {buttonIcon && (
        <IconButton onClick={() => setOpen(true)}>{buttonIcon}</IconButton>
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        {...props}
      >
        <div className={classes.paper}>
          <h2 id='simple-modal-title'>{title}</h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 800,
              height: 700,
            }}
            id='simple-modal-description'
          >
            {content}
          </div>
        </div>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    padding: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: 4,
  },
}));

export default CustomModal;
